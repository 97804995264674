import { useScrollTrigger } from "@mui/material";
import { useEffect, useState } from "react"


const SearchBar = ({userMembers, setFilteredUserMembers, searchParameter}) => {

    // const [filteredUserMembers, setFilteredUserMembers] = useState(userMembers);
    const [searchField, setSearchField] = useState('');
    useEffect(() => {
        var newFilteredUserMembers;
        
        if(searchParameter === "Full Name")
        {
            newFilteredUserMembers = userMembers.filter((userMember) => {
                return (userMember.forename + " " + userMember.surname).toLocaleLowerCase().includes(searchField);
            });
        }
        else {
            newFilteredUserMembers = userMembers.filter((userMember) => {
                return (userMember[searchParameter]).toLocaleLowerCase().includes(searchField);
            });
        }

        setFilteredUserMembers([...newFilteredUserMembers]);
    }, [userMembers, searchField])

    const onSearchChange = (event) => {
        console.log("new search");
        setSearchField(event.target.value.toLocaleLowerCase());
    }

    return (
        <div className="p-3">
            <input type="text" onChange={onSearchChange} placeholder="Search by name" className="form-control" />
        </div>
    )
}

export default SearchBar;