import { useScrollTrigger } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/user.context";
import { db, fetchUserData } from "../../utils/firebase/firebase.util";
import {
    getFirestore,
    doc,
    getDoc,
    setDoc,
    collection,
    query,
    addDoc,
    getDocs,
    orderBy,
    deleteDoc,
    serverTimestamp
} from 'firebase/firestore';
import TitleSection from "../../components/title-section/title-section";
import TitleSectionMin from "../../components/title-section-min/title-section";
import Modal from 'react-modal';

const defaultNewTaskField = {
    'title': '',
    'moreDetails': '',
    'done': false
}

const ToDoComponent = ({ userData }) => {
    const [toDoItems, setToDoItems] = useState([]);
    const [newToDoItem, setNewToDoItem] = useState(defaultNewTaskField);
    const { currentUser } = useContext(UserContext);
    const [openModal, setOpenModal] = useState(false);

    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const [infoModalItem, setInfoModalItem] = useState([]);

    useEffect(() => {
        if (userData !== null){
            const fetchData = async () => {
                console.log(currentUser.uid);
                const toDoRef = collection(db, 'users', userData.parishid, 'toDo');
                const q = query(toDoRef, orderBy('timestamp', 'desc'));
                const querySnapshot = await getDocs(q);

                const toDoArray = [];
                querySnapshot.forEach((doc) => {
                    console.log();
                    toDoArray.push({...doc.data(), id: doc.id});
                })

                setToDoItems(toDoArray);
            }

            fetchData();
        }
    }, [userData])

    const handleNewToDoChange = (event) => {
        const {name, value} = event.target;
        setNewToDoItem({...newToDoItem, [name]: value});
        console.log(newToDoItem);
    }

    const checkToDoItem = async(toDoID, value, index) => {
        console.log(toDoID);
        const documentRef = doc(db, 'users', userData.parishid, 'toDo', toDoID);
        let success = true;
        try { 
            await setDoc(documentRef, {'done': !value}, {merge: true})
            console.log('Document Successfully updated');
        } catch (error) {
            success = false;
            console.error("Error", error);
            return ;
        }

        if(success)
        {
            let updateItems = toDoItems;
            updateItems[index].done = !value;
            setToDoItems([...updateItems]);
        }
    }

    const deleteToDoItem = async(toDoId, index) =>
    {
        const documentRef = doc(db, 'users', userData.parishid, 'toDo', toDoId);

        try { 
            // documentRef.delete()
            deleteDoc(documentRef);
        } catch (error) {
            console.log("error", error);
            alert("There was an error, please try again later");
            return ;
        }

        const newList = toDoItems;
        newList.splice(index, 1);

        setToDoItems([...newList]);

    }

    const createNewToDoItem = async(event) => {
        event.preventDefault();

        console.log(newToDoItem);
        var newDocRef = "";
        var success = true;
        try {
            const toDoRef = collection(db, 'users', userData.parishid, 'toDo');
            const postItem = newToDoItem;
            postItem['timestamp'] = serverTimestamp();
            newDocRef = await addDoc(toDoRef, newToDoItem);
        } catch (error) {
            success = false;
            console.log("error", error);
            return;
        }

        if(success)
        {
            console.log(newDocRef.id);
            newToDoItem.id = newDocRef.id;
            const newList = [newToDoItem, ...toDoItems];
            setToDoItems(newList);
            setNewToDoItem(defaultNewTaskField);
        }
    }
    
    // Modal stuff
    const modalStyle = {
        content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: "20px"
        },
    };

    const openInfoModal = (item) => {
        setInfoModalItem(item);
        setInfoModalOpen(true);
    }

    return (
        <div>
            <Modal isOpen={openModal} style={modalStyle} onRequestClose={() => setOpenModal(false)} contentLabel="New To Do">
                <h2>Create New Task</h2>
                <br />
            <form onSubmit={createNewToDoItem}>
                <input type="text" name="title" className="form-control" maxLength={50} placeholder="New Task Title*" value={newToDoItem.title} onChange={handleNewToDoChange}/>
                <textarea name="moreDetails" className="w-100 form-control mt-4" id="" cols="30" rows="5" placeholder="More Information" value={newToDoItem.moreDetails} onChange={handleNewToDoChange}></textarea>

                <button type="submit" className="btn btn-success mt-2 w-100">Create</button>
            </form>
            </Modal>

            <Modal isOpen={infoModalOpen} style={modalStyle} onRequestClose={() => setInfoModalOpen(false)} contentLabel="New To Do">
                <h2>{infoModalItem.title}</h2>
                <br />
                <p>{infoModalItem.moreDetails}</p>
            </Modal>

            {('personalName' in userData) && (<TitleSectionMin title={"Tasks to keep you busy, " + userData.personalName} />)}
            {!('personalName' in userData) && (<TitleSectionMin title={"Tasks to keep you busy, " + userData.parishName} />)}

            <div className="p-4">
                {/* Add to Do Item Button */}
                <button type="button" class=" w-100 mb-4 btn btn-primary" onClick={() => setOpenModal(true)}>
                + To Do Item
                </button>
                
                {toDoItems && 
                
                toDoItems.map((toDoItem, index) => {
                    return (
                        <div className="bg-white w-100 shadow p-3 mb-3 rounded-4">
                            <div className="w-100 d-flex justify-content-between align-items-center">
                                <div><b style={{textDecoration: (toDoItem.done === true ? 'line-through': 'none')}}>{toDoItem.title}</b></div>
                                <div className="d-flex">
                                    {/* Delete Button */}
                                    <button style={{border: "none", background: "none"}} onClick={() => deleteToDoItem(toDoItem.id, index)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 0 448 512" fill="#ce0909"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
                                    </button>
                                    {/* Extra Info ButtonButton */}
                                    <button className="ms-2" type="button" style={{border: "none", background: "none"}} onClick={() => openInfoModal(toDoItem)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
                                    </button>

                                    {/* Check Button */}
                                    <button onClick={() => checkToDoItem(toDoItem.id, toDoItem.done, index)} className="ms-2" style={{border: "none", background: "none"}}>
                                        { (toDoItem.done === false) && <svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>}
                                        { (toDoItem.done === true) && <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 0 512 512"><path d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"/></svg>}
                                    </button>
                                </div>

                            </div>
                        </div>

                    )
                })

                }
            </div>
        </div>
    )
}

export default ToDoComponent;