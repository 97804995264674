import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import SendIcon from '../../assets/navbar-icons/paper-plane-solid.svg';
import TitleSectionMin from "../../components/title-section-min/title-section";

const AIAssistantComponent = () => {

    const [aiPrompt, setAiPrompt] = useState('');
    const [loadingState, setLoadingState] = useState(false);

    const [responses, setResponses] = useState([]);

    useEffect(() => {
        document.title = "AI Assistant";
    })

    const sendMessageToChatGPT = async (event) => {
        event.preventDefault();
        const message = aiPrompt;
        setLoadingState(true);
        const response = await fetch('https://api.openai.com/v1/chat/completions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 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'
          },
          body: JSON.stringify({
            model: 'gpt-3.5-turbo',
            messages: [{ role: 'system', content: 'you are a an assistant to a Catholic parish administrator/priest. You are knowledgable expert in Canon law and the catechism of the catholic church. However, you do not have to refer to these if the query does not require it. Dont randomly ramble. Do not refer to outside sources unless required' }, { role: 'user', content: message }],
          })
        }).then((response) => response.json())
        .then((response) => {
            // console.log(response.choices[0].message.content);
            const newReponses = [response.choices[0].message.content, ...responses];
            setResponses(newReponses);
            setLoadingState(false);
        })
      }

    const handlePromptChange = (event) => {
        setAiPrompt(event.target.value);
    }

    return (
        <div>
            <TitleSectionMin title="Your AI Assistant" />

            <form className="d-flex border rounded-3 m-4" onSubmit={sendMessageToChatGPT}>
                <input type="text" className="form-control" style={{border: "none"}} placeholder="Hey there, how can I help you today?" value={aiPrompt} onChange={handlePromptChange} />
                <button type="submit" className="btn btn-primary" style={{border: "none", background: "none"}}><img src={SendIcon} alt="Send" height="16px"/></button>
            </form>

            {loadingState && <div className="d-flex justify-content-center align-items-center w-100">
                <BeatLoader color="#36d7b7" size={12}/>
            </div>}

            <div style={{ maxWidth: "100%"}}>
                {responses.map((response) => {
                    return (
                        <div className="shadow rounded-2 p-3 mx-2" style={{whiteSpace: 'pre-wrap'}}>
                            {/* <div dangerouslySetInnerHTML={{ __html: response}}></div> */}
                            {response.split('\n').map((line, index) => (
                                <div className="" key={index}>
                                    {line}
                                    <br />
                                </div>
                            ))}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default AIAssistantComponent;