import './title-section.css';

const TitleSection = ({title, subtitle}) => {
    return (
        <div className="TaskHeaderDiv w-100 NormalBorder d-flex flex-column px-4 justify-content-center align-items-start">
            <h2 className='titleClean'><b>{title}</b></h2> 
            <p className='subtitle'>{subtitle}</p>
        </div>
    );
}

export default TitleSection;