import { useState } from "react";
import { useContext, useEffect } from 'react';
import { UserContext } from '../../../contexts/user.context';

// components
import '../../members/add-members/add-members.css';
import { APIAuthContext } from "../../../contexts/api-auth.context";
import TitleSectionMin from "../../../components/title-section-min/title-section";

function removeFromArray(array, value){
    for (var i = array.length - 1; i >= 0; i--) {
     if (array[i] === value) {
      array.splice(i, 1);
     }
    }
  }

const defaultFuneralFields = {
    'deceasedName': '',
    'contactName': '',
    'contactEmail': '',
    'contactPhone': '',
    'funeralDate': '',
    'funeralTime': '',
    'funeralChurch': 'church1',
    'ministerName': '',
    'quickNotes': ''
}

const AddFuneralComponent = ({userData}) => {
    const [userMembers, setUserMembers] = useState(null);
    const [funeralProcedures, setFuneralProcedures] = useState(userData.funeralProcedures);
    const [proceduresSelected, setProcedures] = useState([]);
    const [funeralFormStatus, setFuneralFormStatus] = useState(null);
    const [churchOptions, setChurchOptions] = useState([]);

    const [funeralFormFields, setFuneralFormFields] = useState(defaultFuneralFields);

    const { currentUser } = useContext(UserContext);
    const { authCreds } = useContext(APIAuthContext);

    useEffect(() => {
        if(userData !== null){
            var temp = []
            if(userData['church1'] !== '')
            {
                temp.push({'churchnum': 'church1', 'churchname': userData['church1']});
            }
            if(userData['church2'] !== '')
            {
                temp.push({'churchnum': 'church2', 'churchname': userData['church2']});
            }
            if(userData['church3'] !== '')
            {
                temp.push({'churchnum': 'church3', 'churchname': userData['church3']});
            }
            setChurchOptions(temp);
            console.log("church options: ", churchOptions);
        }
    }, [userData])

    const updateSelectedProcedures = (event) => {
        var temp = proceduresSelected;
        
        if(event.target.checked === true)
        {
            temp.push(event.target.value);
        } else {
            removeFromArray(temp, event.target.value);
        }

        setProcedures(temp);
        console.log("selected procedures: ", proceduresSelected);
    }

    const handleFuneralChange = (event) => {
        const {name, value} = event.target;
        setFuneralFormFields({...funeralFormFields, [name]: value});
        console.log(funeralFormFields);
    }

    const handleNewFuneral = async(event) => {
        event.preventDefault();

        var postJson = funeralFormFields;
        postJson['completedProcedures'] = proceduresSelected;
        postJson['allProcedures'] = funeralProcedures;

        let token = await currentUser.getIdToken();
        fetch("https://api.parishnavigator.com/add-funeral", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "funeralData": postJson
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response['status'] === "true"){
                setFuneralFormStatus(true);
            } else {
                setFuneralFormStatus(false);
            }
        })
    }

    if(currentUser !== null && funeralProcedures !== null && userData !== null)
    {
        return (
            <div>
                <TitleSectionMin title="Add Funerals"/>

                <div className="p-4">

                <form className="formGrid w-100 flex-fill " onSubmit={handleNewFuneral}>
                                
                    <div class="d-flex flex-column mb-3">
                        <label>Name of Deceased*</label>
                        <input required type="text" className="form-control" name="deceasedName" onChange={handleFuneralChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Contact Name</label>
                        <input type="text" className="form-control" name="contactName" onChange={handleFuneralChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Contact Email</label>
                        <input type="email" className="form-control" name="contactEmail" onChange={handleFuneralChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Contact Phone</label>
                        <input type="phone" className="form-control" name="contactPhone" onChange={handleFuneralChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Date of Funeral*</label>
                        <input type="date" className="form-control" name="funeralDate" onChange={handleFuneralChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Time of Funeral*</label>
                        <input type="time" className="form-control" name="funeralTime" onChange={handleFuneralChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Church</label>
                        <select required className="form-select" name="funeralChurch" onChange={handleFuneralChange}>
                            {churchOptions.map((church) => {
                                return(
                                <option value={church.churchnum}>{church.churchname}</option>
                                );
                            })}
                        </select>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Minister Name*</label>
                        <input type="text" className="form-control" name="ministerName" onChange={handleFuneralChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Quick Notes</label>
                        <textarea name="quickNotes" className="form-control" onChange={handleFuneralChange}/>
                    </div>

                    <div className="rolesContainer d-flex flex-wrap">
                        {funeralProcedures.map(procedure => {
                            return (
                                <div className="me-4 mb-3">
                                    {/* <p>hey there</p> */}
                                    <input type="checkbox" class="form-check-input me-2" id={procedure} value={procedure} onChange={updateSelectedProcedures}/>
                                    <label for={procedure}>{procedure}</label>
                                </div>
                            )
                        })}
                    </div>

                    <div>
                        {funeralFormStatus === true && (
                            <p className="align-self-start mt-4 text-success"><i>Successfully added Funeral</i></p>
                        )}
                        {funeralFormStatus === false && (
                            <p className="align-self-start mt-4 text-danger"><i>There was an error adding the Funeral</i></p>
                        )
                        }
                        <button type="submit" className="btn btn-primary mt-4">Submit</button>
                    </div>
            
                </form>

                </div>
            </div>
        );
    }
}

export default AddFuneralComponent;