import { UserContext } from "../../../contexts/user.context";
import { APIAuthContext } from "../../../contexts/api-auth.context";
import { useEffect } from "react";
import EmailSender from "../../../components/email-sender/email-sender.component";
import TitleSectionMin from "../../../components/title-section-min/title-section";
import React from 'react';


const EmailAllMembers = ({userData}) =>
{
    return (
        <div>
            <TitleSectionMin title="Email All Parishioners" subtitle="Reach all your parishioners quickly and easily"/>

            <div className="p-4">
                <EmailSender recipients="" parishName={userData.parishName} senderEmail={userData.email} apiRoute='contact-all-parishioners' userId={userData.parishid}/>
            </div>
        </div>

    );
}

export default EmailAllMembers;