import { useContext, useEffect, useState } from "react";
import TitleSectionMin from "../../components/title-section-min/title-section";
import React from "react";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CallToActionSharp } from "@mui/icons-material";
import { UserContext } from "../../contexts/user.context";
import { APIAuthContext } from "../../contexts/api-auth.context";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


// Function to format date to "YYYY-MM-DD"
const formatDateForInput = (date) => {
    // Get the year, month, and day from the date object
    const year = date.getFullYear();
    // JavaScript months are 0-based, so we add 1 to get the correct month
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    // Format the date as "YYYY-MM-DD"
    return `${year}-${month}-${day}`;
};

interface CollectionFigures {
    [key: string]: string;
}

const FinanceManager = ({ userData }) => {
    const [date, setDate] = useState(formatDateForInput(new Date()));
    const [amount, setAmount] = useState(0.0);
    const [open, setOpen] = useState(false);
    const [selectedChurch, setSelectedChurch] = useState('church1');
    const [churchOptions, setChurchOptions] = useState<any>([]);
    const [snackBarContent, setSnackBarContent] = useState("");
    const { authCreds } = useContext(APIAuthContext);
    const { currentUser } = useContext<any>(UserContext);

    const [collectionFigures, setCollectionFigures] = useState<CollectionFigures[]>([]);
    const [chartDateFrom, setChartDateFrom] = useState(formatDateForInput(new Date(new Date().setFullYear(new Date().getFullYear() - 1))));
    const [chartDateTo, setChartdateTo] = useState(formatDateForInput(new Date()));
    const [filteredData, setFilteredData] = useState<CollectionFigures[]>([]);
    const [transformDatad, setTransformedData] = useState([]);

    const [refreshToken, setRefreshToken] = useState(0);


    useEffect(() => {
        if(userData !== null){
            var temp: any[] = []
            if(userData['church1'] !== '')
            {
                temp.push({'churchnum': 'church1', 'churchname': userData['church1']});
            }
            if(userData['church2'] !== '')
            {
                temp.push({'churchnum': 'church2', 'churchname': userData['church2']});
            }
            if(userData['church3'] !== '')
            {
                temp.push({'churchnum': 'church3', 'churchname': userData['church3']});
            }
            setChurchOptions(temp);
            console.log("church options: ", churchOptions);

            filterCollectionData();
        }
    }, [userData])

    useEffect(() => {

        const getCols = async() => {
            if (userData != null)
            {
                let token = await currentUser.getIdToken();
                fetch(`https://api.parishnavigator.com/get-collections`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: "include"
                })
                    .then((result) => result.json())
                    .then((result) => {
                        if (result['status'] == 'true')
                        {
                            console.log("Collection Figures: ", result['collections'])
                            setCollectionFigures(result['collections']);
                        }
                    })
            }
        }

        getCols();
    }, [userData, refreshToken])

    function formatDateJS(dateString) {
        console.log("Type: ", typeof(dateString));
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
        // Parse the date string into a Date object
        const date = new Date(dateString);
    
        // Extract year, month, and day
        const year = date.getFullYear();
        const monthIndex = date.getMonth();
        const day = date.getDate();
    
        // Convert month index to month name abbreviation
        const month = months[monthIndex];
    
        // Format the date
        const formattedDate = `${year}-${String(monthIndex + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    
        return formattedDate;
    }
    

    const showSnackBar = () => {
        setOpen(true);
      };
    
      const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
    
      const action = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

    const handleNewCollection = async() => {
        console.log("New Collection ready");
        let token = await currentUser.getIdToken();
        fetch("https://api.parishnavigator.com/add-new-collection", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "date": new Date(date),
                "amount": amount,
                "church": selectedChurch
            })
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response['status'] === "true"){
                setSnackBarContent("Added new Collection");
                setRefreshToken(refreshToken+1);
            } else {
                setSnackBarContent("Failed to add new Collection");
            }

            showSnackBar();
        })
    }

    const updateCollectionDate = (index, newdate) => {
        var collection = collectionFigures;
        (collection as any)[index].timestamp = newdate;

        setCollectionFigures([...collection]);
    }

    const updateCollectionAmount = (index, newamount) => {
        var collection = collectionFigures;
        (collection as any)[index].amount = newamount;

        setCollectionFigures([...collection]);
    }

    const updateCollectionChurch = (index, newchurch) => {
        var collection = collectionFigures;
        (collection as any)[index].church = newchurch;

        setCollectionFigures([...collection]);
    }

    const updateCollectionFigure = async(index) => {
        
        let token = await currentUser.getIdToken();
        fetch("https://api.parishnavigator.com/update-parish-collection", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "collection_id": collectionFigures[index].id,
                "collection_data": collectionFigures[index]
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response['status'] === "true"){
                setSnackBarContent("Added new Collection");
            } else {
                setSnackBarContent("Failed to add new Collection");
            }

            showSnackBar();
        })
    }

    const deleteCollectionFigure = async(index) => {
        
        let token = await currentUser.getIdToken();
        fetch("https://api.parishnavigator.com/delete-collection-figure", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "collection_id": collectionFigures[index].id,
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response['status'] === "true"){
                setSnackBarContent("Deleted Collection Figure");
                var collection = collectionFigures;
                collection.splice(index, 1);
                setCollectionFigures([...collection]);
            } else {
                setSnackBarContent("Failed to delete Collection Figure");
            }

            showSnackBar();
        })
    }

    const filterCollectionData = () => {
        const filteredData = collectionFigures.filter(item => {
            console.log(item);
            const timestamp = new Date(item.timestamp);
            return ((new Date(chartDateFrom)) <= timestamp) && (new Date(chartDateTo) >= timestamp)
            return timestamp >= (new Date(chartDateFrom)) && timestamp <= (new Date(chartDateTo));
        });

        setFilteredData(filteredData);
        transformData(filteredData);
        console.log("Filtered Data: ", filteredData);
    }

    useEffect(() => {
        filterCollectionData();
    }, [collectionFigures]);

    useEffect(() => {
        filterCollectionData();
    }, [chartDateFrom, chartDateTo])

    const colors = ['#007bff', '#28a745', '#dc3545', '#000000'];

    function transformData(data) {
        const transformedData = {};
    
        data.forEach(entry => {
            const { timestamp, church, amount } = entry;
    
            if (!transformedData[timestamp]) {
                transformedData[timestamp] = {};
            }
    
            transformedData[timestamp][church] = amount;
        });
    
        // Convert the transformed data into an array of objects
        const result = Object.keys(transformedData).map(timestamp => ({
            timestamp,
            ...transformedData[timestamp]
        }));

        console.log("Transformed Data: ", result);
    
     setTransformedData(result.reverse());
    }

    return (
        <div>
            <TitleSectionMin title="Collection Manager" subtitle="Track your collections all in one place" />

            <div className="p-4">
                
                {/* Collection Chart */}
                {/* Line Graph */}
                <div className="w-100 mb-3">
                    <div className="d-flex justify-content-start align-items-center">
                        <div>
                            <p className="mb-0">From</p>
                            <input type="date" className="form-control mt-0" value={chartDateFrom} onChange={(e) => setChartDateFrom(e.target.value)}/>
                        </div>

                        <div className="ms-2">
                            <p className="mb-0">To</p>
                            <input type="date" className="form-control mt-0" value={chartDateTo} onChange={(e) => setChartdateTo(e.target.value)}/>
                        </div>
                    </div>

                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart data={transformDatad}>
                            <CartesianGrid strokeDasharray="0 0" />
                            <XAxis dataKey="timestamp" />
                            <YAxis />
                            <Tooltip />
                            <Legend />

                            {churchOptions.map((church, index) => {
                                return (
                                    <Line type="monotone" connectNulls dataKey={church.churchnum} stroke={colors[index]} name={church.churchname} />
                                )
                            })}
                            {/* <Line type="monotone" connectNulls dataKey="church1" stroke="green"  />
                            <Line type="monotone" connectNulls dataKey="church2" stroke="blue" /> */}

                        </LineChart>
                    </ResponsiveContainer>
                </div>

                {/* Adding new Collection */}
                <div className="w-100 d-md-flex justify-content-around align-items-center">
                    <input type="date" className="form-control" value={date} onChange={(e) => setDate((e.target.value))}/>
                    <div className="w-100" style={{ position: 'relative' }}>
                        <span style={{ position: 'absolute', left: 5, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}>
                            £
                        </span>
                        <input 
                            type="number" 
                            step="0.01" 
                            className="form-control" 
                            placeholder="Enter payment amount" 
                            value={amount} 
                            onChange={(e) => setAmount(parseFloat(e.target.value))}
                            style={{ paddingLeft: '20px' }} // Adjust padding to accommodate the currency symbol
                        />
                    </div>

                    <select required className="form-select" name="church" value={selectedChurch} onChange={(e) => setSelectedChurch(e.target.value)}>
                        {churchOptions.map((church) => {
                        return(
                            <option value={church.churchnum}>{church.churchname}</option>
                        );
                        })}
                    </select>

                    <button className="btn btn-success w-md-100" style={{ whiteSpace: 'nowrap' }} onClick={handleNewCollection}>Add Collection</button>
                </div>

                {/* Collection Figures */}
                <div>
                    <h4 className="mt-3"><strong>Previous Collections</strong></h4>
                    {collectionFigures.map((collection, index) => {
                        return(
                            <div className="w-100 d-md-flex justify-content-around align-items-center mt-1">
                                
                                <input type="date" className="form-control" value={collection['timestamp']} onChange={(e) => {updateCollectionDate(index, e.target.value)}}/>
                                <div className="w-100" style={{ position: 'relative' }}>
                                    <span style={{ position: 'absolute', left: 5, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}>
                                        £
                                    </span>
                                    <input 
                                        type="number" 
                                        step="0.01" 
                                        className="form-control" 
                                        placeholder="Enter payment amount" 
                                        value={collection.amount} 
                                        onChange={(e) => updateCollectionAmount(index, parseFloat(e.target.value))}
                                        style={{ paddingLeft: '20px' }} // Adjust padding to accommodate the currency symbol
                                    />
                                </div>

                                <select required className="form-select" name="church" value={collection.church} onChange={(e) => updateCollectionChurch(index, e.target.value)}>
                                    {churchOptions.map((church) => {
                                    return(
                                        <option value={church.churchnum}>{church.churchname}</option>
                                    );
                                    })}
                                </select>

                                <button className="btn btn-primary w-md-100 mx-1" style={{ whiteSpace: 'nowrap' }} onClick={() => updateCollectionFigure(index)}>Update Collection</button>
                                <button className="btn btn-danger" onClick={() => deleteCollectionFigure(index)}><i className="bx bxs-trash-alt"/></button>
                            </div>
                        );
                    })}
                </div>
            </div>





            <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    message={snackBarContent}
                    action={action}
                    />
            
        </div>
    )
}

export default FinanceManager;