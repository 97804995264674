import React, { useContext, useEffect, useState } from "react";
import TitleSection from "../../../components/title-section/title-section";
import TitleSectionMin from "../../../components/title-section-min/title-section";
import { APIAuthContext } from "../../../contexts/api-auth.context";
import { UserContext } from "../../../contexts/user.context";


const QuestionAndAnswer = ({userData}) => {

    const { authCreds } = useContext(APIAuthContext);
    const { currentUser } = useContext<any>(UserContext);
    const [questionAnswers, setQuestionAnswers] = useState<any[]>([]);

    useEffect(() => {

        const getQA = async() => {
            let token = await currentUser.getIdToken();
            fetch(`https://api.parishnavigator.com/get-qandas`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                credentials: "include"
            })
            .then((response) => response.json())
            .then((response) => {
                if(response['status'] === "true")
                {
                    setQuestionAnswers(response['items'])
                }
                else
                {
                    alert("Sorry there was an error. Please try again later.")
                }
            })
        }

        getQA();
    }, [])

    const handleNewQA = async (event) => {
        event.preventDefault();


        var question = event.target.elements.question.value;
        var answer = event.target.elements.answer.value;

        let token = await currentUser.getIdToken();

        fetch(`https://api.parishnavigator.com/new-qanda`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "question": question,
                "answer": answer,
                "parish_id": userData.parishid
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            if(response['status'] === "false")
            {
                alert("Failed to add new Q and A. Please try again later");
            }
            else
            {
                let data = [{"question": question, 'answer': answer, 'itemid': response['newid']}];
                setQuestionAnswers([...data.concat(questionAnswers)]);
            }
        })
    }

    const handleQandAChange = (event, index) => {
        let data = questionAnswers;
        data[index][event.target.name] = event.target.value;

        setQuestionAnswers([...data]);
    }

    const handleQandAUpdate = async(event, index) => {
        event.preventDefault();

        let token = await currentUser.getIdToken();
        fetch(`https://api.parishnavigator.com/update-q-and-a`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "question": questionAnswers[index].question,
                "answer": questionAnswers[index].answer,
                "question_id": questionAnswers[index].itemid
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            if(response['status'] === "true")
            {
                alert('Updated Q and A');
            }
            else
            {
                alert("Failed to update Q and A");
            }
        })
    }

    const handleDeleteQA = async(itemid, index) => {
        let token = await currentUser.getIdToken();
        fetch(`https://api.parishnavigator.com/delete-q-and-a`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "question_id": itemid
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            if(response['status'] === "true")
            {
                let newdata = questionAnswers;
                newdata.splice(index, 1);

                setQuestionAnswers([...newdata]);
            }
        })
    }

    return (
        <div>
            <TitleSectionMin title="Frequently Asked Questions" subtitle="Make Q and As to train our AI model to answer the questions of your parishioners" />
            <div className="p-3">
                <div>
                    <form action="" onSubmit={handleNewQA}>
                        <label htmlFor="">New Question</label>
                        <input type="text" name="question" className="form-control"/>
                        <label htmlFor="" className="mt-2">New Answer</label>
                        <textarea name="answer" className="form-control"  />
                        <button className="btn btn-primary w-100 mt-2">Add new FAQ</button>
                    </form>
                </div>

                <div className="mt-3">
                    <h4>Current Q and As</h4>
                    {questionAnswers.map((qanda, index) => {
                        return(
                            <div className="mb-5">
                                <label htmlFor="">Question</label>
                                <input type="text" name="question" className="form-control" value={qanda.question} onChange={(event) => handleQandAChange(event, index)}/>
                                <label htmlFor="">Answer</label>
                                <textarea className="form-control" name="answer" value={qanda.answer} onChange={(event) => handleQandAChange(event, index)}/>
                                <div className="d-flex mt-2">
                                    <button className="me-2 btn btn-success" type="submit" onClick={(event) => handleQandAUpdate(event, index)}>Submit Change</button>
                                    <button className="btn btn-danger" onClick={() => handleDeleteQA(qanda.itemid, index)}>Delete</button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            
        </div>
    )
}

export default QuestionAndAnswer;