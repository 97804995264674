import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { storage } from '../../utils/firebase/firebase.util';
import { useContext, useState } from "react";
import FunctionWrapper from "../../components/function-area-wrapper/function-wrapper.component";
import TitleSection from "../../components/title-section/title-section";
import 'bootstrap/dist/css/bootstrap.min.css';
import { APIAuthContext } from "../../contexts/api-auth.context";
import { UserContext } from "../../contexts/user.context";
const EmailSender = ({recipients, parishName, senderEmail, userId, apiRoute}) => {
    
    const [emailSubject, setEmailSubject] = useState('');
    const [emailContent, setEmailContent] = useState('Dear ');

    const [emailFormStatus, setEmailFormStatus] = useState(0);

    const { authCreds } = useContext(APIAuthContext);
    const { currentUser } = useContext(UserContext);

    const handleForm = async (event) => {
        event.preventDefault();
        console.log("sending");
        setEmailFormStatus(1);
        const file = event.target[2].files[0];
    
        try {
            const file_url = await uploadFile(file);
            
            
            let token = await currentUser.getIdToken();
            fetch(`https://api.parishnavigator.com/${apiRoute}`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "file": file_url,
                    "content": emailContent,
                    "subject": emailSubject,
                    "parishName": parishName,
                    "senderEmail": senderEmail,
                    "recipients": recipients
                }),
                credentials: "include"
            }).then((response) => response.json())
            .then((response) => {
                console.log(response);

                if(response['status'] === "true")
                {
                    setEmailFormStatus(3);
                }
                if(response['status'] === "false")
                {
                    setEmailFormStatus(2);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }
    
    const uploadFile = (file) => {
        if(!file) return '';
        return new Promise((resolve, reject) => {
            if (!file) {
                reject("No file provided");
                return;
            }
    
            const storageRef = ref(storage, `/files/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
    
            uploadTask.on("state_changed", (snapshot) => {
            }, (err) => {
                console.log(err);
                reject(err);
            }, () => {
                console.log("success");
                getDownloadURL(uploadTask.snapshot.ref)
                    .then((url) => {
                        console.log(url);
                        resolve(url);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        });
    }
    
    

    return (
            <form onSubmit={handleForm}>
                <label>Subject</label>
                <input type="text"  className="form-control mb-2" onChange={s => setEmailSubject(s.target.value)}/>

                <label>Message</label>
                <textarea
                    className="form-control w-100 mb-2"
                    rows={10}
                    value={emailContent}
                    onChange={e => setEmailContent(e.target.value)}
                />

                <label>Attachment</label>
                <input type="file" className="form-control"/>

                {/* sending */}
                {emailFormStatus === 1 && (
                    <p className="align-self-start mt-4 text-secondary"><i>Please wait...</i></p>
                )}
                {/* failed */}
                {emailFormStatus === 2 && (
                    <p className="align-self-start mt-4 text-danger"><i>Email could not be sent</i></p>
                )
                }
                {/* success */}
                {emailFormStatus === 3 && (
                    <p className="align-self-start mt-4 text-success"><i>Email sent successfully</i></p>
                )
                }

                <button type="submit" className="btn btn-primary mt-4">Send Email</button>
            </form>
    )
}

export default EmailSender;