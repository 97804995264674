import { useScrollTrigger } from "@mui/material"
import { useContext, useState } from "react";
import TitleSectionMin from "../../../components/title-section-min/title-section";
import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { APIAuthContext } from "../../../contexts/api-auth.context";
import { UserContext } from "../../../contexts/user.context";

const FormCreator = ({ userData }) => {
    const [textQuestion, setTextQuestion] = useState('');
    const [optionQuestion, setOptionQuestion] = useState('');
    const [fileQuestion, setFileQuestion] = useState('');
    const [formName, setFormName] = useState('');
    const [formStatus, setFormStatus] = useState('');
    const [formProcedures, setFormProcedures] = useState('');
    const [optionFields, setOptionFields] = useState([{value: ''}]);

    const { authCreds } = useContext(APIAuthContext);
    const { currentUser } = useContext(UserContext);

    // rich text editor value
    const [editorValue, setEditorValue] = useState('');

    const [formJSON, setFormJSON] = useState({
        title: '',
        pages: [
            {
                name: 'page1',
                elements: []
            }
        ]
    });

    const survey = new Model(formJSON);

    const handleOptionsChange = (index, event) => {
        const values = [...optionFields];
        values[index].value = event.target.value;
        setOptionFields(values);
    }

    const handleAddField = () => {
        setOptionFields([...optionFields, {value: ''}]);
    };

    const handleDeleteField = (index) => {
        const values = [...optionFields];
        values.splice(index, 1);
        setOptionFields(values);
    }

    const handleAddOptionField = (event) => {
        event.preventDefault();

        console.log('Form Values: ', optionFields.map(field => field.value));
        const newQuestion = {
            type: 'radiogroup',
            name: optionQuestion,
            title: optionQuestion,
            choices: optionFields.map(field => field.value)
        }

        var prevElements = formJSON.pages[0].elements;
        prevElements.push(newQuestion);

        const newJSON = formJSON;
        newJSON.pages[0].elements = prevElements;

        setFormJSON({...newJSON});
    }

    const addTextQuestion = (event) => {
        event.preventDefault();
        console.log(event.question);
        console.log(event);
        
        const newQuestion = {
            type: 'text',
            name: textQuestion,
            title: textQuestion
        };

        var prevElements = formJSON.pages[0].elements;
        prevElements.push(newQuestion);

        console.log(prevElements);

        const newJSON = formJSON;
        newJSON.pages[0].elements = prevElements;

        setFormJSON({...newJSON});

        console.log("newJSON: " + JSON.stringify(formJSON));
    }

    const handleAddFileField = (event) => {
        event.preventDefault();

        const newQuestion = {
            type: 'file',
            name: fileQuestion,
            title: fileQuestion,
            storeDataAsText: false,
            fileType: 'pdf',
            acceptedTypes: 'application/pdf',
        }

        var prevElements = formJSON.pages[0].elements;
        prevElements.push(newQuestion);

        const newJSON = formJSON;
        newJSON.pages[0].elements = prevElements;

        setFormJSON({...newJSON});
    }

    const handleEditorChange = (value) => {
        setEditorValue(value);
    }

    const addHTMLtoForm = (event) => {
        event.preventDefault();
        const parser = new DOMParser();
        const doc = parser.parseFromString(editorValue, 'text/html');
        const htmlContent = doc.body.innerHTML;

        console.log('converted html: ', htmlContent);

        const newContent = {
            type: "html",
            name: "htmlContent",
            html: htmlContent
        };

        var prevElements = formJSON.pages[0].elements;
        prevElements.push(newContent);

        const newJSON = formJSON;
        newJSON.pages[0].elements = prevElements;

        setFormJSON({...newJSON});

    }

    const updateFormName = (newName) => {
        setFormName(newName);
        
        const newJSON = formJSON;
        newJSON.title = newName;

        setFormJSON({...newJSON});
    }

    const publishForm = async() => {
        setFormStatus("Please Wait");

        let token = await currentUser.getIdToken();
        fetch("https://api.parishnavigator.com/create-custom-form", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "formJSON": formJSON,
                "formProcedures": formProcedures
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response['status'] === "true")
            {
                setFormStatus("Successfully created custom form")
            } else {
                setFormStatus("Custom Form creation failed.")
            }
        })
    }

    return (
        <div>
            <TitleSectionMin title="Form Creator" subtitle="Please add questions, your form preview will automatically be updated"/>

            <div className="p-4 w-100">

                <h3><b>Form Name</b></h3>
                <input type="text" className="form-control mb-4" placeholder="What would you like your form to be called?" value={formName} onChange={e => updateFormName(e.target.value)}/>

                <form onSubmit={addHTMLtoForm}>
                    <div className="bg-white border border-2 border-info p-3 p-3 rounded-2">
                        <label htmlFor=""><strong>Add Content</strong></label>
                        <ReactQuill value={editorValue} onChange={handleEditorChange} />
                        <button type="submit" className="btn btn-primary mt-3">Add Content</button>
                    </div>
                </form>

                <form onSubmit={addTextQuestion}>
                    <div className="bg-white border border-2 border-info p-3 rounded-2">
                        <label htmlFor=""><strong>Add Text Question</strong></label>
                        <input name='question' className="form-control" type="text" placeholder="Field Title" value={textQuestion} onChange={e => setTextQuestion(e.target.value)}/>

                        <button className="btn btn-primary mt-3" type="submit">Add Text Question</button>
                    </div>
                </form>

                <form onSubmit={handleAddOptionField}>
                    <div className="mt-2 bg-white border border-2 border-info p-3 rounded-2">
                        <label htmlFor=""><strong>Add Option Question</strong></label>

                        <input name="question" className="form-control" type="text" placeholder="Field Title" value={optionQuestion} onChange={e => setOptionQuestion(e.target.value)}/>


                        <label htmlFor="" className="mt-2"><i>Add options here</i></label>
                        {optionFields.map((field, index) => (
                            <div key={index} className="d-flex">
                                <input type="text" className="form-control" value={field.value} onChange={(event) => handleOptionsChange(index, event)}/>
                                <button type="button" className="btn btn-danger" onClick={() => handleDeleteField(index)}><i className="bx bx-trash"></i></button>
                            </div>
                        ))}
                        <div>
                            <button type="button" className="mt-1 btn btn-success" onClick={handleAddField}>Add Field</button>
                        </div>
                        <button className="btn btn-primary mt-3" type="submit">Add Option Question</button>
                    </div>
                </form>
                        
                {/* <form onSubmit={handleAddFileField}>
                    <div className="mt-2 bg-white border border-2 border-info p-3 rounded-2">
                        <label htmlFor=""><strong>Add File Upload</strong></label>

                        <input type="text" className="form-control" placeholder="Field Title" value={fileQuestion} onChange={e => setFileQuestion(e.target.value)}/>

                        <button type="submit" className="btn btn-primary">Add File Upload</button>
                    </div>
                </form> */}

                {/* <form action="">
                    <div className="mt-2 bg-white border border-2 border-info p-3 rounded-2">
                        <label htmlFor=""><strong>Procedures (for managing form responses)</strong></label>
                        <i>Enter a comma-separated set of procedures</i>

                        <input type="text" className="form-control" placeholder="procedure1, procedure2" value={formProcedures} onChange={e => setFormProcedures(e.target.value)} />
                    </div>
                </form> */}
                
                <div>
                    {formStatus && (
                        <p className="d-flex mb-0 mt-4"><i>{formStatus}</i></p>
                    )}
                    <button className="btn btn-success w-100 mt-3" onClick={publishForm}>Publish Form</button>
                </div>
            </div>

            <div>
                <Survey model={new Model(formJSON)} />
            </div>
        </div>
    )
}

export default FormCreator;