import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/user.context";

// components
import MemberAccordion from "../../../components/accordion/accordion.component";
import SearchBar from "../../../components/search-bar/search-bar.component";
import LoadingState from "../../../components/loading-state/loading-state.component";
import { APIAuthContext } from "../../../contexts/api-auth.context";
import TitleSectionMin from "../../../components/title-section-min/title-section";

function removeFromArray(array, value){
    for (var i = array.length - 1; i >= 0; i--) {
     if (array[i] === value) {
      array.splice(i, 1);
     }
    }
  }

const DeleteMembersComponent = ({userData, setChildComponent}) => {
    const [userMembers, setUserMembers] = useState(null);
    const [filteredUserMembers, setFilteredUserMembers] = useState(null);
    const { currentUser } = useContext(UserContext);
    const { authCreds } = useContext(APIAuthContext);
    const [deleteUsers, setDeleteUsers] = useState([]);

    // update to refresh the page
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        document.title = "Delete Members";
    })

    useEffect(() => {
        setFilteredUserMembers(userMembers);
    }, [userMembers])

    const updateDeleteUsers = (event) => {
        var temp = deleteUsers;

        if(event.target.checked === true)
        {
            temp.push(event.target.value);
        } else {
            removeFromArray(temp, event.target.value);
        }

        setDeleteUsers([...temp]);

        console.log("deleting users: ", deleteUsers);

    }


    const handleDeleteUsers = async(event) => {
        event.preventDefault();

        let token = await currentUser.getIdToken();
        fetch('https://api.parishnavigator.com/delete-members', {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "deleteMembers": deleteUsers
             }),
             credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            // window.location.reload();
            setRefreshKey(refreshKey+1);
        })
    }


    useEffect(() => {

        const getMembers = async() => {
            if(currentUser !== null){
                let token = await currentUser.getIdToken();
                fetch(`https://api.parishnavigator.com/get-members`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: "include"
                })
                    .then((members_result) => members_result.json())
                    .then((members_result) => {
                        console.log("api response = ", members_result);
                        setUserMembers(members_result);
                    })
            }
        }

        getMembers();
    }, [currentUser, refreshKey])
    
    if(currentUser !== null && filteredUserMembers !== null){
        return (
            <div>
                <TitleSectionMin title="Delete Parish Members" subtitle="Delete members from your Parish Database"/>


                <div className="p-4">
                    <SearchBar userMembers={userMembers} setFilteredUserMembers={setFilteredUserMembers} searchParameter="Full Name"/>
                    <form onSubmit={handleDeleteUsers}>
                    <button className="btn btn-danger w-100 mb-4" type="submit">Delete Members</button>
                    {filteredUserMembers.map((member, index) => {
                        return (
                            <div key={index} className="w-100 d-flex align-items-center">
                                {/* <input type="checkbox" value={member.memberid} id={member.memberid} className="form-check-input me-3" onChange={updateDeleteUsers}/> */}
                                <input type="checkbox" value={member.memberid} id={member.memberid} className="form-check-input me-3" onChange={updateDeleteUsers} checked={deleteUsers.includes(member.memberid) ? 'checked': ''} style={{minWidth: "20px"}}/>
                                <MemberAccordion memberData={member} userData={userData} setChildComponent={setChildComponent} className="w-100"/>
                            </div>
                        )
                    })}
                    </form>
                </div>
    
                    
            </div>
        )
    } else {
        return (
            <LoadingState />
        )
    }
}

export default DeleteMembersComponent;