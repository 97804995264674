import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/user.context";

import 'bootstrap/dist/css/bootstrap.min.css';
// components
import { APIAuthContext } from "../../../contexts/api-auth.context";
import TitleSectionMin from "../../../components/title-section-min/title-section";
import EmailSender from "../../../components/email-sender/email-sender.component";
import Modal from "react-modal";
import DeleteButton from "../../../components/delete-button/delete-button.component";
import { setRef } from "@mui/material";
import ViewBaptismsComponent from '../view-baptisms/view-baptisms.component.jsx';

function removeFromArray(array, value){
    for (var i = array.length - 1; i >= 0; i--) {
     if (array[i] === value) {
      array.splice(i, 1);
     }
    }
  }


const EditBaptismComponent = ({ userData, baptismID, setChildComponent }) => {
    const { currentUser } = useContext(UserContext);
    const { authCreds } = useContext(APIAuthContext);
    const [churchOptions, setChurchOptions] = useState([]);
    const [baptismDetails, setBaptismDetails] = useState(null);

    const [baptismFormStatus, setBaptismFormStatus] = useState(null);

    const [modalOpen, setModalOpen] = useState(null);

    const [allProcedures, setAllProcedures] = useState([]);
    const [selectedProcedures, setSelectedProcedures] = useState([]);


    useEffect(() => {
        const fetchBaptism = async() => {
            if(currentUser !== null)
            {
                let token = await currentUser.getIdToken();
                fetch(`https://api.parishnavigator.com/get-baptism/${baptismID}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: "include"
                })
                    .then((response) => response.json())
                    .then((response) => {
                        if(response['status'] === "true")
                        {
                            setAllProcedures(response['allProcedures']);
                            setSelectedProcedures(response['completedProcedures']);
                            setBaptismDetails(response);
                        }
                    })
            }
        }
        fetchBaptism();
    }, [currentUser])

    useEffect(() => {
        if(userData !== null){
            var temp = []
            if(userData['church1'] !== '')
            {
                temp.push({'churchnum': 'church1', 'churchname': userData['church1']});
            }
            if(userData['church2'] !== '')
            {
                temp.push({'churchnum': 'church2', 'churchname': userData['church2']});
            }
            if(userData['church3'] !== '')
            {
                temp.push({'churchnum': 'church3', 'churchname': userData['church3']});
            }
            setChurchOptions(temp);
            console.log("church options: ", churchOptions);
        }
    }, [userData])

    const handleBaptismChange = (event) => {
        const {name, value} = event.target;
        setBaptismDetails({...baptismDetails, [name]: value});
        console.log(baptismDetails);
    }

    const changeSelectedProcedures = (event) => {
        var temp = selectedProcedures;

        if(event.target.checked === true){
            temp.push(event.target.value);
        } else {
            removeFromArray(temp, event.target.value);
        }

        setSelectedProcedures([...temp]);
        console.log(selectedProcedures);
    }


    const getBaptismCertificate = async() => {

        // fetch(`https://api.parishnavigator.com/get-baptism-certificate/${currentUser.uid}/${event.target.value}`);
        try{
            let token = await currentUser.getIdToken();

            const response = await fetch(`https://api.parishnavigator.com/get-baptism-certificate/${baptismID}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                credentials: "include"
            })
            // const data = await response.json();

            // if(data.status === "false") {
            //     console.log("ERROR");
            //     return;
            // }
            const blob = await response.blob();
            if (blob.type === "application/json"){
                alert("Could not download Baptism Certificate. Please check you have provided all required fields (Minister's Name + Date of Baptism). And saved these changes");
                return;
            }
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `Baptism Certificate - ${baptismDetails.childFullName}.docx`;
            a.click();
            URL.revokeObjectURL(url);
        } catch(error) {
            console.log(error);
            alert("Could not download Baptism Certificate. Please check you have provided all required fields (Minister's Name + Date of Baptism)")
        }
        
    }

    const commitBaptismChange = async (event) => {
        event.preventDefault();

        var postJson = baptismDetails;
        postJson['allProcedures'] = allProcedures;
        postJson['completedProcedures'] = selectedProcedures;

        let token = await currentUser.getIdToken();
        fetch("https://api.parishnavigator.com/set-baptism-details", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "baptism_id": baptismID,
                "baptism_details": postJson
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response['status'] === "true"){
                setBaptismFormStatus(true);
            } else {
                setBaptismFormStatus(false);
            }
        })
    }

     // Modal stuff
  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: "20px"
    },
  };

    if(baptismDetails !== null && userData !== null)
    {
        return (
            <div>
                <Modal isOpen={modalOpen} style={modalStyle} onRequestClose={() => setModalOpen(false)} contentLabel='Send Email' >
                    <h2>Send Email to {baptismDetails.childFullName}</h2>
                    <hr />
                    <EmailSender recipients={baptismDetails.email} parishName={userData.parishName} senderEmail={userData.email} apiRoute='send-email'/>
                </Modal>


                <TitleSectionMin title={"Baptism Application of " + baptismDetails.childFullName} />
                <div className="p-4">

                <div className="formGrid w-100 flex-fill ">
                    
                    <div class="d-flex flex-column mb-3">
                        <label>Full Name</label>
                        <input required type="text" className="form-control" name="childFullName" value={baptismDetails.childFullName} onChange={handleBaptismChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Date of Birth</label>
                        <input type="date" className="form-control" name="childDOB" value={baptismDetails.childDOB} onChange={handleBaptismChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Father's Name</label>
                        <input type="text" className="form-control" name="fatherName" value={baptismDetails.fatherName} onChange={handleBaptismChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                            <label>Father's Religion*</label>
                            <select required className="form-select" name="fatherReligion" value={baptismDetails.fatherReligion} onChange={handleBaptismChange} >
                                <option value="Catholic">Catholic</option>
                                <option value="Non-Catholic Christian">Non-Catholic Christian</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Mother's Name</label>
                        <input type="text" className="form-control" name="motherName" value={baptismDetails.motherName} onChange={handleBaptismChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                            <label>Mother's Religion*</label>
                            <select required className="form-select" name="fatherReligion" value={baptismDetails.motherReligion} onChange={handleBaptismChange} >
                                <option value="Catholic">Catholic</option>
                                <option value="Non-Catholic Christian">Non-Catholic Christian</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Contact Email</label>
                        <input type="email" className="form-control" name="email" value={baptismDetails.email} onChange={handleBaptismChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Telephone</label>
                        <input type="phone" className="form-control" name="phone" value={baptismDetails.phone} onChange={handleBaptismChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Home Address</label>
                        <input type="text" className="form-control" name="address" value={baptismDetails.address} onChange={handleBaptismChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Church</label>
                        <select required className="form-select" name="church" value={baptismDetails.church} onChange={handleBaptismChange}>
                            {churchOptions.map((church) => {
                                return(
                                <option value={church.churchnum}>{church.churchname}</option>
                                );
                            })}
                        </select>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Parent's Marital Status</label>
                        <input type="text" className="form-control" name="maritalStatus" value={baptismDetails.maritalStatus} onChange={handleBaptismChange}/>
                    </div>

                    <div></div>

                    <div class="d-flex flex-column mb-3">
                        <label>1st Godparent's Name</label>
                        <input type="text" className="form-control" name="godparentName1" value={baptismDetails.godparentName1} onChange={handleBaptismChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>1st Godparent's Address</label>
                        <input type="text" className="form-control" name="godparentAddress1" value={baptismDetails.godparentAddress1} onChange={handleBaptismChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>1st Godparent's Baptism</label>
                        <input type="date" className="form-control" name="godparentDOB1" value={baptismDetails.godparentDOB1} onChange={handleBaptismChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>1st Godparent's Confirmation</label>
                        <input type="date" className="form-control" name="godparentDOC1" value={baptismDetails.godparentDOC1} onChange={handleBaptismChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>1st Godparent's Church and Location of Baptism</label>
                        <input type="text" className="form-control" name="godparentChurch1" value={baptismDetails.godparentChurch1} onChange={handleBaptismChange}/>
                    </div>

                    <div></div>

                    <div class="d-flex flex-column mb-3">
                        <label>2nd Godparent's Name</label>
                        <input type="text" className="form-control" name="godparentName2" value={baptismDetails.godparentName2} onChange={handleBaptismChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>2nd Godparent's Address</label>
                        <input type="text" className="form-control" name="godparentAddress2" value={baptismDetails.godparentAddress2} onChange={handleBaptismChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>2nd Godparent's Baptism</label>
                        <input type="date" className="form-control" name="godparentDOB2" value={baptismDetails.godparentDOB2} onChange={handleBaptismChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>2nd Godparent's Confirmation</label>
                        <input type="date" className="form-control" name="godparentDOC2" value={baptismDetails.godparentDOC2} onChange={handleBaptismChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>2nd Godparent's Church and Location of Baptism</label>
                        <input type="text" className="form-control" name="godparentChurch2" value={baptismDetails.godparentChurch2} onChange={handleBaptismChange}/>
                    </div>

                    <div className="rolesContainer d-flex flex-wrap">
                        {allProcedures.map((procedure) => {
                            return (
                                <div className="me-4 mb-3">
                                    {/* <p>hey there</p> */}
                                    <input type="checkbox" class="form-check-input me-2" id={procedure} value={procedure} checked={(selectedProcedures.includes(procedure) ? 'checked': '')} onChange={changeSelectedProcedures}/>
                                    <label for={procedure}>{procedure}</label>
                                </div>    
                            )
                        })}
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Baptism Date</label>
                        <input type="date" className="form-control" name="baptismDate" value={baptismDetails.baptismDate} onChange={handleBaptismChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Baptism Time</label>
                        <input type="time" className="form-control" name="baptismTime" value={baptismDetails.baptismTime} onChange={handleBaptismChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Minister's Name</label>
                        <input type="text" className="form-control" name="ministerName" value={baptismDetails.ministerName} onChange={handleBaptismChange}/>
                    </div>

                    <div></div>

                    

                    <div className="rolesContainer d-flex flex-wrap w-100">

                        <div>
                            <button className="btn btn-success ms-2" onClick={getBaptismCertificate}>Download Baptism Certificate</button>
                        </div>

                        <div className="ms-2">
                            {/* Button */}
                            <button type="button" className="btn btn-primary" onClick={() => setModalOpen(true)}>
                                        Send Email
                                        </button>
                        </div>
                        <div className="ms-2">
                            <DeleteButton userData={userData} formName="baptism_applications" formID={baptismID} type="button" onDelete={() => {setChildComponent(<ViewBaptismsComponent userData={userData} setChildComponent={setChildComponent}/>)}}/>
                        </div>
                    </div>

                    <div>
                        <div>
                            {baptismFormStatus === true && (
                                <p className="align-self-start mt-4 text-success"><i>Successfully updated Baptism Details</i></p>
                            )}
                            {baptismFormStatus === false && (
                                <p className="align-self-start mt-4 text-danger"><i>There was an error updating Baptism Details</i></p>
                            )
                            }
                        </div>

                        <button className="btn btn-primary mt-4" onClick={commitBaptismChange}>Save Changes</button>

                        
                    </div>

                </div>
                
                </div>
            </div>
        );
    }

}

export default EditBaptismComponent;