import React, { useState, useEffect, useContext } from "react";
import { APIAuthContext } from "../../../contexts/api-auth.context";
import TitleSectionMin from "../../../components/title-section-min/title-section";
import ListItemComponent from "../../../components/list-item/list-item.component";
import ViewFormResponse from "../view-form-response/view-form-response.component.tsx";
import { UserContext } from "../../../contexts/user.context.jsx";

const ViewFormResponses = ({ userData, setChildComponent, formName, formID }) => {
    
    const [formResponses, setFormResponse] = useState<any | null>(null);
    const { authCreds } = useContext(APIAuthContext);
    const { currentUser } = useContext<any>(UserContext);

    useEffect(() => {

            const getResponses = async() => {
                let token = await currentUser.getIdToken();

                fetch(`https://api.parishnavigator.com/get-form-responses/${formID}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: "include"
                })
                    .then((responses) => responses.json())
                    .then((responses) => {
                        if (responses['status'] === "true")
                        {
                            console.log(responses['responses']);
                            setFormResponse(responses['responses']);
                        }
                    })
            }

            getResponses();
    }, []);

    if (formResponses !== null)
    {
        return (
            <div>
                <TitleSectionMin title={"Form Response for " + formName} subtitle="" />

                <div className="p-4">
                    
                    <div>
                        <h5>Your Custom Form Link: <a href={`https://app.parishnavigator.com/online-custom-form?p=${userData.parishid}&f=${formID}`}>{`https://app.parishnavigator.com/online-custom-form?p=${userData.parishid}&f=${formID}`}</a></h5>
                    </div>

                    {formResponses.map((response, index) => {
                        // const keysToKeepAtEnd = ['timestamp', 'id'];

                        // const allKeys = Object.keys(response);
                        // const keysToMoveToEnd = allKeys.filter(key => keysToKeepAtEnd.includes(key));
                        
                        // const orderedForm = Object.fromEntries(
                        //     allKeys
                        //       .filter(key => !keysToKeepAtEnd.includes(key))
                        //       .reverse()
                        //       .map(key => [key, response[key]])
                        //       .concat(keysToMoveToEnd.map(key => [key, response[key]]))
                        //   );

                        var lastKey = Object.keys(response)[Object.keys(response).length - 4];
                        return (
                            <ListItemComponent setChildComponent={setChildComponent} newState={<ViewFormResponse userData={userData} formID={formID} docID={response.id} response={response} formName={formName} setChildComponent={setChildComponent} />}>
                                <div className="w-100 d-flex justify-content-start">
                                    <div className="d-flex flex-column">
                                        <div style={{fontSize: "12px"}}>{lastKey}</div>
                                        <div><b>{response[lastKey]}</b></div>
                                    </div>
                                </div>
                            </ListItemComponent>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default ViewFormResponses;