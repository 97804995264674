import './function-wrapper.css';

const FunctionWrapper = ({children}) => {
    return (
        <div className="TaskArea p-2 NormalBorder" style={{margin: "32px auto auto", height: "100vh", overflow: "auto"}}>
            {children}
        </div>
    );
}

export default FunctionWrapper;