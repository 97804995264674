import { useContext, useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './add-members.css';

// components
import { UserContext } from "../../../contexts/user.context";
import { APIAuthContext } from "../../../contexts/api-auth.context";
import TitleSectionMin from "../../../components/title-section-min/title-section";
import Modal from 'react-modal';
import base64 from 'base-64';

function isEighteenOrOlder(dateOfBirth) {
    const today = new Date();
    const dob = new Date(dateOfBirth);
    var age = today.getFullYear() - dob.getFullYear();
  
    // Adjust age if birthday hasn't occurred yet this year
    if (today.getMonth() < dob.getMonth() || (today.getMonth() === dob.getMonth() && today.getDate() < dob.getDate())) {
      age--;
    }
  
    return age >= 18;
  }

const defaultAdultFormFields = {
    forename: '',
    surname: '',
    email: '',
    phone: '',
    dob: '',
    address: '',
    occupation: '',
    church: 'church1'
}

const defaultChildFormFields = {
    forename: '',
    surname: '',
    email: '',
    parentemail: '',
    dob: '',
    address: '',
    occupation: '',
    church: 'church1'
}

function removeFromArray(array, value){
    for (var i = array.length - 1; i >= 0; i--) {
     if (array[i] === value) {
      array.splice(i, 1);
     }
    }
  }

const AddMembersComponent = ({userData}) => {
    const [roles, setRoles] = useState(null);
    // const [userData, setUserData] = useState(null);
    const { currentUser } = useContext(UserContext);
    const { authCreds } = useContext(APIAuthContext);
    const [churchOptions, setChurchOptions] = useState([]);

    // adult form fields
    const [adultFormFields, setAdultFormFields] = useState(defaultAdultFormFields);
    const { forename, surname, email, phone, dob, address, occupation, church} = adultFormFields;
    const [selectedRoles, setSelectedRoles] = useState([]);

    // child form fields
    const [childFormFields, setChildFormFields] = useState(defaultChildFormFields);
    const { cforename, csurname, cemail, cparentemail, cdob, caddress, coccupation, cchurch} = childFormFields;
    const [selectedChildRoles, setSelectedChildRoles] = useState([]);

    // form status
    const [adultFormStatus, setAdultFormStatus] = useState(null);
    const [childFormStatus, setChildFormstatus] = useState(null);

    useEffect(() => {
        document.title = "Add Parish Members";
    })

    const handleAdultChange = (event) => {
        const {name, value} = event.target;
        setAdultFormFields({...adultFormFields, [name]: value});
        console.log(adultFormFields);
    }
    
    const handleChildChange = (event) => {
        const {name, value} = event.target;
        setChildFormFields({...childFormFields, [name]: value});
        console.log(childFormFields);
    }

    const updateSelectedRoles = (event) => {
        console.log(event.target.value, event.target.checked);
        var temp = selectedRoles;

        if(event.target.checked === true)
        {
            temp.push(event.target.value);
        } else {
            removeFromArray(temp, event.target.value);
        }

        setSelectedRoles(temp);
        console.log("selected roles: ", selectedRoles);
    }

    const updateSelectedChildRoles = (event) => {
        console.log(event.target.value, event.target.checked);
        var temp = selectedChildRoles;

        if(event.target.checked === true)
        {
            temp.push(event.target.value);
        } else {
            removeFromArray(temp, event.target.value);
        }

        setSelectedChildRoles(temp);
        console.log("selected child roles: ", selectedChildRoles);
    }

    const handleNewAdult = async(event) => {
        event.preventDefault();
        console.log("Information: ", adultFormFields);
        console.log("Final Role Seleection: ", selectedRoles);

        if(!isEighteenOrOlder(adultFormFields.dob))
        {
            alert("Aduts must be 18 years old or older");
            return null;
        }

        var postJson = adultFormFields;
        postJson['roles'] = selectedRoles;

        let token = await currentUser.getIdToken();
        fetch("https://api.parishnavigator.com/add-new-member", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "memberData": postJson
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response['status'] === "true"){
                setAdultFormStatus(true);
            } else {
                setAdultFormStatus(false);
            }
        })
    }

    const handleNewChild = async(event) => {
        event.preventDefault();
        console.log("Information: ", childFormFields);
        console.log("Final Role Seleection: ", selectedChildRoles);

        console.log("isEighteen years old: ", isEighteenOrOlder(childFormFields.dob));
        if(isEighteenOrOlder(childFormFields.dob))
        {
            alert("Children must be under 18 years old");
            return null;
        }

        var postJson = childFormFields;
        postJson['roles'] = selectedChildRoles;

        let token = await currentUser.getIdToken();
        fetch("https://api.parishnavigator.com/add-new-member", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "memberData": postJson
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response['status'] === "true"){
                setChildFormstatus(true);
            } else {
                setChildFormstatus(false);
            }
        })
    }



    useEffect(() => {
        if(currentUser !== null){
            var headers = new Headers();
            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;
            const encodedCredentials = base64.encode(`${username}:${password}`);
            headers.set('Authorization', 'Basic ' + encodedCredentials)
            fetch(`https://api.parishnavigator.com/get-roles/${userData.parishid}`, {headers: headers})
                .then((roles_result) => roles_result.json())
                .then((roles_result) => {
                    console.log("api response = ", roles_result);
                    setRoles(roles_result);
                })
        }
    }, [currentUser])

    useEffect(() => {
        if(userData !== null){
            var temp = []
            if(userData['church1'] !== '')
            {
                temp.push({'churchnum': 'church1', 'churchname': userData['church1']});
            }
            if(userData['church2'] !== '')
            {
                temp.push({'churchnum': 'church2', 'churchname': userData['church2']});
            }
            if(userData['church3'] !== '')
            {
                temp.push({'churchnum': 'church3', 'churchname': userData['church3']});
            }
            setChurchOptions(temp);
            console.log("church options: ", churchOptions);
        }
    }, [userData])


    // Modal stuff
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: "20px"
        },
      };
    
    function closeAdultModal() {
        setAdultFormStatus(null);
    }

    function closeChildModal() {
        setChildFormstatus(null);
    }

    if(currentUser !== null && roles !== null && userData !== null)
    {
        return (
            <div className="">
                <Modal
                    isOpen={adultFormStatus}
                    style={customStyles}
                    onRequestClose={closeAdultModal}
                    contentLabel="Example Modal"
                >
                    
                    <div>
                        <h2 className='text-success' >Success</h2>
                        <p>Successfully added new member, {adultFormFields.forename}</p>
                    </div>

                    <button className='btn btn-primary mt-3' onClick={closeAdultModal}>Close</button>
                </Modal>

                <Modal
                    isOpen={childFormStatus}
                    style={customStyles}
                    onRequestClose={closeChildModal}
                    contentLabel="Child Form"
                >
                    
                    <div>
                        <h2 className='text-success' >Success</h2>
                        <p>Successfully added new member, {childFormFields.forename}</p>
                    </div>

                    <button className='btn btn-primary mt-3' onClick={closeChildModal}>Close</button>
                </Modal>
                <TitleSectionMin title="Add Parish Members" subtitle="Enrich your parish with members"/>

                <div className="p-4">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Adult</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Child</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        {/* Adult */}
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                            <div class="d-flex flex-column justify-content-center align-items-center">
                                <form className="formGrid w-100 flex-fill " onSubmit={handleNewAdult}>
                                    
                                    <div class="d-flex flex-column mb-3">
                                        <label>First Name*</label>
                                        <input required type="text" className="form-control" name="forename" onChange={handleAdultChange} />
                                    </div>

                                    <div class="d-flex flex-column mb-3">
                                        <label>Last Name*</label>
                                        <input required type="text" className="form-control" name="surname" onChange={handleAdultChange} />
                                    </div>

                                    <div class="d-flex flex-column mb-3">
                                        <label>Email*</label>
                                        <input required type="email" className="form-control" name="email" onChange={handleAdultChange} />
                                    </div>

                                    <div class="d-flex flex-column mb-3">
                                        <label>Phone Number</label>
                                        <input type="phone" className="form-control" name="phone" onChange={handleAdultChange} />
                                    </div>

                                    <div class="d-flex flex-column mb-3">
                                        <label>Date of Birth*</label>
                                        <input required type="date" className="form-control" name="dob" onChange={handleAdultChange} />
                                    </div>

                                    <div class="d-flex flex-column mb-3">
                                        <label>Home Address*</label>
                                        <input required type="text" className="form-control" name="address" onChange={handleAdultChange} />
                                    </div>

                                    <div class="d-flex flex-column mb-3">
                                        <label>Occupation</label>
                                        <input required type="text" className="form-control" name="occupation" onChange={handleAdultChange} />
                                    </div>

                                    <div class="d-flex flex-column mb-3">
                                        <label>Church</label>
                                        <select required className="form-select" name="church" onChange={handleAdultChange} >
                                            {churchOptions.map((church) => {
                                            return(
                                                <option value={church.churchnum}>{church.churchname}</option>
                                            );
                                            })}
                                        </select>
                                    </div>

                                    <div className="rolesContainer d-flex flex-wrap">
                                        {roles.map((role, index) => {
                                            return (
                                                <div className="me-4 mb-3">
                                                    <input onChange={updateSelectedRoles} type="checkbox" class="form-check-input me-2" id={role.roleid} value={role.roleid}/>
                                                    <label for={role.roleid}>{role.rolename}</label>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    <div>
                                    {adultFormStatus === false && (
                                        <p className="align-self-start mt-4 failureMessage"><i>There was an error adding the new Parish Member</i></p>
                                    )
                                    }
                                        <button type="submit" className="btn btn-primary mt-4">Submit</button>
                                    </div>
                            
                                </form>
                                
                                
                            </div>
                        </div>
                        
                        {/* Child */}
                        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="1">

                        <div class="d-flex flex-column justify-content-center align-items-center">
                                <form className="formGrid w-100 flex-fill " onSubmit={handleNewChild}>
                                    
                                    <div class="d-flex flex-column mb-3">
                                        <label>First Name*</label>
                                        <input required type="text" className="form-control" name="forename" onChange={handleChildChange} />
                                    </div>

                                    <div class="d-flex flex-column mb-3">
                                        <label>Last Name*</label>
                                        <input required type="text" className="form-control" name="surname" onChange={handleChildChange} />
                                    </div>

                                    <div class="d-flex flex-column mb-3">
                                        <label>Email (not required)*</label>
                                        <input type="email" className="form-control" name="email" onChange={handleChildChange} />
                                    </div>

                                    <div class="d-flex flex-column mb-3">
                                        <label>Parent's Email*</label>
                                        <input required type="email" className="form-control" name="parentemail" onChange={handleChildChange} />
                                    </div>

                                    <div class="d-flex flex-column mb-3">
                                        <label>Date of Birth*</label>
                                        <input required type="date" className="form-control" name="dob" onChange={handleChildChange} />
                                    </div>

                                    <div class="d-flex flex-column mb-3">
                                        <label>Home Address*</label>
                                        <input required type="text" className="form-control" name="address" onChange={handleChildChange} />
                                    </div>

                                    <div class="d-flex flex-column mb-3">
                                        <label>School / Occupation</label>
                                        <input required type="text" className="form-control" name="occupation" onChange={handleChildChange} />
                                    </div>

                                    <div class="d-flex flex-column mb-3">
                                        <label>Church</label>
                                        <select required className="form-select" name="church" onChange={handleChildChange} >
                                            {churchOptions.map((church) => {
                                            return(
                                                <option value={church.churchnum}>{church.churchname}</option>
                                            );
                                            })}
                                        </select>
                                    </div>

                                    <div className="rolesContainer d-flex flex-wrap">
                                        {roles.map((role, index) => {
                                            return (
                                                <div className="me-4 mb-3">
                                                    <input onChange={updateSelectedChildRoles} type="checkbox" class="form-check-input me-2" id={role.roleid+index} value={role.roleid}/>
                                                    <label for={role.roleid+index}>{role.rolename}</label>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    <div>
                                    {childFormStatus === true && (
                                        <p className="align-self-start mt-4 successMessage"><i>Successfully created new Parish Member</i></p>
                                    )}
                                    {childFormStatus === false && (
                                        <p className="align-self-start mt-4 failureMessage"><i>There was an error adding the new Parish Member</i></p>
                                    )
                                    }
                                        <button type="submit" className="btn btn-primary mt-4">Submit</button>
                                    </div>
                            

                                </form>

                                
                            </div>

                        </div>
                    </div>
                </div>
                </div>
    );
    }
    else {
        return (
            <p>loading</p>
        );
    }
}

export default AddMembersComponent;