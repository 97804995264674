import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../contexts/user.context';

// components
import MemberAccordion from '../../../components/accordion/accordion.component';
import SearchBar from '../../../components/search-bar/search-bar.component';
import { APIAuthContext } from '../../../contexts/api-auth.context';
import TitleSectionMin from '../../../components/title-section-min/title-section';
import { setRef } from '@mui/material';
import TitleSectionCustom from '../../../components/title-section-min/title-section-customisable.component.tsx';
import './edit-roles.css';

function removeFromArray(array, value){
    for (var i = array.length - 1; i >= 0; i--) {
     if (array[i] === value) {
      array.splice(i, 1);
     }
    }
  }

const EditRolesComponent = ({ userData, roleID}) => {
    const { currentUser } = useContext(UserContext);
    const { authCreds } = useContext(APIAuthContext);

    const [roleName, setRoleName] = useState('Role');
    const [roleMembers, setRoleMembers] = useState([]);
    const [restMembers, setRestMembers] = useState([]);
    const [filteredRestMembers, setFilteredRestMembers] = useState([]);

    const [deleteUsers, setDeleteUsers] = useState([]);
    const [addUsers, setAddUsers] = useState([]);

    const [editRoleName, setEditRoleName] = useState(false);

    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        setFilteredRestMembers(restMembers);
    }, [restMembers])


    const updateDeleteUsers = (event) => {
        var temp = deleteUsers;

        if(event.target.checked === true)
        {
            temp.push(event.target.value);
        } else {
            removeFromArray(temp, event.target.value);
        }

        setDeleteUsers([...temp]);

        console.log("deleting users: ", deleteUsers);

    }

    const updateAddUsers = (event) => {
        var temp = addUsers;

        if(event.target.checked === true)
        {
            temp.push(event.target.value);
        } else {
            removeFromArray(temp, event.target.value);
        }

        setAddUsers([...temp]);

        console.log("adding users: ", addUsers);

    }

    // handle role removal
    const handleRoleRemoval = async(event) => {
        event.preventDefault();

        let token = await currentUser.getIdToken();
        fetch('https://api.parishnavigator.com/remove-role-member', {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "deleteMembers": deleteUsers,
                "roleid": roleID
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            // window.location.reload();
            setRefreshKey(refreshKey+1);
        })
    }

    // handle role addition
    const handleRoleAddition = async(event) => {
        event.preventDefault();

        let token = await currentUser.getIdToken();
        fetch('https://api.parishnavigator.com/add-role-member', {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "addMembers": addUsers,
                "roleid": roleID
            }),
            credentials: 'include'
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            // window.location.reload();
            setRefreshKey(refreshKey+1);
        })

    }

    useEffect(() => {

        const getRoleMembers = async() => {
            if(currentUser !== null){
                console.log(currentUser.uid);

                let token = await currentUser.getIdToken();
                fetch(`https://api.parishnavigator.com/get-role-members-filtered/${roleID}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: "include"
                })
                    .then((role_members) => role_members.json())
                    .then((role_members) => {
                        if(role_members['status'] === "false")
                        {
                            return;
                        }
                        console.log("api response = ", role_members);
                        setRoleName(role_members['role_name']);
                        setRoleMembers(role_members['role_members']);
                        setRestMembers(role_members['rest']);
                    });
            }
        }
        getRoleMembers();
        setAddUsers([]);
        setDeleteUsers([]);

    }, [currentUser, refreshKey])

    const updateRoleName = async() => {
        let token = await currentUser.getIdToken();
        fetch('https://api.parishnavigator.com/edit-role-name', {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "roleid": roleID,
                'new_name': roleName
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            if (response['status'] === "false")
            {
                alert("There was an error");
            } else {
                setEditRoleName(false);
            }
        })
    }

    return (
        <div>

            {!editRoleName && (
                <TitleSectionCustom title={
                    (
                        <div className='d-flex align-items-center'>
                            <h2 className='title'><b>{roleName}</b></h2>
                            <button type='button' className='btn' onClick={() => setEditRoleName(!editRoleName)}><i className='bx bxs-pencil inlineIcon'></i></button>
                        </div>
                    )

                }
                subtitle="Add or remove members from specific Roles within the Parish"
                />
            )
            }
            {editRoleName && (
                <TitleSectionCustom title={(
                    <div className='w-100 d-flex align-items-center'>
                        <input type="text" className='form-control' value={roleName} onChange={e => setRoleName(e.target.value)} />
                        <button type="button" className='btn' onClick={updateRoleName}><i className='bx bxs-check-circle inlineIcon' style={{color: "green"}}/></button>
                    </div>
                )} 
                subtitle="Add or remove members from specific Roles within the Parish" />
            )}


            <div className="p-4">
                <form onSubmit={handleRoleRemoval} className='mb-5'>
                    <button type="submit" className='btn btn-danger w-100 mb-2'>Remove selection from Role</button>
                    {roleMembers.map((member, index) => {
                        return (
                            <div key={index} className='w-100 d-flex align-items-center'>
                                <input type="checkbox" value={member.memberid} id={member.memberid} className='form-check-input me-3' onChange={updateDeleteUsers} style={{minWidth: "20px"}} checked={deleteUsers.includes(member.memberid) ? 'checked': ''}/>
                                <MemberAccordion memberData={member} userData={userData}/>
                            </div>
                        )
                    })}
                </form>

                <form onSubmit={handleRoleAddition}>
                    <button type="submit" className='btn btn-success w-100 mb-2'>Add selection to Role</button>

                    <SearchBar userMembers={restMembers} setFilteredUserMembers={setFilteredRestMembers} searchParameter="Full Name"/>
                    {filteredRestMembers.map((member, index) => {
                        return (
                            <div key={index} className="w-100 d-flex align-items-center">
                                <input type="checkbox" value={member.memberid} id={member.memberid} className='form-check-input me-3' onChange={updateAddUsers} checked={addUsers.includes(member.memberid) ? 'checked': ''}/>
                                <MemberAccordion memberData={member} userData={userData}/>
                            </div>
                        )
                    })}
                </form>
            </div>
        </div>
    )
}

export default EditRolesComponent;