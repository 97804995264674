import React from "react";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../contexts/user.context"
import { APIAuthContext } from "../../../contexts/api-auth.context";
import TitleSectionMin from "../../../components/title-section-min/title-section";

import '../add-members/add-members.css';
import base64 from "base-64";

function removeFromArray(array, value){
    for (var i = array.length - 1; i >= 0; i--) {
     if (array[i] === value) {
      array.splice(i, 1);
     }
    }
  }

  function isEighteenOrOlder(dateOfBirth) {
    const today = new Date();
    const dob = new Date(dateOfBirth);
    var age = today.getFullYear() - dob.getFullYear();
  
    // Adjust age if birthday hasn't occurred yet this year
    if (today.getMonth() < dob.getMonth() || (today.getMonth() === dob.getMonth() && today.getDate() < dob.getDate())) {
      age--;
    }
  
    return age >= 18;
  }

const EditMember = ({ memberInfo, userData}) => {
    const { currentUser } = useContext(UserContext);
    const { authCreds } = useContext(APIAuthContext);
    const [churchOptions, setChurchOptions] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedChildRoles, setSelectedChildRoles] = useState([]);
    const [adultFormFields, setAdultFormFields] = useState(null);
    const [childFormFields, setChildFormFields] = useState(null);
    const [roles, setRoles] = useState(null);

    const [updateFormStatus, setUpdateFormStatus] = useState(null);

    const getChurchInfo = () => {
        var temp = []
        if(userData['church1'] !== '')
        {
            temp.push({'churchnum': 'church1', 'churchname': userData['church1']});
        }
        if(userData['church2'] !== '')
        {
            temp.push({'churchnum': 'church2', 'churchname': userData['church2']});
        }
        if(userData['church3'] !== '')
        {
            temp.push({'churchnum': 'church3', 'churchname': userData['church3']});
        }
        setChurchOptions(temp);
        console.log("church options: ", churchOptions);
    }

    const getRoles = () => {
        var headers = new Headers();
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;
        const encodedCredentials = base64.encode(`${username}:${password}`);
        headers.set('Authorization', 'Basic ' + encodedCredentials)
        fetch(`https://api.parishnavigator.com/get-roles/${userData.parishid}`, {headers: headers})
            .then((roles_result) => roles_result.json())
            .then((roles_result) => {
                console.log("api response = ", roles_result);
                setRoles(roles_result);
            })
    }

    useEffect(() => {
        if(userData !== null){
            getChurchInfo();
            getRoles();
            getMemberDetails();
        }
    }, [userData])

    const handleAdultChange = (event) => {
        const {name, value} = event.target;
        setAdultFormFields({...adultFormFields, [name]: value});
        console.log(adultFormFields);
    }

    const handleChildChange = (event) => {
        const {name, value} = event.target;
        setChildFormFields({...childFormFields, [name]: value});
        console.log(childFormFields);
    }

    const getMemberDetails = async() => {
        let token = await currentUser.getIdToken();
        fetch(`https://api.parishnavigator.com/get-member-info/${memberInfo.memberid}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: "include"
        })
            .then((member_info) => member_info.json())
            .then((member_info) => {
                if(member_info['status'] === "true")
                {
                    console.log("member-info: " + member_info['memberInfo']);
                    if(member_info['memberInfo'].hasOwnProperty('parentemail'))
                    {
                        setChildFormFields(member_info['memberInfo']);
                        setSelectedChildRoles(member_info['memberInfo'].roles);
                    }
                    else{
                        setAdultFormFields(member_info['memberInfo']);
                        setSelectedRoles(member_info['memberInfo'].roles);
                    }
                }
            })
    }

    const updateSelectedRoles = (event) => {
        var temp = selectedRoles;
        if(event.target.checked === true)
        {
            temp.push(event.target.value);
        } else {
            removeFromArray(temp, event.target.value);
        }

        setSelectedRoles([...temp]);
        console.log("selected roles: ", selectedRoles);
    }

    const updateSelectedChildRoles = (event) => {
        var temp = selectedChildRoles;
        if(event.target.checked === true)
        {
            temp.push(event.target.value);
        } else {
            removeFromArray(temp, event.target.value);
        }

        setSelectedChildRoles([...temp]);
        console.log("selected roles: ", selectedRoles);
    }

    const updateAdultInfo = async(event) => {
        event.preventDefault();
        console.log("Information: ", adultFormFields);
        console.log("Final Role Seleection: ", selectedRoles);

        if(!isEighteenOrOlder(adultFormFields.dob))
        {
            alert("Aduts must be 18 years old or older");
            return null;
        }

        var postJson = adultFormFields;
        postJson['roles'] = selectedRoles;

        let token = await currentUser.getIdToken();
        fetch("https://api.parishnavigator.com/update-member", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "member_id": memberInfo.memberid,
                "memberData": postJson
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response['status'] === "true"){
                setUpdateFormStatus(true);
            } else {
                setUpdateFormStatus(false);
            }
        })
    }

    const updateChildInfo = async(event) => {
        event.preventDefault();
        console.log("Information: ", childFormFields);
        console.log("Final Role Seleection: ", selectedChildRoles);

        if(isEighteenOrOlder(childFormFields.dob))
        {
            alert("Aduts must be 18 years old or older");
            return null;
        }

        var postJson = childFormFields;
        postJson['roles'] = selectedChildRoles;

        let token = await currentUser.getIdToken();
        fetch("https://api.parishnavigator.com/update-member", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "member_id": memberInfo.memberid,
                "memberData": postJson
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response['status'] === "true"){
                setUpdateFormStatus(true);
            } else {
                setUpdateFormStatus(false);
            }
        })
    }

    if(userData !== null && roles !== null)
    {
        if(adultFormFields === null && childFormFields !== null)
        {
            return (
                <div>
                    <TitleSectionMin title={"Edit Child Member - " + childFormFields.forename + " " + childFormFields.surname} subtitle="" />

                    <div className="p-4">
                    <form className="formGrid w-100 flex-fill " onSubmit={updateChildInfo}>
                                    
                                    <div className="d-flex flex-column mb-3">
                                        <label>First Name*</label>
                                        <input required type="text" className="form-control" name="forename" value={childFormFields.forename} onChange={handleChildChange} />
                                    </div>

                                    <div className="d-flex flex-column mb-3">
                                        <label>Last Name*</label>
                                        <input required type="text" className="form-control" name="surname" value={childFormFields.surname} onChange={handleChildChange} />
                                    </div>

                                    <div className="d-flex flex-column mb-3">
                                        <label>Email (not required)*</label>
                                        <input type="email" className="form-control" name="email" value={childFormFields.email} onChange={handleChildChange} />
                                    </div>

                                    <div className="d-flex flex-column mb-3">
                                        <label>Parent's Email*</label>
                                        <input required type="email" className="form-control" name="parentemail" value={childFormFields.parentemail} onChange={handleChildChange} />
                                    </div>

                                    <div className="d-flex flex-column mb-3">
                                        <label>Date of Birth*</label>
                                        <input required type="date" className="form-control" name="dob" value={childFormFields.dob} onChange={handleChildChange} />
                                    </div>

                                    <div className="d-flex flex-column mb-3">
                                        <label>Home Address*</label>
                                        <input required type="text" className="form-control" name="address" value={childFormFields.address} onChange={handleChildChange} />
                                    </div>

                                    <div className="d-flex flex-column mb-3">
                                        <label>School / Occupation</label>
                                        <input required type="text" className="form-control" name="occupation" value={childFormFields.occupation} onChange={handleChildChange} />
                                    </div>

                                    <div className="d-flex flex-column mb-3">
                                        <label>Church</label>
                                        <select required className="form-select" name="church" value={childFormFields.church} onChange={handleChildChange} >
                                            {churchOptions.map((church) => {
                                            return(
                                                <option value={church.churchnum}>{church.churchname}</option>
                                            );
                                            })}
                                        </select>
                                    </div>

                                    <div className="rolesContainer d-flex flex-wrap">
                                        {roles.map((role, index) => {
                                            return (
                                                <div className="me-4 mb-3">
                                                    <input type="checkbox" className="form-check-input me-2" id={role.roleid} value={role.roleid} checked={(selectedChildRoles.includes(role.roleid) ? 'checked': '' )} onChange={updateSelectedChildRoles}/>
                                                    <label for={role.roleid}>{role.rolename}</label>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    <div>
                                    {updateFormStatus === true && (
                                        <p className="align-self-start mt-4 successMessage"><i>Successfully updated Parishioner details</i></p>
                                    )}
                                    {updateFormStatus === false && (
                                        <p className="align-self-start mt-4 failureMessage"><i>There was an error updating the Parishioner details.</i></p>
                                    )
                                    }
                                        <button type="submit" className="btn btn-primary mt-4">Submit</button>
                                    </div>
                            

                                </form>
                    </div>
                </div>
            )
        }
        if(childFormFields === null && adultFormFields !== null)
            return (
                <div>
                    <TitleSectionMin title={"Edit Member - " + adultFormFields.forename + " " + adultFormFields.surname} subtitle=""/>

                    <div className="p-4">
                        <form className="formGrid w-100 flex-fill " onSubmit={updateAdultInfo}>
                            
                            <div className="d-flex flex-column mb-3">
                                <label>First Name*</label>
                                <input required type="text" className="form-control" name="forename" value={adultFormFields.forename} onChange={handleAdultChange} />
                            </div>

                            <div className="d-flex flex-column mb-3">
                                <label>Last Name*</label>
                                <input required type="text" className="form-control" name="surname" value={adultFormFields.surname} onChange={handleAdultChange} />
                            </div>

                            <div className="d-flex flex-column mb-3">
                                <label>Email*</label>
                                <input required type="email" className="form-control" name="email" value={adultFormFields.email} onChange={handleAdultChange} />
                            </div>

                            <div className="d-flex flex-column mb-3">
                                <label>Phone Number</label>
                                <input type="phone" className="form-control" name="phone" value={adultFormFields.phone} onChange={handleAdultChange} />
                            </div>

                            <div className="d-flex flex-column mb-3">
                                <label>Date of Birth*</label>
                                <input required type="date" className="form-control" name="dob" value={adultFormFields.dob} onChange={handleAdultChange} />
                            </div>

                            <div className="d-flex flex-column mb-3">
                                <label>Home Address*</label>
                                <input required type="text" className="form-control" name="address" value={adultFormFields.address} onChange={handleAdultChange} />
                            </div>

                            <div className="d-flex flex-column mb-3">
                                <label>Occupation</label>
                                <input required type="text" className="form-control" name="occupation" value={adultFormFields.occupation} onChange={handleAdultChange} />
                            </div>

                            <div className="d-flex flex-column mb-3">
                                <label>Church</label>
                                <select required className="form-select" name="church" value={adultFormFields.church} onChange={handleAdultChange} >
                                    {churchOptions.map((church) => {
                                    return(
                                        <option value={church.churchnum}>{church.churchname}</option>
                                    );
                                    })}
                                </select>
                            </div>

                            <div className="rolesContainer d-flex flex-wrap">
                                {roles.map((role, index) => {
                                    return (
                                        <div className="me-4 mb-3">
                                            <input type="checkbox" className="form-check-input me-2" id={role.roleid} value={role.roleid} checked={(selectedRoles.includes(role.roleid) ? 'checked': '' )} onChange={updateSelectedRoles}/>
                                            <label for={role.roleid}>{role.rolename}</label>
                                        </div>
                                    )
                                })}
                            </div>

                            <div>
                            {updateFormStatus === false && (
                                <p className="align-self-start mt-4 failureMessage"><i>There was an error adding the new Parish Member</i></p>
                            )
                            }
                            {updateFormStatus === true && (
                                <p className="align-self-start mt-4 text-success"><i>Member Data successfully updated</i></p>
                            )
                            }
                                <button type="submit" className="btn btn-primary mt-4">Update Details</button>
                            </div>
                    
                        </form>
                    </div>
                </div>
            )
    }

}

export default EditMember;