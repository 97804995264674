import { BeatLoader } from 'react-spinners';
import ParishNavLogo from '../../assets/navbar-icons/ParishNavigator-Logo.png';

const LoadingState = () => {
    return (
    <div className='d-flex flex-column justify-content-center align-items-center' style={{width: '100%', height: '100vh'}}>
        <div className='d-flex justify-content-center align-items-center'>
        <img src={ParishNavLogo} alt="" style={{height: '25%'}} className='mb-3'/>
        <h1 className='ms-2'><b>ParishNavigator</b></h1>
        </div>
        <BeatLoader color="#36d7b7"/>
    </div>)
}

export default LoadingState;