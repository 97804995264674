import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/user.context";
import { fetchUserData } from "../../../utils/firebase/firebase.util";
import { useSearchParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
// import './add-members.css';
import './add-members.css';

// components
import FunctionWrapper from "../../../components/function-area-wrapper/function-wrapper.component";
import TitleSection from "../../../components/title-section/title-section";
import { query } from "firebase/firestore";
import userEvent from "@testing-library/user-event";
import { APIAuthContext } from "../../../contexts/api-auth.context";
import Modal from 'react-modal';
import TitleSectionMin from "../../../components/title-section-min/title-section";
import base64, { encode } from 'base-64';


function isEighteenOrOlder(dateOfBirth) {
    const today = new Date();
    const dob = new Date(dateOfBirth);
    var age = today.getFullYear() - dob.getFullYear();
  
    // Adjust age if birthday hasn't occurred yet this year
    if (today.getMonth() < dob.getMonth() || (today.getMonth() === dob.getMonth() && today.getDate() < dob.getDate())) {
      age--;
    }
  
    return age >= 18;
  }

const defaultAdultFormFields = {
    forename: '',
    surname: '',
    email: '',
    phone: '',
    dob: '',
    address: '',
    occupation: '',
    church: 'church1'
}

const defaultChildFormFields = {
    forename: '',
    surname: '',
    email: '',
    parentemail: '',
    dob: '',
    address: '',
    occupation: '',
    church: 'church1'
}

function removeFromArray(array, value){
    for (var i = array.length - 1; i >= 0; i--) {
     if (array[i] === value) {
      array.splice(i, 1);
     }
    }
  }

const OnlineParishRegistration = () => {
    const [queryParameters] = useSearchParams();
    const [invalidPage, setInvalidPage] = useState(false);
    const [parishId, setParishId] = useState(null);
    const [parishName, setParishName] = useState('----');
    const [roles, setRoles] = useState(null);
    const [churchOptions, setChurchOptions] = useState([]);
    const { authCreds } = useContext(APIAuthContext);

    // adult form fields
    const [adultFormFields, setAdultFormFields] = useState(defaultAdultFormFields);
    const { forename, surname, email, phone, dob, address, occupation, church} = adultFormFields;
    const [selectedRoles, setSelectedRoles] = useState([]);

    // child form fields
    const [childFormFields, setChildFormFields] = useState(defaultChildFormFields);
    const { cforename, csurname, cemail, cparentemail, cdob, caddress, coccupation, cchurch} = childFormFields;
    const [selectedChildRoles, setSelectedChildRoles] = useState([]);

    // form status
    const [adultFormStatus, setAdultFormStatus] = useState(null);
    const [childFormStatus, setChildFormstatus] = useState(null);

    useEffect(() => {
        document.title = "Parish Registration Online";
    })

    const handleAdultChange = (event) => {
        const {name, value} = event.target;
        setAdultFormFields({...adultFormFields, [name]: value});
        console.log(adultFormFields);
    }
    
    const handleChildChange = (event) => {
        const {name, value} = event.target;
        setChildFormFields({...childFormFields, [name]: value});
        console.log(childFormFields);
    }

    const updateSelectedRoles = (event) => {
        console.log(event.target.value, event.target.checked);
        var temp = selectedRoles;

        if(event.target.checked === true)
        {
            temp.push(event.target.value);
        } else {
            removeFromArray(temp, event.target.value);
        }

        setSelectedRoles(temp);
        console.log("selected roles: ", selectedRoles);
    }

    const updateSelectedChildRoles = (event) => {
        console.log(event.target.value, event.target.checked);
        var temp = selectedChildRoles;

        if(event.target.checked === true)
        {
            temp.push(event.target.value);
        } else {
            removeFromArray(temp, event.target.value);
        }

        setSelectedChildRoles(temp);
        console.log("selected child roles: ", selectedChildRoles);
    }

    const handleNewAdult = async(event) => {
        event.preventDefault();
        console.log("Information: ", adultFormFields);
        console.log("Final Role Seleection: ", selectedRoles);

        if(!isEighteenOrOlder(adultFormFields.dob))
        {
            alert("Aduts must have 18 years old or older");
            return null;
        }

        var postJson = adultFormFields;
        postJson['roles'] = selectedRoles;

        var headers = new Headers();
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;
        const encodedCredentials = base64.encode(`${username}:${password}`);
        headers.set('Authorization', 'Basic ' + encodedCredentials)
        headers.set('content-type', 'application/json');
        fetch("https://api.parishnavigator.com/add-new-member-online", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                // "user_id": currentUser.uid,
                "user_id": parishId,
                "memberData": postJson
            })
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response['status'] === "true"){
                setAdultFormStatus(true);
            } else {
                setAdultFormStatus(false);
            }
        })
    }

    const handleNewChild = async(event) => {
        event.preventDefault();
        console.log("Information: ", childFormFields);
        console.log("Final Role Seleection: ", selectedChildRoles);

        console.log("isEighteen years old: ", isEighteenOrOlder(childFormFields.dob));
        if(isEighteenOrOlder(childFormFields.dob))
        {
            alert("Children must be under 18 years old");
            return null;
        }

        var postJson = childFormFields;
        postJson['roles'] = selectedChildRoles;
        var headers = new Headers();
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;
        const encodedCredentials = base64.encode(`${username}:${password}`);
        headers.set('Authorization', 'Basic ' + encodedCredentials)
        headers.set('content-type', 'application/json');
        fetch("https://api.parishnavigator.com/add-new-member-online", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                "user_id": parishId,
                "memberData": postJson
            })
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response['status'] === "true"){
                setChildFormstatus(true);
            } else {
                setChildFormstatus(false);
            }
        })
    }

    // Online Baptism Form Effect
    useEffect(() => {
        setParishId(queryParameters.get("parish"));

        if(parishId !== null){
            var headers = new Headers();
            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;
            const encodedCredentials = base64.encode(`${username}:${password}`);
            headers.set('Authorization', 'Basic ' + encodedCredentials);
            fetch(`https://api.parishnavigator.com/get-churches-parish/${parishId}`, {headers: headers})
            .then((response) => response.json())
            .then((response) => {
                console.log("Churches = ", response);

                if(response['status'] === "false")
                {
                    setInvalidPage(true);
                } else {
                    setParishName(response['parishName']);

                    const parishData = response;
                    var temp = [];

                    if(parishData['church1'] !== '')
                    {
                        temp.push({'churchnum': 'church1', 'churchname': parishData['church1']});
                    }
                    if(parishData['church2'] !== '')
                    {
                        temp.push({'churchnum': 'church2', 'churchname': parishData['church2']});
                    }
                    if(parishData['church3'] !== '')
                    {
                        temp.push({'churchnum': 'church3', 'churchname': parishData['church3']});
                    }

                    setChurchOptions(temp);
                }
            })
        }
        
    }, [parishId])

    useEffect(() => {
        if(parishId !== null)
        {
            var headers = new Headers();
            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;
            const encodedCredentials = base64.encode(`${username}:${password}`);
            headers.set('Authorization', 'Basic ' + encodedCredentials)
            fetch(`https://api.parishnavigator.com/get-roles/${parishId}`, {headers: headers})
                .then((roles_result) => roles_result.json())
                .then((roles_result) => {
                    console.log("api response = ", roles_result);
                    setRoles(roles_result);
                })
        }
    }, [parishId])

    // Modal stuff
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: "20px"
        },
      };
    
    function closeAdultModal() {
        setAdultFormStatus(null);
    }

    function closeChildModal() {
        setChildFormstatus(null);
    }
    
    if(queryParameters.get("parish") !== null && invalidPage === false && roles !== null)
    {
        return (
            <div className="mb-5">
                <Modal
                    isOpen={adultFormStatus}
                    style={customStyles}
                    onRequestClose={closeAdultModal}
                    contentLabel="Example Modal"
                >
                    
                    <div>
                        <h2 className='text-success' >Success</h2>
                        <p>Successfully added new member, {adultFormFields.forename}</p>
                    </div>

                    <button className='btn btn-primary mt-3' onClick={closeAdultModal}>Close</button>
                </Modal>

                <Modal
                    isOpen={childFormStatus}
                    style={customStyles}
                    onRequestClose={closeChildModal}
                    contentLabel="Child Form"
                >
                    
                    <div>
                        <h2 className='text-success' >Success</h2>
                        <p>Successfully added new member, {childFormFields.forename}</p>
                    </div>

                    <button className='btn btn-primary mt-3' onClick={closeChildModal}>Close</button>
                </Modal>


                <FunctionWrapper>
                    <TitleSectionMin title={parishName + " Parish Registration Form"} subtitle={"Become a registered member of the Parish of " + parishName}/>

                    <div className="p-4 mb-5">
                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Adult</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Child</button>
                            </li>
                        </ul>
                        <div class="tab-content pb-5" id="pills-tabContent">
                            {/* Adult */}
                            <div class="tab-pane fade show active pb-5" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <form className="formGrid w-100 flex-fill " onSubmit={handleNewAdult}>
                                        
                                        <div class="d-flex flex-column mb-3">
                                            <label>First Name*</label>
                                            <input required type="text" className="form-control" name="forename" onChange={handleAdultChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Last Name*</label>
                                            <input required type="text" className="form-control" name="surname" onChange={handleAdultChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Email*</label>
                                            <input required type="email" className="form-control" name="email" onChange={handleAdultChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Phone Number</label>
                                            <input type="phone" className="form-control" name="phone" onChange={handleAdultChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Date of Birth*</label>
                                            <input required type="date" className="form-control" name="dob" onChange={handleAdultChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Home Address*</label>
                                            <input required type="text" className="form-control" name="address" onChange={handleAdultChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Occupation</label>
                                            <input required type="text" className="form-control" name="occupation" onChange={handleAdultChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Church</label>
                                            <select required className="form-select" name="church" onChange={handleAdultChange} >
                                                {churchOptions.map((church) => {
                                                return(
                                                    <option value={church.churchnum}>{church.churchname}</option>
                                                );
                                                })}
                                            </select>
                                        </div>

                                        <div className="rolesContainer d-flex flex-wrap">
                                            {roles.map((role, index) => {
                                                return (
                                                    <div className="me-4 mb-3">
                                                        <input onChange={updateSelectedRoles} type="checkbox" class="form-check-input me-2" id={role.roleid} value={role.roleid}/>
                                                        <label for={role.roleid}>{role.rolename}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        
                                        <div className="rolesContainer">
                                            <p><i>I consent to the processing of my data for parish management purposes as outlined in the privacy policy.</i></p>
                                        </div>

                                        <div>
                                        {adultFormStatus === false && (
                                            <p className="align-self-start mt-4 failureMessage"><i>There was an error adding the new Parish Member</i></p>
                                        )
                                        }
                                            <button type="submit" className="btn btn-primary mt-4">Submit</button>
                                        </div>
                                
                                    </form>
                                    
                                    
                                </div>
                            </div>
                            
                            {/* Child */}
                            <div class="tab-pane fade pb-5" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="1">

                            <div class="d-flex flex-column justify-content-center align-items-center">
                                    <form className="formGrid w-100 flex-fill " onSubmit={handleNewChild}>
                                        
                                        <div class="d-flex flex-column mb-3">
                                            <label>First Name*</label>
                                            <input required type="text" className="form-control" name="forename" onChange={handleChildChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Last Name*</label>
                                            <input required type="text" className="form-control" name="surname" onChange={handleChildChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Email (not required)*</label>
                                            <input type="email" className="form-control" name="email" onChange={handleChildChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Parent's Email*</label>
                                            <input required type="email" className="form-control" name="parentemail" onChange={handleChildChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Date of Birth*</label>
                                            <input required type="date" className="form-control" name="dob" onChange={handleChildChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Home Address*</label>
                                            <input required type="text" className="form-control" name="address" onChange={handleChildChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>School / Occupation</label>
                                            <input required type="text" className="form-control" name="occupation" onChange={handleChildChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Church</label>
                                            <select required className="form-select" name="church" onChange={handleChildChange} >
                                                {churchOptions.map((church) => {
                                                return(
                                                    <option value={church.churchnum}>{church.churchname}</option>
                                                );
                                                })}
                                            </select>
                                        </div>

                                        <div className="rolesContainer d-flex flex-wrap">
                                            {roles.map((role, index) => {
                                                return (
                                                    <div className="me-4 mb-3">
                                                        <input onChange={updateSelectedChildRoles} type="checkbox" class="form-check-input me-2" id={role.roleid+index} value={role.roleid}/>
                                                        <label for={role.roleid+index}>{role.rolename}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        <div className="rolesContainer"><p><i>I confirm that I have the legal authority or parental consent to provide this information for parish management purposes, as outlined in the privacy policy</i></p></div>
                                        

                                        <div>
                                        {childFormStatus === false && (
                                            <p className="align-self-start mt-4 failureMessage"><i>There was an error adding the new Parish Member</i></p>
                                        )
                                        }
                                            <button type="submit" className="btn btn-primary mt-4">Submit</button>
                                        </div>
                                

                                    </form>

                                    
                                </div>

                            </div>
                        </div>

                    </div>

                    
                </FunctionWrapper>
            </div>
    );
    }
    else {
        return (
            <p>loading</p>
        );
    }
}

export default OnlineParishRegistration;