import React from 'react';
import { useEffect } from 'react';
import './sidebar.css';
import AddMembersComponent from '../../function-components/members/add-members/add-members.component';
import AccountDashboardComponent from '../../function-components/dashboard/account-dashboard.component';
import DeleteMembersComponent from '../../function-components/members/delete-members/delete-members.component';
import ViewMembersComponent from '../../function-components/members/view-members/view-members.component';
import CreateRoleComponent from '../../function-components/roles/create-role/create-role.component';
import ViewRolesComponent from '../../function-components/roles/view-roles/view-roles.component';
import SendRoleEmailComponent from '../../function-components/roles/send-role-emails/send-role-email.component';
import CreateGroupComponent from '../../function-components/groups/create-group/create-group.component';
import SendGroupEmailComponent from '../../function-components/groups/send-group-emails/send-group-emails.component';
import ViewGroupsComponent from '../../function-components/groups/view-groups/view-groups.component';
import AddFuneralComponent from '../../function-components/funerals/add-funeral/add-funeral.component';
import ViewFuneralsComponent from '../../function-components/funerals/view-funerals/view-funerals.component';
import ViewBaptismsComponent from '../../function-components/baptisms/view-baptisms/view-baptisms.component';
import ToDoComponent from '../../function-components/to-do/to-do.component';
import AIAssistantComponent from '../../function-components/ai-assistant/ai-assistant.component';

import ParishNavigatorLogo from '../../assets/navbar-icons/ParishNavigator-Logo.png';
import { signOutUser } from '../../utils/firebase/firebase.util';
import CreateSubUserComponent from '../../function-components/sub-users/create-sub-users/create-sub-users.component';
import ViewSubUsersComponent from '../../function-components/sub-users/view-sub-users/view-sub-users.component';
import ViewContactFormsComponent from '../../function-components/contact-us/view-contact-us/view-contact-forms.component';
import EmailAllMembers from '../../function-components/members/email-all-members/email-all-members.component.tsx';
import HomeDashboard from '../home-dashboard/home-dashboard.component.tsx';
import FormCreator from '../../function-components/custom-forms/form-creator/form-creator.component.tsx';
import ViewForms from '../../function-components/custom-forms/view-forms/view-forms.component.tsx';
import FinanceManager from '../../function-components/finances/finances.component.tsx';
const ParishNavSidebar = ({userData, childComponent, setChildComponent, setUserData}) => {

    useEffect(() => {
        const sidebar = document.querySelector(".sidebar");
        const submenuItems = document.querySelectorAll(".submenu_item");
        const sidebarOpen = document.querySelector("#sidebarOpen");
        const sidebarClose = document.querySelector(".collapse_sidebar");
        const sidebarExpand = document.querySelector(".expand_sidebar");
        const content = document.querySelector('.content');
    
        const handleSidebarToggle = () => {
          sidebar.classList.toggle("close");
        };
    
        const handleSidebarClose = () => {
          sidebar.classList.add("close", "hoverable");
          // Assuming content is accessible in your component
          // You might need to use useRef to get a reference to it
        //   content.classList.add("wide");
        };
    
        const handleSidebarExpand = () => {
          sidebar.classList.remove("close", "hoverable");
          // Assuming content is accessible in your component
          // You might need to use useRef to get a reference to it
        //   content.classList.remove('wide');
        };
    
        const handleMouseEnter = () => {
          if (sidebar.classList.contains("hoverable")) {
            sidebar.classList.remove("close");
          }
        };
    
        const handleMouseLeave = () => {
          if (sidebar.classList.contains("hoverable")) {
            sidebar.classList.add("close");
          }
        };
    
    
        window.addEventListener('resize', () => {
          if (window.innerWidth < 768) {
            sidebar.classList.add("close");
          } else {
            sidebar.classList.remove("close");
          }
        });

        
        sidebarOpen.addEventListener("click", handleSidebarToggle);
        sidebarClose.addEventListener("click", handleSidebarClose);
        sidebarExpand.addEventListener("click", handleSidebarExpand);
        sidebar.addEventListener("mouseenter", handleMouseEnter);
        sidebar.addEventListener("mouseleave", handleMouseLeave);
    
        // Clean up event listeners when the component unmounts
        return () => {
          sidebarOpen.removeEventListener("click", handleSidebarToggle);
          sidebarClose.removeEventListener("click", handleSidebarClose);
          sidebarExpand.removeEventListener("click", handleSidebarExpand);
          sidebar.removeEventListener("mouseenter", handleMouseEnter);
          sidebar.removeEventListener("mouseleave", handleMouseLeave);
        };
      }, []);

    return (
        <>
            <nav className='navbar'>
                <div className="logo-item d-flex justify-content-center align-items-center">
                    <i className="bx bx-menu" id='sidebarOpen'></i>
                    <><img src={ParishNavigatorLogo} alt="" /><p className='LogoTitle ms-2'>ParishNavigator</p></>
                </div>

                <div className="navbar_content">
                    <i className="bi bi-grid"></i>
                    {/* <i className='bx bx-sun' id="darkLight"></i>
                    <i className='bx bx-bell' ></i> */}

                    {('personalName' in userData) && (<p className='parishName'>{userData.personalName} - {userData.parishName}</p>)}
                    {!('personalName' in userData) && (<p className='parishName'>{userData.parishName}</p>)}
                    

                    <div class="dropdown">
                        {/* <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{border: 'none', background: 'none'}}> */}
                        <img src={userData.parishLogo} alt="" className="profile" />
                        {/* </button> */}
                        {/* <ul class="dropdown-menu dropdown-menu-end">
                            <li onClick={signOutUser}><a class="dropdown-item d-flex align-items-center text-danger" href="#">
                                <i className='bx bx-power-off my-auto me-2' style={{color: 'red'}}></i>
                                Logout</a></li>
                        </ul> */}
                    </div>
                </div>

            </nav>

            <div className='d-flex sidebarContentWrapper'>
                <nav className="sidebar">
                    <div className="menu_content">
                        <ul className="menu_items">
                            <div className="menu_title menu_dashboard"></div>
                            
                            {/* start */}
                            <li className="item" onClick={() => setChildComponent(<HomeDashboard userData={userData} setChildComponent={setChildComponent}/>)}>
                                <div href='#' className="nav_link">
                                    <span className="navlink_icon">
                                        <i className="bx bx-home"></i>
                                    </span>
                                    <span className="navlink">Home</span>
                                </div>
                            </li>
                            {/* end */}

                            {/* start */}
                            <li className="item" onClick={() => document.getElementById('membersNav').classList.toggle('show_submenu')}>
                                <div href='#' id='membersNav' className="nav_link submenu_item">
                                    <span className="navlink_icon">
                                        <i className="bx bx-user"></i>
                                    </span>
                                    <span className="navlink">Members</span>
                                    <i className="bx bx-chevron-right arrow-left"></i>
                                </div>

                                <ul className='menu_items submenu'>
                                    <a href="#" className="nav_link sublink" onClick={() => setChildComponent(<ViewMembersComponent userData={userData} setChildComponent={setChildComponent}/>)}>View Members</a>
                                    {((userData.account_type === "parish") || (userData.account_type === "Edit")) && (
                                        <>
                                        <a href="#" className="nav_link sublink" onClick={() => setChildComponent(<AddMembersComponent userData={userData}/>)}>Add Members</a>
                                        <a href="#" className="nav_link sublink" onClick={() => setChildComponent(<DeleteMembersComponent userData={userData} setChildComponent={setChildComponent}/>)}>Delete Members</a>
                                        <a href="#" className="nav_link sublink" onClick={() => setChildComponent(<EmailAllMembers userData={userData} />)}>Email All Members</a>
                                        </>
                                    )}
                                </ul>
                            </li>
                            {/* end */}

                            {/* start */}
                            <li className="item" onClick={() => document.getElementById('rolesNav').classList.toggle('show_submenu')}>
                                <div href='#' id='rolesNav' className="nav_link submenu_item">
                                    <span className="navlink_icon">
                                        <i className="bx bx-briefcase"></i>
                                    </span>
                                    <span className="navlink">Roles</span>
                                    <i className="bx bx-chevron-right arrow-left"></i>
                                </div>

                                <ul className='menu_items submenu'>
                                    {((userData.account_type === "parish") || (userData.account_type === "Edit")) && (
                                        <a href="#" className="nav_link sublink" onClick={() => setChildComponent(<CreateRoleComponent userData={userData}/>)}>Create Role</a>
                                    )}
                                    <a href="#" className="nav_link sublink" onClick={() => setChildComponent(<ViewRolesComponent userData={userData} setChildComponent={setChildComponent}/> )}>View Roles</a>
                                    <a href="#" className="nav_link sublink" onClick={() => setChildComponent(<SendRoleEmailComponent userData={userData} />)}>Send Role Email</a>
                                </ul>
                            </li>
                            {/* end */}

                            {/* start */}
                            <li className="item" onClick={() => document.getElementById('groupNav').classList.toggle('show_submenu')}>
                                <div href='#' id='groupNav' className="nav_link submenu_item">
                                    <span className="navlink_icon">
                                        <i className="bx bx-group"></i>
                                    </span>
                                    <span className="navlink">Groups</span>
                                    <i className="bx bx-chevron-right arrow-left"></i>
                                </div>

                                <ul className='menu_items submenu'>
                                    {((userData.account_type === "parish") || (userData.account_type === "Edit")) && (
                                        <a href="#" className="nav_link sublink" onClick={() => setChildComponent(<CreateGroupComponent userData={userData}/>)}>Create Group</a>
                                    )}
                                    <a href="#" className="nav_link sublink" onClick={() => setChildComponent(<ViewGroupsComponent userData={userData} setChildComponent={setChildComponent} />)}>View Groups</a>
                                    <a href="#" className="nav_link sublink" onClick={() => setChildComponent(<SendGroupEmailComponent userData={userData} />)}>Send Group Email</a>
                                </ul>
                            </li>
                            {/* end */}

                            {/* start */}
                            <li className="item" onClick={() => document.getElementById('funeralNav').classList.toggle('show_submenu')}>
                                <div href='#' id='funeralNav' className="nav_link submenu_item">
                                    <span className="navlink_icon">
                                        <i className="bx bx-church"></i>
                                    </span>
                                    <span className="navlink">Funerals</span>
                                    <i className="bx bx-chevron-right arrow-left"></i>
                                </div>

                                <ul className='menu_items submenu'>
                                    {((userData.account_type === "parish") || (userData.account_type === "Edit")) && (
                                        <a href="#" className="nav_link sublink" onClick={() => setChildComponent(<AddFuneralComponent userData={userData}/>)}>Add Funerals</a>
                                    )}
                                    <a href="#" className="nav_link sublink" onClick={() => setChildComponent(<ViewFuneralsComponent userData={userData} setChildComponent={setChildComponent} />)}>View Funerals</a>
                                </ul>
                            </li>
                            {/* end */}

                            
                            {(userData.account_type === "parish") && (
                                <li className="item" onClick={() => document.getElementById('subuserNav').classList.toggle('show_submenu')}>
                                    <div href='#' id='subuserNav' className="nav_link submenu_item">
                                        <span className="navlink_icon">
                                            <i className="bx bx-user-circle"></i>
                                        </span>
                                        <span className="navlink">Sub Users</span>
                                        <i className="bx bx-chevron-right arrow-left"></i>
                                    </div>

                                    <ul className='menu_items submenu'>
                                        <a href="#" className="nav_link sublink" onClick={() => setChildComponent(<CreateSubUserComponent userData={userData}/>)}>Create Users</a>
                                        <a href="#" className="nav_link sublink" onClick={() => setChildComponent(<ViewSubUsersComponent userData={userData} />)}>View Users</a>
                                    </ul>
                                </li>
                            )}

                            <div className="menu_title menu_forms"></div>
                            {/* start */}
                            <li className="item" onClick={() => setChildComponent(<ViewBaptismsComponent userData={userData} setChildComponent={setChildComponent}/>)}>
                                <div href='#' className="nav_link">
                                    <span className="navlink_icon">
                                        <i className="bx bx-droplet"></i>
                                    </span>
                                    <span className="navlink">Baptism Forms</span>
                                </div>
                            </li>
                            {/* end */}

                            {/* start */}
                            <li className="item" onClick={() => setChildComponent(<ViewContactFormsComponent userData={userData} setChildComponent={setChildComponent} />)}>
                                <div href='#' className="nav_link">
                                    <span className="navlink_icon">
                                        <i className="bx bx-support"></i>
                                    </span>
                                    <span className="navlink">Contact us Forms</span>
                                </div>
                            </li>
                            {/* end */}

                            {/* start */}
                            <li className="item" onClick={() => document.getElementById('formNav').classList.toggle('show_submenu')}>
                                <div href='#' id='formNav' className="nav_link submenu_item">
                                    <span className="navlink_icon">
                                        <i className="bx bx-clipboard"></i>
                                    </span>
                                    <span className="navlink">Custom Forms</span>
                                    <i className="bx bx-chevron-right arrow-left"></i>
                                </div>

                                <ul className='menu_items submenu'>
                                    {((userData.account_type === "parish") || (userData.account_type === "Edit")) && (
                                        <a href="#" className="nav_link sublink" onClick={() => setChildComponent(<FormCreator userData={userData} />)}>Create Form</a>
                                    )}
                                    <a href="#" className="nav_link sublink" onClick={() => setChildComponent(<ViewForms userData={userData} setChildComponent={setChildComponent} />)}>View Forms</a>
                                </ul>
                            </li>
                            {/* end */}

                            <div className="menu_title menu_tools"></div>
                            {/* start */}
                            <li className="item" onClick={() => setChildComponent(<FinanceManager userData={userData} />)}>
                                <div href='#' className="nav_link">
                                    <span className="navlink_icon">
                                        <i className="bx bx-dollar-circle"></i>
                                    </span>
                                    <span className="navlink">Collections</span>
                                </div>
                            </li>
                            {/* end */}

                            {/* start */}
                            <li className="item" onClick={() => setChildComponent(<ToDoComponent userData={userData} />)}>
                                <div href='#' className="nav_link">
                                    <span className="navlink_icon">
                                        <i className="bx bx-list-check"></i>
                                    </span>
                                    <span className="navlink">To Do List</span>
                                </div>
                            </li>
                            {/* end */}

                            {/* start */}
                            <li className="item" onClick={() => setChildComponent(<AIAssistantComponent />)}>
                                <div href='#' className="nav_link">
                                    <span className="navlink_icon">
                                        <i className="bx bx-brain"></i>
                                    </span>
                                    <span className="navlink">AI Assistant</span>
                                </div>
                            </li>
                            {/* end */}

                            {/* start */}
                            <li className="item" onClick={() => setChildComponent(<AccountDashboardComponent userData={userData} setUserData={setUserData} setChildComponent={setChildComponent}/>)}>
                                    <div href='#' className="nav_link">
                                        <span className="navlink_icon">
                                            <i className="bx bx-cog"></i>
                                        </span>
                                        <span className="navlink">Account</span>
                                    </div>
                            </li>
                            {/* End */}

                            {/* start */}
                            <li className="item mt-auto" onClick={signOutUser}>
                                    <div href='#' className="nav_link">
                                        <span className="navlink_icon">
                                            <i className="bx bx-power-off" style={{color: "red"}}></i>
                                        </span>
                                        <span className="navlink text-danger">Logout</span>
                                    </div>
                            </li>
                            {/* End */}

                            <div className="" style={{height: "150px"}}></div>

                            
                        </ul>

                        {/* Sidebar open/close */}
                        <div className="bottom_content">
                            
                            <div className="bottom expand_sidebar">
                                
                                <span> Expand</span>
                                <i className='bx bx-log-in' ></i>
                            </div>
                            <div className="bottom collapse_sidebar">
                                <span> Collapse</span>
                                <i className='bx bx-log-out' />
                            </div>
                        </div>

                    </div>
                </nav>

                <div className='flex-grow-1 p-3 overflow-auto ' style={{height: "100vh"}}>
                    {childComponent}
                    <div style={{height: "100px"}}></div>
                </div>
            </div>


            {/* <div className="content">
                <AddMembersComponent className="contentItem"/>
            </div> */}
        </>
    );
}

export default ParishNavSidebar;