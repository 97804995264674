import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { APIAuthContext } from "../../contexts/api-auth.context";
import TitleSectionMin from "../../components/title-section-min/title-section";

import { Model } from 'survey-core';
import { Survey } from "survey-react-ui";
import FunctionWrapper from "../../components/function-area-wrapper/function-wrapper.component";
import { useRadioGroup } from "@mui/material";
import { storage } from "../../utils/firebase/firebase.util";
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import TitleSection from "../../components/title-section/title-section";
import base64 from 'base-64';


const OnlineCustomForm = () => {
    const [queryParameters] = useSearchParams();
    const [parish, setParish] = useState<string>('');
    const [form, setForm] = useState('');
    const [showThanks, setShowThanks] = useState(false);
    const [formStatus, setFormStatus] = useState("");

    const [formJSON, setFormJSON] = useState({});

    const { authCreds } = useContext(APIAuthContext);

    useEffect(() => {
        const mParish = queryParameters.get("p") as string;
        const mForm = queryParameters.get("f") as string;
        setParish(mParish);
        setForm(mForm);

        var headers = new Headers();
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;
        const encodedCredentials = base64.encode(`${username}:${password}`);
        headers.set('Authorization', 'Basic ' + encodedCredentials)
        fetch(`https://api.parishnavigator.com/get-custom-form/${mParish}/${mForm}`, {headers: headers})
            .then((response) => response.json())
            .then((response) => {
                if(response['status'] === "true")
                {
                    console.log(response);
                    const newJSON = response['formJSON'];
                    setFormJSON({...newJSON});
                }
            });
    }, []);

    const onFileUpload = (sender, options) => {
        console.log("File Uploaded");
    }

    const handleSurveyComplete = (survey, options) => {
        console.log("Survey: ", survey.data);
        console.log("Options: ", options);

        var headers = new Headers();
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;
        const encodedCredentials = base64.encode(`${username}:${password}`);
        headers.set('Authorization', 'Basic ' + encodedCredentials)
        headers.set('content-type', 'application/json');
        fetch("https://api.parishnavigator.com/submit-custom-form", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                "parish_id": parish,
                "form_id": form,
                "formData": survey.data
            })
        }).then((response) => response.json())
        .then((response) => {
            setShowThanks(true);
            if(response['status'] === "true")
            {
                setFormStatus("Form Submitted Successfully");
            } else {
                setFormStatus("There was an error submitting the form");
            }
        })
    }

    if(showThanks)
    {
        return (
            <FunctionWrapper>
                <div className="p-4">
                    <TitleSectionMin title="Thank you for the form submission" subtitle={formStatus} />
                </div>
            </FunctionWrapper>
        )
    }

    else if(formJSON !== null)
    {
        return (
                <FunctionWrapper>
                <div className="p-4">
                    <Survey model={new Model(formJSON)} onComplete={handleSurveyComplete} />
                </div>
                </FunctionWrapper>
        )
    }

    
}

export default OnlineCustomForm;