

import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/user.context";
import { useSearchParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './online-baptism-application.css';

// components
import FunctionWrapper from "../../../components/function-area-wrapper/function-wrapper.component";
import TitleSection from "../../../components/title-section/title-section";
import { APIAuthContext } from "../../../contexts/api-auth.context";
import { serverTimestamp } from "firebase/firestore";
import base64 from 'base-64';


const defaultBaptismFields = {
    childFullName: '',
    childDOB: '',
    fatherName: '',
    motherName: '',
    fatherReligion: '',
    motherReligion: '',
    email: '',
    phone: '',
    address: '',
    church: 'church1',
    maritalStatus: '',
    godparentName1: '',
    godparentAddress1: '',
    godparentDOB1: '',
    godparentDOC1: '',
    godparentChurch1: '',
    godparentName2: '',
    godparentAddress2: '',
    godparentDOB2: '',
    godparentDOC2: '',
    godparentChurch2: ''
}

const OnlineBaptismApplication = () => {
    const [queryParameters] = useSearchParams();
    const [userData, setUserData] = useState(null);
    const { currentUser } = useContext(UserContext)
    const { authCreds } = useContext(APIAuthContext);
    const [churchOptions, setChurchOptions] = useState([]);
    const [parishId, setParishId] = useState(null);
    const [parishName, setParishName] = useState('');

    // baptism form fields
    const [baptismFormFields, setBaptismFormFields] = useState(defaultBaptismFields);
    const [invalidPage, setInvalidPage] = useState(false);

    const [baptismProcedures, setBaptismProcedures] = useState(null);

    // form status
    const [baptismFormStatus, setBaptismFormStatus] = useState(null);

    useEffect(() => {
        document.title = "Online Baptism Application";
    })
    

    const handleBaptismChange = (event) => {
        const {name, value} = event.target;
        setBaptismFormFields({...baptismFormFields, [name]: value});
        console.log(baptismFormFields);
    }

    const handleBaptismFormSubmit = (event) => {
        event.preventDefault();
        var headers = new Headers();
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;
        const encodedCredentials = base64.encode(`${username}:${password}`);
        headers.set('Authorization', 'Basic ' + encodedCredentials)
        headers.set('content-type', 'application/json');

        fetch('https://api.parishnavigator.com/create-baptism', {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                "user_id": parishId,
                "baptismData": baptismFormFields
             })
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response['status'] === "true")
            {
                setBaptismFormStatus(true);
            }
            if(response['status'] === "false")
            {
                setBaptismFormStatus(false);
            }
            // window.location.reload();
        })
    }

    useEffect(() => {
        const parish = queryParameters.get("parish")
        setParishId(parish);
        var headers = new Headers();
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;
        const encodedCredentials = base64.encode(`${username}:${password}`);
        headers.set('Authorization', 'Basic ' + encodedCredentials)
        fetch(`https://api.parishnavigator.com/get-churches-parish/${parish}`, {headers: headers})
            .then((response) => response.json())
            .then((response) => {
                console.log("churches = ", response);
                if(response['status'] === "false")
                {
                    setInvalidPage(true);
                } else {
                    setParishName(response['parishName']);
                    const userData = response;
                    var temp = []
                    if(userData['church1'] !== '')
                    {
                        temp.push({'churchnum': 'church1', 'churchname': userData['church1']});
                    }
                    if(userData['church2'] !== '')
                    {
                        temp.push({'churchnum': 'church2', 'churchname': userData['church2']});
                    }
                    if(userData['church3'] !== '')
                    {
                        temp.push({'churchnum': 'church3', 'churchname': userData['church3']});
                    }
                    setChurchOptions(temp);
                    console.log("church options: ", churchOptions);
                }
            })

    }, [])
    
    if(queryParameters.get("parish") === null || invalidPage === true)
    {
        return (
            <div>INVALID PAGE</div>
        )
    }
    else {
        return (
                <FunctionWrapper>
                    <TitleSection title={"Online Baptism Application for " + parishName} subtitle="Apply for the sacrament of Baptism. Welcomed into the One, Holy, Catholic and Apostolic Church"/>

                    <div className="p-4" >
                        <div class="d-flex flex-column justify-content-center align-items-center">
                                    <form className="formGrid w-100 flex-fill " onSubmit={handleBaptismFormSubmit}>
                                        
                                        <div class="d-flex flex-column mb-3">
                                            <label>Child's Full Name*</label>
                                            <input required type="text" className="form-control" name="childFullName" onChange={handleBaptismChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Child's Date of Birth*</label>
                                            <input required type="date" className="form-control" name="childDOB" onChange={handleBaptismChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Father's Full Name*</label>
                                            <input required type="text" className="form-control" name="fatherName" onChange={handleBaptismChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Father's Religion*</label>
                                            <select required className="form-select" name="fatherReligion" onChange={handleBaptismChange} >
                                                <option value="Catholic">Catholic</option>
                                                <option value="Non-Catholic Christian">Non-Catholic Christian</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Mother's Full Name*</label>
                                            <input required type="text" className="form-control" name="motherName" onChange={handleBaptismChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Mother's Religion*</label>
                                            <select required className="form-select" name="motherReligion" onChange={handleBaptismChange} >
                                                <option value="Catholic">Catholic</option>
                                                <option value="Non-Catholic Christian">Non-Catholic Christian</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Contact Email*</label>
                                            <input required type="email" className="form-control" name="email" onChange={handleBaptismChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Telephone Number</label>
                                            <input type="phone" className="form-control" name="phone" onChange={handleBaptismChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Home Address*</label>
                                            <input required type="text" className="form-control" name="address" onChange={handleBaptismChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Church</label>
                                            <select required className="form-select" name="church" onChange={handleBaptismChange} >
                                                {churchOptions.map((church) => {
                                                return(
                                                    <option value={church.churchnum}>{church.churchname}</option>
                                                );
                                                })}
                                            </select>
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Parent's Marital Status</label>
                                            <input required type="text" className="form-control" name="maritalStatus" onChange={handleBaptismChange} />
                                        </div>

                                        <div></div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>1st Godparent's Full Name</label>
                                            <input required type="text" className="form-control" name="godparentName1" onChange={handleBaptismChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>1st Godparent's Address</label>
                                            <input required type="text" className="form-control" name="godparentAddress1" onChange={handleBaptismChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>1st Godparent's Date of Baptism (as close as possible)</label>
                                            <input required type="date" className="form-control" name="godparentDOB1" onChange={handleBaptismChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>1st Godparent's Date of Confirmation</label>
                                            <input required type="date" className="form-control" name="godparentDOC1" onChange={handleBaptismChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>1st Godparent's Church and Location of Baptism</label>
                                            <input required type="text" className="form-control" name="godparentChurch1" onChange={handleBaptismChange} />
                                        </div>

                                        <div></div>

                                        {/* second godparent */}
                                        <div class="d-flex flex-column mb-3">
                                            <label>2nd Godparent's Full Name</label>
                                            <input type="text" className="form-control" name="godparentName2" onChange={handleBaptismChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>2nd Godparent's Address</label>
                                            <input type="text" className="form-control" name="godparentAddress2" onChange={handleBaptismChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>2nd Godparent's Date of Baptism (as close as possible)</label>
                                            <input type="date" className="form-control" name="godparentDOB2" onChange={handleBaptismChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>2nd Godparent's Date of Confirmation</label>
                                            <input type="date" className="form-control" name="godparentDOC2" onChange={handleBaptismChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>2nd Godparent's Church and Location of Baptism</label>
                                            <input type="text" className="form-control" name="godparentChurch2" onChange={handleBaptismChange} />
                                        </div>

                                        <div></div>


                                        <div>
                                        {baptismFormStatus === true && (
                                            <p className="align-self-start text-success"><i>Your Baptism application has been Submitted Successfully</i></p>
                                        )}
                                        {baptismFormStatus === false && (
                                            <p className="align-self-start text-danger"><i>There was an error submitting your Baptism Application</i></p>
                                        )
                                        }
                                            <button type="submit" className="btn btn-primary mt-4">Submit Baptism Application</button>
                                        </div>
                                
                                    </form>
                                    
                                    
                                </div>

                    </div>

                    
                </FunctionWrapper>
        );
    }
}

export default OnlineBaptismApplication;