import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/user.context";
import 'bootstrap/dist/css/bootstrap.min.css';
import EmailSender from "../../../components/email-sender/email-sender.component";

// components
import CustomAccordion from "../../../components/accordion/custom-accordion.component";
import { APIAuthContext } from "../../../contexts/api-auth.context";
import TitleSectionMin from "../../../components/title-section-min/title-section";
import EditFuneralComponent from "../edit-funeral/edit-funeral.component";
import DesignableAccordion from "../../../components/accordion/designable-accordion.component";
import ListItemComponent from "../../../components/list-item/list-item.component";
import SearchBar from "../../../components/search-bar/search-bar.component";

import './view-funerals.css';

function dateToWordForm(dateString) {
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Determine the suffix for the day
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
        suffix = "st";
    } else if (day === 2 || day === 22) {
        suffix = "nd";
    } else if (day === 3 || day === 23) {
        suffix = "rd";
    }

    const wordDate = `${day}${suffix} ${month} ${year}`;
    return wordDate;
}

const ViewFuneralsComponent = ({ userData, setChildComponent }) => {
    const [funeralsList, setFuneralsList] = useState([]);
    const { currentUser } = useContext(UserContext);
    const { authCreds } = useContext(APIAuthContext);
    const [churchOptions, setChurchOptions] = useState([]);

    const [filteredFunerals, setFilteredFunerals] = useState([]);

    useState(() => {
        setFilteredFunerals(funeralsList);
    }, [funeralsList])

    useEffect(() => {
        const getFunerals = async() => {
            if(currentUser !== null)
            {
                console.log(currentUser.uid);

                let token = await currentUser.getIdToken();
                fetch(`https://api.parishnavigator.com/get-funerals`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: "include"
                })
                    .then((funerals_result) => funerals_result.json())
                    .then((funerals_result) => {
                        console.log("api response = ", funerals_result);
                        setFuneralsList(funerals_result['funerals']);
                    })
            }
        }
        getFunerals();
    }, [currentUser])

    useEffect(() => {
        if(userData !== null){
            var temp = []
            if(userData['church1'] !== '')
            {
                temp.push({'churchnum': 'church1', 'churchname': userData['church1']});
            }
            if(userData['church2'] !== '')
            {
                temp.push({'churchnum': 'church2', 'churchname': userData['church2']});
            }
            if(userData['church3'] !== '')
            {
                temp.push({'churchnum': 'church3', 'churchname': userData['church3']});
            }
            setChurchOptions(temp);
            console.log("church options: ", churchOptions);
        }
    }, [userData])

    if(userData !== null)
    {

        return (
            <div>
                <TitleSectionMin title="View Funerals" subtitle="View and manage Funerals within the Parish"/>

                <div className="p-4">
                    <SearchBar userMembers={funeralsList} setFilteredUserMembers={setFilteredFunerals} searchParameter="deceasedName" />
                    {filteredFunerals.map((funeral, index) => {
                        return (
                            <ListItemComponent setChildComponent={setChildComponent} newState={<EditFuneralComponent userData={userData} funeralID={funeral.funeralid} setChildComponent={setChildComponent} />}>
                                {/* <div className="d-flex justify-content-between align-items-center">
                                    <div><b>{funeral.deceasedName}</b></div>

                                    <div className="goButton py-2 px-3 d-flex align-items-center" onClick={() => {setChildComponent(<EditFuneralComponent userData={userData} funeralID={funeral.funeralid} />)}}>
                                        Go to Funeral
                                    </div>
                                </div> */}

                                <div className="w-100 d-flex justify-content-start">
                                    <div className="d-flex flex-column thirty-five-funeral" >
                                        <div style={{fontSize: "12px"}}>Name of Deceased</div>
                                        <div><b>{funeral.deceasedName}</b></div>
                                    </div>

                                    <div className="d-none d-lg-block">
                                        <div style={{fontSize: "12px"}}>Date/Time of Funeral</div>
                                        <div><b>{dateToWordForm(funeral.funeralDate)} at {funeral.funeralTime}</b></div>
                                    </div>

                                    <div className="ms-auto d-flex justify-content-center align-items-center">
                                        <div>Go to Funeral </div>
                                    </div>

                                </div>
                            </ListItemComponent>
                        )
                    })}

                    {/* <CustomAccordion heading="Something">
                        <div>
                            <p>Some stuff here</p>
                        </div>
                    </CustomAccordion> */}
                </div>
            </div>
        )
    }
}

export default ViewFuneralsComponent;