// Import the functions you need from the SDKs you need
import { initializeApp,  } from "firebase/app";
import {
    getAuth,
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    signInWithEmailAndPassword
} from 'firebase/auth';
import {
    getFirestore,
    doc,
    getDoc,
    setDoc
} from 'firebase/firestore';

import { getStorage } from "firebase/storage";
import { useContext, useEffect } from 'react';
import { UserContext } from '../../contexts/user.context';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// WORKING VERSION
// const firebaseConfig = {
//   apiKey: "AIzaSyDmzrvLzjmBi4YRh3PnrJybpk2VQHLwkA0",
//   authDomain: "ecclesia-connect.firebaseapp.com",
//   projectId: "ecclesia-connect",
//   storageBucket: "ecclesia-connect.appspot.com",
//   messagingSenderId: "777140266604",
//   appId: "1:777140266604:web:a0fb1efe8f59a3b9d79a49",
//   measurementId: "G-B2GMBBYV1Z"
// };

const firebaseConfig = {
    apiKey: "AIzaSyB9_Y_v1BPLpVUE-SNWSX6aFslQXNny0OQ",
    authDomain: "parish-navigator.firebaseapp.com",
    projectId: "parish-navigator",
    storageBucket: "parish-navigator.appspot.com",
    messagingSenderId: "922727544184",
    appId: "1:922727544184:web:a83b7ac82f9867cf46848d",
    measurementId: "G-YYQTP9ZJ54"
}


// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth()
export const db = getFirestore();

export const createUserDocumentFromAuth = async (userAuth, information = {}, parishName, church1, church2, church3) => {
    const userDocRef = doc(db, 'users', userAuth.uid);
    console.log(userAuth, information, parishName, church1, church2, church3);

    const userSnapshot = await getDoc(userDocRef);

    if(!userSnapshot.exists())
    {
        const { displayName, email } = userAuth;
        const createdAt = new Date();

        var details = {
            account_type: "parish",
            email: email,
            createdAt: createdAt,
            parishName: parishName,
            funeralProcedures: ["Visit Family", "Confirm Date", "Record in Register"],
            baptismProcedures: ["Check details", "Baptism Course", "Confirm Date", "Record in Register"]

        }

        // checking if the churches have been given
        if (typeof church1 !== 'undefined') {
            details.church1 = church1;
          }
          
          if (typeof church2 !== 'undefined') {
            details.church2 = church2;
          }
          
          if (typeof church3 !== 'undefined') {
            details.church3 = church3;
          }

          console.log("DETAILS ARE: ", details);

        try {
            await setDoc(userDocRef, details);
        } catch(error) {
            console.log('error creating the user', error.message);
        }
    }

    return userDocRef;
}

export const createSubUserDocumentFromAuth = async (personalName, account_type, userAuth, parish_id) => {
    const userDocRef = doc(db, 'users', userAuth.uid);

    const userSnapshot = await getDoc(userDocRef);

    if(!userSnapshot.exists())
    {
        const { displayName, email } = userAuth;
        const createdAt = new Date();

        var details = {
            personalName: personalName,
            account_type: account_type,
            email: email,
            createdAt: createdAt,
            parish_id: parish_id,
        }
        
        try {
            await setDoc(userDocRef, details);
        } catch(error) {
            console.log('error creating the user', error.message);
        }
    }

    return userDocRef;
}

export const createAuthUserWithEmailAndPassword = async (email, password) => {
    if(!email || !password) return;

    return await createUserWithEmailAndPassword(auth, email, password);
}

export const signOutUser = async () => await signOut(auth);

export const onAuthStateChangedListener = (callback) => onAuthStateChanged(auth, callback)

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
    if (!email || !password) return;
  
    return await signInWithEmailAndPassword(auth, email, password);
  };

// WORKING FETCH USER DATA
// export const fetchUserData = async (user_id, setUserData) => {
//     const userRef = doc(db, 'users', user_id);
//     await getDoc(userRef).then((userDoc) => {
//         console.log("userdoc = ", userDoc.data());
//         setUserData(userDoc.data());
//     })
// }

// TESTING
export const fetchUserData = async (user_id, setUserData, account_type=null, personalName=null) => {
    const userRef = doc(db, 'users', user_id);
    await getDoc(userRef).then((userDoc) => {
        if(userDoc.data().account_type !== "parish")
        {
            fetchUserData(userDoc.data().parish_id, setUserData, userDoc.data().account_type, userDoc.data().personalName)
        }
        var temp = userDoc.data();
        temp.parishid = userDoc.id;
        if(account_type !== null)
        {
            temp.account_type = account_type;
        }
        if(personalName !== null)
        {
            temp.personalName = personalName;
        }
        setUserData(temp);
    })
}