import React from 'react';
import './title-section.css';

const TitleSectionCustom = ({title, subtitle}) => {
    return (
        <div className="mt-3 w-100 border-bottom d-flex flex-column px-4 justify-content-center align-items-start">
            <div className='w-100'>{title}</div>
            <div>{subtitle}</div>
        </div>
    );
}

export default TitleSectionCustom;