import { useContext, useEffect, useState,  } from "react";
import { UserContext } from "../../../contexts/user.context";
import 'bootstrap/dist/css/bootstrap.min.css';
import { PuffLoader } from "react-spinners";
import EmailSender from "../../../components/email-sender/email-sender.component";


// components
import CustomAccordion from "../../../components/accordion/custom-accordion.component";
import { APIAuthContext } from "../../../contexts/api-auth.context";
import TitleSectionMin from "../../../components/title-section-min/title-section";
import DesignableAccordion from "../../../components/accordion/designable-accordion.component";
import Modal from 'react-modal';

const ViewContactFormsComponent = ({ userData, setChildComponent }) => {

      
    const [formsList, setFormsList] = useState([]);
    const { currentUser } = useContext(UserContext);
    const { authCreds } = useContext(APIAuthContext);
    const [refreshKey, setRefreshKey] = useState(0);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalInf, setModalInf] = useState([]);

    useEffect(() => {
        const getContacts = async() => {
            if(currentUser !== null)
            {
                console.log(currentUser.uid);

                let token = await currentUser.getIdToken();
                fetch(`https://api.parishnavigator.com/get-contact-submissions`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: "include"
                })
                    .then((forms_result) => forms_result.json())
                    .then((forms_result) => {
                        console.log("api response = ", forms_result);
                        setFormsList(forms_result['forms']);
                    })

            }
        }

        getContacts();

    }, [currentUser, refreshKey])



    const deleteForm = async(formid) => {
        
        let token = await currentUser.getIdToken();
        fetch('https://api.parishnavigator.com/delete-doc', {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "user_id": userData.parishid, 
                "collection_string": "contact_submissions",
                "doc_id": formid
            }),
            credentials: "include"
        }).then(response => response.json())
        .then((response) => {
            if(response['status'] === "true")
            {
                setRefreshKey(refreshKey+1);
            }
            else {
                alert("There was an error. Please try again later");
            }
        })
    }

    const setModal = (form) => {
        setModalOpen(true);
        setModalInf(form);
    }
    // Modal stuff
  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: "20px"
    },
  };

    if(userData !== null)
    {

        return (
            <div>

                <Modal isOpen={modalOpen} style={modalStyle} onRequestClose={() => setModalOpen(false)} contentLabel='Send Email' >
                    <h2>Send Email to {modalInf.fullName}</h2>
                    <hr />
                    <EmailSender recipients={modalInf.email} parishName={userData.parishName} senderEmail={userData.email} userId={userData.parishid} apiRoute='send-email'/>
                </Modal>


                <TitleSectionMin title="View Parish Contact us Forms" subtitle="View and manage Queries to the Parish"/>

                <div className="p-4">
                    {formsList.map((form, index) => {
                        var headingComponent = (
                            <div className="d-flex w-100 justify-content-between align-items-center">
                                <div><b>{form.subject}</b></div>
                                <div className="d-flex align-items-center">
                                    <div className="deleteButton me-2" onClick={() => {deleteForm(form.formid)}}><i className="bx bx-trash"></i></div>
                                    {form.fullName}</div>
                                </div>
                        );
                        return (
                            <DesignableAccordion headingComponent={headingComponent}>
                                {atob(form.message).split('\n').map((line, index) => (
                                <div className="" key={index}>
                                    {line}
                                    <br />
                                </div>
                                ))}

                                {form.generatedResponse && (<p>Generated Response: {atob(form.generatedResponse).split('\n').map((line, index) => (
                                <div className="" key={index}>
                                    {line}
                                    <br />
                                </div>
                                ))}</p>)}

                                <p>Email: {form.email}</p>
                                <p>Phone: {form.phone}</p>
                                
                                <div className="d-flex">

                                    <div className="ms-2">                                        
                                            {/* Button */}
                                            <button type="button" className="btn btn-primary" onClick={() => setModal(form)}>
                                            Send Email
                                            </button>
                                        
                                    </div>
                                </div>

                            </DesignableAccordion>
                        )
                    })}
                </div>
            </div>
        )
    } else {
        return (
            <div className="d-flex align-items-center justify-content-center w-100" style={{height: '100vh'}}>
                <PuffLoader
                color="#3c2bf8"
                size={80}
                />
            </div>
        )
    }
}

export default ViewContactFormsComponent;