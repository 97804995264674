import './account-dashboard.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useContext, useEffect } from 'react';
import { UserContext } from '../../contexts/user.context';
import { useNavigate } from "react-router-dom";

import { useState } from "react";
import { getCheckoutUrl } from '../../utils/firebase/stripePayment.ts';
import { getPremiumStatus } from '../../utils/firebase/getPremiumStats.ts';
import { app, fetchUserData } from '../../utils/firebase/firebase.util';
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { storage } from '../../utils/firebase/firebase.util';

// Components
import LoadingState from '../../components/loading-state/loading-state.component';
import { APIAuthContext } from '../../contexts/api-auth.context';
import TitleSectionMin from '../../components/title-section-min/title-section';
import { upload } from '@testing-library/user-event/dist/upload';
import Modal from 'react-modal';
import QuestionAndAnswer from './question-answer.component.jsx/question-answer.component.tsx';


const AccountDashboardComponent = ({userData, setUserData, setChildComponent}) => {
    let navigate = useNavigate();
    // const [userData, setUserData] = useState(null);
    const [userMembers, setUserMembers] = useState(null);
    const [funeralProcedures, setFuneralProcedures] = useState('');
    const [funeralFormStatus, setFuneralFormStatus] = useState(null);
    const [autoReplies, setAutoReplies] = useState();

    const [baptismProcedures, setBaptismProcedures] = useState('');
    const [baptismFormStatus, setBaptismFormStatus] = useState(null);

    const { currentUser } = useContext(UserContext);
    const { authCreds } = useContext(APIAuthContext);

    const [isPremium, setIsPremium] = useState(null);

    const [successModal, setSuccessModal] = useState(true);

    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        document.title = "Dashboard";
    })

    useEffect(() => {
        const checkPremium = async () => {
            const newPremiumStatus = currentUser ? await getPremiumStatus(app) : false;
            setIsPremium(newPremiumStatus);
        }
        checkPremium();
    }, [app, currentUser])

    const handleProceduresChange = (event) => {
        setFuneralProcedures(event.target.value);
    }

    const handleBaptismProceduresChange = (event) => {
        setBaptismProcedures(event.target.value);
    }

    const updateFuneralProcedures = async(event) => {
        event.preventDefault();
        console.log(userData.parishid);
        let token = await currentUser.getIdToken();
        fetch("https://api.parishnavigator.com/update-funeral-procedures", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "funeralProcedures": funeralProcedures
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response['status'] === "true")
            {
                setFuneralFormStatus(true);
                var tempUser = userData;
                tempUser['funeralProcedures'] = response['funeralProcedures'];
                setUserData(tempUser);
            } else {
                setFuneralFormStatus(false);
            }
        })

    }

    const updateBaptismProcedures = async(event) => {
        event.preventDefault();
        let token = await currentUser.getIdToken();
        fetch("https://api.parishnavigator.com/update-baptism-procedures", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "baptismProcedures": baptismProcedures
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response['status'] === "true")
            {
                setBaptismFormStatus(true);
                // fetchUserData(currentUser.uid, setUserData);
                var tempUser = userData;
                tempUser['baptismProcedures'] = response['baptismProcedures'];
                setUserData(tempUser);
            } else {
                setBaptismFormStatus(false);
            }
        })

    }

    const upgradePremium = async() => {
        console.log("upgrading");
        // const priceId = "price_1NvMdBHVhAXQAM1QW6MC8NCj";
        const priceId = "price_1Nvi6mF8RN4d19lCESmVEwUU";
        const checkoutUrl = await getCheckoutUrl(app, priceId).then((pricurl) => {
            console.log("checkoutUrl: " + pricurl);
            window.location.href = pricurl; 
        });
    }
    
    useEffect(() => {
        setFuneralProcedures(userData.funeralProcedures.join(', '));
        setBaptismProcedures(userData.baptismProcedures.join(', '));
        setAutoReplies(userData.autoReplies);

    }, [userData])

    const statusPanel = isPremium ? <div>Premium</div> : <div>Not Premium</div>;

    const uploadFile = (file) => {
        if(!file) return '';
        return new Promise((resolve, reject) => {
            if (!file) {
                reject("No file provided");
                return;
            }
    
            const storageRef = ref(storage, `/files/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
    
            uploadTask.on("state_changed", (snapshot) => {
            }, (err) => {
                console.log(err);
                reject(err);
            }, () => {
                console.log("success");
                getDownloadURL(uploadTask.snapshot.ref)
                    .then((url) => {
                        console.log(url);
                        resolve(url);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        });
    }

    const handleNewsletter = async (event) => {
        try {
            const file = event.target.files[0];
            const file_url = await uploadFile(file);

            let token = await currentUser.getIdToken();
            fetch(`https://api.parishnavigator.com/update-newsletter`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "newsletter_url": file_url,
                }),
                credentials: "include"
            }).then((response) => response.json())
            .then((response) => {
                console.log(response);
                setModalOpen(true);
            })
        } catch (error) {
            alert("There was an error. Please try again later");
        }
    }

    const handleAutoReplies = async (event) => {
        const isChecked = event.target.checked;
        // setAutoReplies(isChecked);
        try{
            let token = await currentUser.getIdToken();
            fetch(`https://api.parishnavigator.com/update-auto-reply`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "mode": isChecked,
                }),
                credentials: "include"
            }).then((response) => response.json())
            .then((response) => {
                console.log(response);
                setAutoReplies(isChecked);
                var tempUser = userData;
                tempUser['autoReplies'] = isChecked;
                setUserData(tempUser);
            })
        } catch (error) {
            alert("There was an error. Please try again later");
        }
    }

    const handleNewLogo = async (event) => {
        try {
            const file = event.target.files[0];
            const file_url = await uploadFile(file);

            let token = await currentUser.getIdToken();
            fetch(`https://api.parishnavigator.com/update-parish-logo`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "parish_logo": file_url,
                })
            }).then((response) => response.json())
            .then((response) => {
                if(response['status'] === "true")
                {
                    window.location.reload();
                }
                else{
                    alert("Sorry, there was an error updating your Parish Logo. Please try again later")
                }
            })

        } catch (error) {
            alert("There was an error uploading your Logo. Please contact support and try again later.")
        }
    }

     // Modal stuff
  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: "20px",
    },
  };
    // if(!isPremium && userData !== null)
    // {
    //     navigate('/purchase-software');
    // }
    if(userData === null)
    {
        return(
            <LoadingState />
        )
    }
    else{
        return (
            <div>
                {/* Newsletter Submitted Modal */}
                <Modal isOpen={modalOpen} style={modalStyle} onRequestClose={() => {setModalOpen(false)}} contentLabel='Delete'>
                    <div>
                        <h4 className='text-success'>Newsletter Successfully Uploaded</h4>
                        <p>Thank you for uploading your Newsletter. We will process this behind the scenes.</p>
                    </div>  
                </Modal>

                <TitleSectionMin title={"Hello, " + userData.parishName}/>
                <div className='p-4'>
                    <div>
                        <p className='mb-0'>Parish Name:  <b>{userData.parishName}</b></p>
                        <p className='mb-0'>Parish Email:  <b>{userData.email}</b></p>
                        <p className="mb-0">Online Parish Registration Form: <a href={"https://app.parishnavigator.com/online-parish-registration?parish="+userData.parishid}>{"https://app.parishnavigator.com/online-parish-registration?parish="+userData.parishid}</a></p>
                        <p className="mb-0">Online Baptism Application Form: <a href={"https://app.parishnavigator.com/online-baptism-application?parish="+userData.parishid}>{"https://app.parishnavigator.com/online-baptism-application?parish="+userData.parishid}</a></p>
                        <p className="mb-0">Online Contact us Form: <a href={"https://app.parishnavigator.com/contact-us?parish="+userData.parishid}>{"https://app.parishnavigator.com/contact-us?parish="+userData.parishid}</a></p>
                
                        

                        <form action="" onSubmit={updateFuneralProcedures} className='mt-4'>
                            <label htmlFor="">Funeral Procedures <i>(Comma separated list)</i></label>
                            <input required type="text" className='form-control' value={funeralProcedures} onChange={handleProceduresChange}/>
                            {funeralFormStatus === true && (
                                <p className="align-self-start text-success"><i>Successfully updated Funeral Procedures</i></p>
                            )}
                            {funeralFormStatus === false && (
                                <p className="align-self-start text-danger"><i>There was an error updating Funeral Procedures</i></p>
                            )
                            }
                            <button type="submit" className='mt-2 btn btn-primary'>Update Funeral Procedures</button>
                        </form>
                    </div>

                    <div>
                        <form className='mt-4' onSubmit={updateBaptismProcedures}>
                            <label htmlFor="">Baptism Procedures <i>(Comma separated list)</i></label>
                            <input required type="text" className='form-control' value={baptismProcedures} onChange={handleBaptismProceduresChange}/>
                            {baptismFormStatus === true && (
                                <p className="align-self-start text-success"><i>Successfully updated Baptism Procedures</i></p>
                            )}
                            {baptismFormStatus === false && (
                                <p className="align-self-start text-danger"><i>There was an error updating Baptism Procedures</i></p>
                            )
                            }
                            <button type="submit" className='mt-2 btn btn-primary'>Update Baptism Procedures</button>
                        </form>
                    </div>

                    <div>
                        <div className='mt-5'>
                            <label htmlFor="logo-upload" className="custom-file-upload">
                                <div className='bg-primary rounded-3 p-3 text-white'>
                                    <i className='bx bx-color'/> Upload Custom Logo
                                </div>
                            </label>
                            <input id="logo-upload" type="file" accept="image/jpeg, image/jpg, image/png" style={{ display: 'none' }} onChange={handleNewLogo}/>
                        </div>
                    </div>

                    <div>
                        <div className='mt-4'>
                            <label htmlFor="newsletter-upload" className="custom-file-upload">
                                <div className='bg-success rounded-3 p-3 text-white'>
                                    <i className='bx bx-cloud-upload'/> Upload Newsletter
                                </div>
                            </label>
                            <input id="newsletter-upload" type="file" accept='.pdf' style={{ display: 'none' }} onChange={handleNewsletter}/>
                        </div>
                        <div><i>This will help the AI Auto Responder make better replies to contact us forms</i></div>
                    </div>

                    <div>
                        <button className='btn btn-warning' onClick={() => setChildComponent(<QuestionAndAnswer userData={userData}/>)}>Frequently Asked Questions</button>
                    </div>

                    <div className='mt-3 d-flex align-items-center'>
                        <b>Auto Replies: </b>
                        <div className='ms-2 form-check form-switch'>
                            <input className="form-check-input" type='checkbox' role='switch'style={{height: "25px", width: "60px"}} checked={(autoReplies ? 'checked': '')} onChange={handleAutoReplies} />
                        </div>
                    </div>
                    <i>Our AI bot will automatically reply to contact us forms by analysing data from the FAQ as well as the latest uploaded Newsletter</i>
                </div>
            </div>
        );
    }
}


export default AccountDashboardComponent;