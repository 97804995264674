import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/user.context";
// components
import LoadingState from "../../../components/loading-state/loading-state.component";
import MemberAccordion from "../../../components/accordion/accordion.component";

import SearchBar from "../../../components/search-bar/search-bar.component";
import { APIAuthContext } from "../../../contexts/api-auth.context";
import TitleSectionMin from "../../../components/title-section-min/title-section";

const ViewMembersComponent = ({userData, setChildComponent}) => {
    const [userMembers, setUserMembers] = useState([]);
    const [filteredUserMembers, setFilteredUserMembers] = useState([]);
    const { currentUser } = useContext(UserContext);
    const { authCreds } = useContext(APIAuthContext);

    useEffect(() => {
        document.title = "View Members";
    }, [])

    useEffect(() => {
        setFilteredUserMembers(userMembers);
    }, [userMembers])

    useEffect(() => {

        const getMembers = async() => {
            if(currentUser !== null){
                console.log(currentUser.uid);

                try{
                    let token = await currentUser.getIdToken();
                    fetch(`https://api.parishnavigator.com/get-members`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                        credentials: "include"
                    })
                        .then((members_result) => members_result.json())
                        .then((members_result) => {
                            console.log("api response = ", members_result);
                            setUserMembers(members_result);
                        })
                } catch (error) {
                    alert("There was an error. Please try again later");
                }
            }
        }

        getMembers();
    }, [currentUser])


    
    if(currentUser !== null && filteredUserMembers !== null){
        return (
            <div>
                <TitleSectionMin title="View Parish Members" subtitle="Explore your Parish Community"/>

                <div className="">
                    <SearchBar userMembers={userMembers} setFilteredUserMembers={setFilteredUserMembers} searchParameter="Full Name"/>

                    {filteredUserMembers.map((member, index) => {
                        return (
                            <MemberAccordion memberData={member} userData={userData} setChildComponent={setChildComponent} key={'member'+index}/>
                        )
                    })}
                </div>
            </div>
        )
    } else {
        return (
            <LoadingState />
        )
    }
}

export default ViewMembersComponent;