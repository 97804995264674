import { buttonBaseClasses } from "@mui/material"
import { useContext, useState } from "react";
import Modal from 'react-modal';
import { APIAuthContext } from "../../contexts/api-auth.context";
import { UserContext } from "../../contexts/user.context";

const DeleteButton = ({userData, formName, formID, type, onDelete}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const { authCreds } = useContext(APIAuthContext);
    const { currentUser } = useContext(UserContext);

    const deleteForm = async () => {
        let token = await currentUser.getIdToken();

        fetch('https://api.parishnavigator.com/delete-doc', {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "user_id": userData.parishid, 
                "collection_string": formName,
                "doc_id": formID
            }),
            credentials: "include"
        }).then(response => response.json())
        .then((response) => {
            if(response['status'] === "true")
            {
                onDelete();
            }
            else {
                alert("There was an error. Please try again later");
            }
        })
    }

    // Modal stuff
  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: "20px"
    },
  };

    return (
        <div>
        <Modal isOpen={modalOpen} style={modalStyle} onRequestClose={() => {setModalOpen(false)}} contentLabel='Delete'>
            <div>
                <h4>Are you sure you want to delete?</h4>
                <div className="d-flex flex-wrap mt-4">
                    <button className="btn btn-danger" onClick={deleteForm}>Yes, Delete</button>
                    <button className="ms-2 btn btn-secondary" onClick={() => setModalOpen(false)}>Cancel</button>
                </div>
            </div>  
        </Modal>

        {(type === "button") && (
            <button className="btn btn-danger" onClick={() => {setModalOpen(true)}}>Delete</button>
        )}
        </div>
    );
}

export default DeleteButton;