import { useEffect } from "react";
import FunctionWrapper from "../../components/function-area-wrapper/function-wrapper.component"


const TermsAndConditions = () => {
    useEffect(() => {
        document.title = "Terms and Conditions";
    })
    return (
        <div>
            <FunctionWrapper>
            <h1>Terms and Conditions of ParishNavigator</h1>

<h2>1. Introduction</h2>
<p>Welcome to ParishNavigator! These Terms and Conditions govern your use of our software platform designed to assist with parish management and administration. By using ParishNavigator, you agree to be bound by these terms.</p>

<h2>2. User Responsibilities</h2>
<p>Users are responsible for the legal, ethical, and responsible handling of the data of parishioners. This includes refraining from illegal activities such as selling parishioner data.</p>

<h2>3. User Accounts</h2>
<p>To use ParishNavigator, users are required to create an account and pay for the services. During the account creation process, users will provide the name of the parish, email of the parish, and the churches within the parish.</p>

<h2>4. Data Collection and Privacy</h2>
<p>ParishNavigator collects and stores the data mentioned in section 3 for use by our users. This data is securely stored in our Firestore database and is protected.</p>

<h2>5. Intellectual Property</h2>
<p>ParishNavigator, including all related intellectual property, is and will remain the property of ParishNavigator. Users may not use our trademarks, logos, or branding without our express permission.</p>

<h2>6. Prohibited Activities</h2>
<p>Users are prohibited from attempting to gain unauthorized access to other users' data, exploiting the software, or illegally mishandling the data of parishioners.</p>

<h2>7. Liability and Disclaimers</h2>
<p>ParishNavigator is provided on an "as-is" and "as-available" basis. We do not guarantee the accuracy, completeness, or suitability of the information provided. Users use the software at their own risk.</p>

<h2>8. Account Termination</h2>
<p>Users may terminate their account at any time by following the provided instructions on the platform.</p>

<h2>9. Governing Law</h2>
<p>These terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction].</p>

<h2>10. Updates and Changes</h2>
<p>Users will be notified of updates or changes to the software or these terms and conditions via email.</p>

<h2>11. Contact Information</h2>
<p>For questions or concerns, users can contact us at alfieanilecclesia@gmail.com.</p>
            </FunctionWrapper>
        </div>
    )
}

export default TermsAndConditions;