import { useContext, useEffect, useState,  } from "react";
import { UserContext } from "../../../contexts/user.context";
import 'bootstrap/dist/css/bootstrap.min.css';
import { PuffLoader } from "react-spinners";
import EmailSender from "../../../components/email-sender/email-sender.component";
import { Link } from 'react-router-dom';

import '../../css/function-components.css';
import { APIAuthContext } from "../../../contexts/api-auth.context";
import TitleSectionMin from "../../../components/title-section-min/title-section";
import EditBaptismComponent from "../edit-baptism/edit-baptism.component";
import DesignableAccordion from "../../../components/accordion/designable-accordion.component";
import ListItemComponent from "../../../components/list-item/list-item.component";
import { useAuthState } from "react-firebase-hooks/auth";
import SearchBar from "../../../components/search-bar/search-bar.component";

const ViewBaptismsComponent = ({ userData, setChildComponent }) => {
    const [baptismsList, setBaptismsList] = useState([]);
    const { currentUser } = useContext(UserContext)
    const { authCreds } = useContext(APIAuthContext);
    const [churchOptions, setChurchOptions] = useState([]);

    const [filteredBaptismList, setFilteredBaptismList] = useState([]);

    useEffect(() => {
        setFilteredBaptismList(baptismsList);
    }, [baptismsList])

    useEffect(() => {
        const fetchBaptisms = async() => {
            if(currentUser !== null)
            {
                let token = await currentUser.getIdToken();
                
                fetch(`https://api.parishnavigator.com/get-baptisms`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: "include"
                })
                .then((baptisms_result) => baptisms_result.json())
                .then((baptisms_result) => {
                    console.log("api response = ", baptisms_result);
                    setBaptismsList(baptisms_result['baptisms']);
                })

                

            }
        }

        fetchBaptisms();
    }, [currentUser])

    useEffect(() => {
        if(userData !== null){
            var temp = []
            if(userData['church1'] !== '')
            {
                temp.push({'churchnum': 'church1', 'churchname': userData['church1']});
            }
            if(userData['church2'] !== '')
            {
                temp.push({'churchnum': 'church2', 'churchname': userData['church2']});
            }
            if(userData['church3'] !== '')
            {
                temp.push({'churchnum': 'church3', 'churchname': userData['church3']});
            }
            setChurchOptions(temp);
            console.log("church options: ", churchOptions);
        }
    }, [userData])

    const getBaptismCertificate = async(baptismid, baptismname) => {

        // fetch(`https://api.parishnavigator.com/get-baptism-certificate/${currentUser.uid}/${event.target.value}`);
        try{
            const response = await fetch(`https://api.parishnavigator.com/get-baptism-certificate/${userData.parishid}/${baptismid}`)
            // const data = await response.json();

            // if(data.status === "false") {
            //     console.log("ERROR");
            //     return;
            // }
            const blob = await response.blob();
            if (blob.type === "application/json"){
                alert("Could not download Baptism Certificate. Please check you have provided all required fields (Minister's Name + Date of Baptism)");
                return;
            }
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `Baptism Certificate - ${baptismname}.docx`;
            a.click();
            URL.revokeObjectURL(url);
        } catch(error) {
            console.log(error);
            alert("Could not download Baptism Certificate. Please check you have provided all required fields (Minister's Name + Date of Baptism)")
        }
        
    }

    if(userData !== null)
    {

        return (
            <div>
                <TitleSectionMin title="View Baptism Applications" subtitle="View and manage Baptism Applications within the Parish"/>

                <div className="p-4">
                    <SearchBar userMembers={baptismsList} setFilteredUserMembers={setFilteredBaptismList} searchParameter="childFullName" />
                    {filteredBaptismList.map((baptism, index) => {
                        return (
                            <ListItemComponent setChildComponent={setChildComponent} newState={<EditBaptismComponent userData={userData} baptismID={baptism.baptismid} setChildComponent={setChildComponent}/>}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <div style={{ fontWeight: "100", fontSize: "12px"}}>Child's Full Name</div>
                                        <b>{baptism.childFullName}</b>
                                    </div>

                                    <div className="goButton py-2 px-3 d-flex align-items-center" onClick={() => {setChildComponent(<EditBaptismComponent userData={userData} baptismID={baptism.baptismid} setChildComponent={setChildComponent}/>)}}>
                                        Go to Baptism <i className="bx bx-chevron-right"></i>
                                    </div>
                                </div>
                            </ListItemComponent>
                        )
                    })}
                </div>
            </div>
        )
    } else {
        return (
            <div className="d-flex align-items-center justify-content-center w-100" style={{height: '100vh'}}>
                <PuffLoader
                color="#3c2bf8"
                size={80}
                />
            </div>
        )
    }
}

export default ViewBaptismsComponent;