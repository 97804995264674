import { createContext, useEffect } from 'react';
import { useState } from 'react';

import { onAuthStateChangedListener } from '../utils/firebase/firebase.util.jsx';

export const UserContext = createContext({
    currentUser: null
});

export const UserProvider = ({children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const value = {currentUser, setCurrentUser};

    useEffect(() => {
        const unsubscribe = onAuthStateChangedListener((user) => {
            setCurrentUser(user);
        })
    }, []);

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}