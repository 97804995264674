import { useEffect } from "react";
import FunctionWrapper from "../../components/function-area-wrapper/function-wrapper.component"


const PrivacyPolicy = () => {

    useEffect(() => {
        document.title = "Privacy Policy";
    })
    return (<div>
        <FunctionWrapper >
            <p>
                Privacy Policy for ParishNavigator
                <br />
1. Introduction
<br />
ParishNavigator is a platform designed to assist parishes with management and administration. This policy outlines how we collect, use, and protect the information you provide to us.
<br />
2. Data Collection
<br />
For Adults:
<br />
First Name<br />
Last Name<br />
Email<br />
Phone Number<br />
Date of Birth<br />
Home Address<br />
Occupation<br />
Church Attended<br />
Roles within the Church<br />
For Children:<br />
<br />
First Name<br />
Last Name<br />
Email (optional)<br />
Parent's Email (required)<br />
Date of Birth<br />
Home Address<br />
School/Occupation<br />
Church Attended<br />
Roles within the Church<br />
Data is collected through online forms and may also be provided via paper forms to be added by authorized parish personnel.<br />
<br />
3. Purpose of Data Collection<br />
<br />
The primary purpose of collecting this data is to assist parishes with management and administration.<br />
<br />
4. Legal Basis for Processing
<br />
We rely on legitimate interests as the legal basis for processing the data, as it is necessary for providing the services offered by ParishNavigator.
<br />
5. Data Usage and Sharing
<br />
The collected data is used exclusively by the respective parish for management and administration purposes. It is not shared with any third parties.
<br />
6. Data Security
<br />
All data is securely stored, and authentication is required before access is granted.
<br />
7. Data Retention
<br />
Data will be retained as long as the respective parish keeps it within the ParishNavigator database.
<br />
8. User Rights
<br />
Users can request updates or deletions of their data directly from the parish that stores their information.
<br />
9. Cookies and Tracking
<br />
We do not use cookies or tracking technologies.
<br />
10. Third-Party Services
<br />
We utilize Firebase Firestore to securely store the data.
<br />
11. Children's Data
<br />
Children's data is managed by the respective parish for administrative purposes. When a child reaches 18 years of age, their data will continue to be managed by the parish.
<br />
12. Contact Information
<br />
For any questions or concerns about your data, please contact us at info@ParishNavigator.com.
            </p>
        </FunctionWrapper>
    </div>);
}

export default PrivacyPolicy;