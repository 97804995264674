import React, { useContext, useEffect, useState } from "react";
import { APIAuthContext } from "../../../contexts/api-auth.context";
import TitleSectionMin from "../../../components/title-section-min/title-section";
import ListItemComponent from "../../../components/list-item/list-item.component";
import EditFuneralComponent from "../../funerals/edit-funeral/edit-funeral.component";
import '../../../components/accordion/accordion.css';
import ViewFormResponses from "../view-form-responses/view-form-responses.component.tsx";
import { UserContext } from "../../../contexts/user.context.jsx";

const ViewForms = ({ userData, setChildComponent }) => {

    const { authCreds } = useContext(APIAuthContext);
    const { currentUser } = useContext(UserContext);
    const [customFormsList, setCustomFormsList] = useState<any>([]);

    useEffect(() => {

        const getForms = async() => {        
            let token = await currentUser.getIdToken();
            fetch(`https://api.parishnavigator.com/get-custom-forms`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                credentials: "include"
            })
                .then((custom_forms) => custom_forms.json())
                .then((custom_forms) => {
                    console.log(custom_forms);
                    if(custom_forms['status'] === "true")
                    {
                        setCustomFormsList([...custom_forms['forms']]);
                    }
                })
        }
        getForms();

    }, [userData])

    return (
        <div>
            <TitleSectionMin title="Your Custom Forms" subtitle="View the forms that you have created" />

            <div className="p-4">
                {customFormsList.map((form, index) => {
                    return (
                        // <div>{form.formJSON.title}</div>
                        <ListItemComponent setChildComponent={setChildComponent} newState={<ViewFormResponses userData={userData} setChildComponent={setChildComponent} formName={form.formJSON.title} formID={form.id}/>}>
                            <div className="w-100 d-flex justify-content-between align-items-center">
                                <div className="d-flex flex-column">
                                    <div style={{fontSize: "12px"}}>Form Name</div>
                                    <div><b>{form.formJSON.title}</b></div>
                                </div>

                                {/* <div className="h-100 d-flex justify-content-center align-items-center">
                                    <button type="button" className="accordionIcon d-flex justify-content-center align-items-center me-4">
                                        <i className="bx bx-edit-alt"></i>
                                    </button>

                                    <button type="button" className="accordionIcon d-flex justify-content-ceneter align-items-center">
                                        <i className=" bx bx-list-ul" />
                                    </button>
                                </div> */}
                            </div>
                        </ListItemComponent>
                    )
                })}
            </div>
        </div>
    )

}

export default ViewForms;