import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/user.context";
import CustomAccordion from "../../../components/accordion/custom-accordion.component";
import MemberAccordion from "../../../components/accordion/accordion.component";

import DownloadIcon from '../../../assets/download-solid (1).svg';
import { APIAuthContext } from "../../../contexts/api-auth.context";
import TitleSectionMin from "../../../components/title-section-min/title-section";
import EditGroupComponent from "../edit-group/edit-group.component";
import DesignableAccordion from "../../../components/accordion/designable-accordion.component";
import Modal from 'react-modal';

const ViewGroupsComponent = ({ userData, setChildComponent }) => {
    const [groupsList, setGroupsList] = useState([]);
    const { currentUser } = useContext(UserContext);
    const [churchOptions, setChurchOptions] = useState([]);
    const { authCreds } = useContext(APIAuthContext);

    const [modalOpen, setModalOpen] = useState(false);
    const [deleteGroup, setDeleteGroup] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);

    const extra_icon = <img src={DownloadIcon} alt="" style={{height: "20px"}} className="me-2 mb-1"/>;

    useEffect(() => {
        document.title = "View Groups";
    })

    useEffect(() => {
        const getGroupMembers = async() => {
            if(currentUser !== null)
            {
                let token = await currentUser.getIdToken();
                fetch(`https://api.parishnavigator.com/get-groups-with-members`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: "include"
                })
                .then((groups) => groups.json())
                .then((groups) => {
                    if(groups['status'] === "false")
                    {
                        setGroupsList([]);
                    }
                    else {
                        setGroupsList(groups['groups']);
                    }
                })
            }
        }

        getGroupMembers();
    }, [currentUser, refreshKey])

    useEffect(() => {
        if(userData !== null){
            var temp = []
            if(userData['church1'] !== '')
            {
                temp.push({'churchnum': 'church1', 'churchname': userData['church1']});
            }
            if(userData['church2'] !== '')
            {
                temp.push({'churchnum': 'church2', 'churchname': userData['church2']});
            }
            if(userData['church3'] !== '')
            {
                temp.push({'churchnum': 'church3', 'churchname': userData['church3']});
            }
            setChurchOptions(temp);
            console.log("church options: ", churchOptions);
        }
    }, [userData])
    
    const DownloadGroups = async(group_data) => {
        try{
            let token = await currentUser.getIdToken();
            const response = await fetch(`https://api.parishnavigator.com/get-excel-group-members/${group_data.groupid}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                credentials: "include"
            });

            const blob = await response.blob();
            if (blob.type === "application/json")
            {
                alert("Could not Download the requested File. Please try again later");
                return;
            }

            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${group_data.groupname}.xlsx`;
            a.click();
            URL.revokeObjectURL(url);
        } catch(e)
        {
            alert("Could not Download the requested File. Please try again later");
            return;
        }
    }

    const handleGroupDelete = (group_data) => {
        setDeleteGroup(group_data);

        setModalOpen(true);
    }

    const DeleteGroup = async() => {
        setModalOpen(false);

        let token = await currentUser.getIdToken();
        fetch('https://api.parishnavigator.com/delete-group', {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "group_id": deleteGroup.groupid
            }),
            credentials: "include"
        }).then(response => response.json())
        .then((response) => {
            if(response['status'] === "true")
            {
                setRefreshKey(refreshKey+1);
            }
            else {
                alert("There was an error. Please try again later");
            }
        })
    }

     // Modal stuff
  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: "20px"
    },
  };

    if(userData !== null)
    {
        return (
            <div>
                <Modal isOpen={modalOpen} style={modalStyle} onRequestClose={() => setModalOpen(false)} contentLabel='Delete Group' >
                    <h2>Are you sure you want to delete this Group?</h2>
                    
                    <button className="btn btn-danger" onClick={DeleteGroup}>Yes, Delete</button>
                    <button className="btn btn-gray" onClick={() => setModalOpen(false)}>Cancel</button>
                    
                </Modal>


                <TitleSectionMin title="View Groups" subtitle="View the groups that you have created within the Parish"/>

                <div className="p-4">
                    {groupsList.map((group, index) => {

                        var headingComponent = (
                            <div className="d-flex w-100 justify-content-between align-items-center">
                                <div><b>{group.groupname}</b></div>

                                <div className="d-flex justify-content-end">
                                    <button type="button" className="btn" onClick={() => setChildComponent(<EditGroupComponent userData={userData} groupID={group.groupid}/>)}><i style={{fontSize: "20px"}} className="bx bxs-pencil"/></button>
                                    <button type="button" className="btn" onClick={() => DownloadGroups(group)}><i style={{fontSize: "20px"}} className="bx bxs-download"/></button>
                                    <button type="button" className="btn" onClick={() => handleGroupDelete(group)}><i style={{fontSize: "20px"}} className="bx bx-trash"/></button>
                                </div>
                            </div>
                        )

                        return (
                            <DesignableAccordion headingComponent={headingComponent} extra_icon={extra_icon} extra_icon_function={DownloadGroups} extra_data={group}>
                                
                                <button className="btn btn-info" onClick={() => {setChildComponent(<EditGroupComponent userData={userData} groupID={group.groupid} />)}}>Edit Group</button>
                                
                                {group.members.map((member, index) => {
                                    return (
                                        <MemberAccordion memberData={member} userData={userData}/>
                                    )
                                })}
                            </DesignableAccordion>
                        )
                    })}
                </div>
            </div>
        );
    }
}

export default ViewGroupsComponent;