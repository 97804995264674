import { useState } from "react";
import { createAuthUserWithEmailAndPassword, createUserDocumentFromAuth } from "../../utils/firebase/firebase.util";

import { useContext, useEffect } from "react";

import { UserContext } from "../../contexts/user.context";

import { signInAuthUserWithEmailAndPassword } from "../../utils/firebase/firebase.util";
import { useNavigate } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './sign-in.css';
import { LoadBundleTask } from "firebase/firestore";

const defaultFormFields = {
    parishName: '',
    email: '',
    password: '',
    church1: '',
    church2: '',
    church3: ''
}

const SignIn = () => {
    const { currentUser } = useContext(UserContext);
    let navigate = useNavigate();
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { email, password } = formFields;

    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        document.title = "Sign In";
    })

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormFields({...formFields, [name]: value})
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            await signInAuthUserWithEmailAndPassword(email, password);
            navigate('/');
            // await createUserDocumentFromAuth(user, {}, parishName, church1, church2, church3);
        } catch (error) {
            setErrorMessage("Failed to sign in. Please check your credentials.");
        }
    }
    return (
        <div>

            <div className="signUpWrapper w-100 d-flex d-md-flex p-4 justify-content-start ">
                <div className="signUpImage"></div>

                <div className="signUpContent d-flex flex-column justify-content-center align-items-center">
                    <div>
                        <h2 className="text-start">Sign In</h2>
                        <p className="text-start">Please complete the form below to create your account.</p>

                        <form className="d-flex flex-column align-items-start w-100" onSubmit={handleSubmit}>
                            <label>Email Address</label>
                            <input required className="w-100 form-control mb-2" type="email" name="email" onChange={handleChange} placeholder="e.g. edward@email.com"/>

                            <label>Password</label>
                            <input required className="w-100 form-control" type="password" name="password" onChange={handleChange} placeholder="********"/>

                            <button type="submit" className="btn shadow btn-primary mt-4 w-100">Sign In</button>

                            {errorMessage && (
                                <p className="text-danger mt-2">{errorMessage}</p>
                            )}

                        </form>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default SignIn;