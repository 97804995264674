import React from "react";
import TitleSectionMin from "../../../components/title-section-min/title-section";
import ViewFormResponses from "../view-form-responses/view-form-responses.component.tsx";

const ViewFormResponse = ({ userData, formID, docID, response, formName, setChildComponent }) => {
    console.log(response);
    return (
        <div>
            <TitleSectionMin title={"Form Response for " + formName} subtitle="" />
            <div className="p-4">

                <button className="btn  mb-3" onClick={() => setChildComponent(<ViewFormResponses userData={userData} formID={formID} setChildComponent={setChildComponent} formName={formName} />)}><i className="bx bx-arrow-back" /> Back to Responses</button>

                {Object.entries(response).map(([key, value]) => {
                    return (
                        <div>
                            <strong>{key}</strong>
                            <p>{value}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ViewFormResponse;