import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/user.context";

// components
import MemberAccordion from "../../../components/accordion/accordion.component";
import SearchBar from "../../../components/search-bar/search-bar.component";
import { APIAuthContext } from "../../../contexts/api-auth.context";
import TitleSectionMin from "../../../components/title-section-min/title-section";

function removeFromArray(array, value){
    for (var i = array.length - 1; i >= 0; i--) {
     if (array[i] === value) {
      array.splice(i, 1);
     }
    }
  }

const CreateGroupComponent = ({ userData }) => {
    const [userMembers, setUserMembers] = useState(null);
    const [filteredUserMembers, setFilteredUserMembers] = useState(null);
    const { currentUser } = useContext(UserContext)
    const { authCreds } = useContext(APIAuthContext);
    const [groupMembers, setgroupMembers] = useState([]);
    const [groupname, setgroupname] = useState('');

    // form status
    const [formStatus, setFormStatus] = useState(null);

    useEffect(() => {
        document.title = "Create Group";
    })

    useEffect(() => {
        setFilteredUserMembers(userMembers);
    }, [userMembers])


    const handlegroupChange = (event) => {
        setgroupname(event.target.value);
        console.log(groupname);
    }

    const updategroupMembers = (event) => {
        var temp = groupMembers;

        if(event.target.checked === true)
        {
            temp.push(event.target.value);
        } else {
            removeFromArray(temp, event.target.value);
        }

        setgroupMembers([...temp]);

        console.log("group Members: ", groupMembers);

    }


    const handleNewgroup = async (event) => {
        event.preventDefault();

        if(groupname === "All Members")
        {
            alert("'All Members' is a restricted Group Name. Please select another");
            return;
        }

        let token = await currentUser.getIdToken();
        fetch('https://api.parishnavigator.com/create-group', {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "groupname": groupname,
                "members": groupMembers
             }),
             credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response['status'] === "true"){
                setFormStatus(true);
            } else {
                setFormStatus(false);
            }
        })
    }


    useEffect(() => {
        const getMembers = async() => {
            if(currentUser !== null){
                console.log(currentUser.uid);

                let token = await currentUser.getIdToken();
                fetch(`https://api.parishnavigator.com/get-members`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: "include"
                })
                    .then((members_result) => members_result.json())
                    .then((members_result) => {
                        console.log("api response = ", members_result);
                        setUserMembers(members_result);
                    })
            }
        }

        getMembers();
    }, [currentUser])
    
    if(currentUser !== null && filteredUserMembers !== null){
        return (
            <div>
                <TitleSectionMin title="Create group" subtitle="Create groups carried out by members of the Parish"/>

                <div className="p-4">
                    <form onSubmit={handleNewgroup}>
                    <input required type="text" placeholder="Group Name" className="form-control mb-1" onChange={handlegroupChange}/>
                    {formStatus === true && (
                        <p className="align-self-start mt-4 text-success"><i>Successfully created new Group</i></p>
                    )}
                    {formStatus === false && (
                        <p className="align-self-start mt-4 text-danger"><i>There was an error creating a new Group</i></p>
                    )
                    }
                    <button className="btn btn-success w-100 mb-4" type="submit"><b>Create group</b></button>

                    <SearchBar userMembers={userMembers} setFilteredUserMembers={setFilteredUserMembers} searchParameter="Full Name"/>

                    {filteredUserMembers.map((member, index) => {
                        return (
                            <div key={index} className="w-100 d-flex align-items-center">
                                <input type="checkbox" value={member.memberid} id={member.memberid} className="form-check-input me-3" onChange={updategroupMembers} checked={groupMembers.includes(member.memberid) ? 'checked': ''} style={{minWidth: "20px"}}/>
                                <MemberAccordion memberData={member} userData={userData} className="w-100"/>
                            </div>
                        )
                    })}
                    </form>
                </div>
            </div>
        )
    } else {
        return (
            <p></p>
        )
    }
}

export default CreateGroupComponent;