import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/user.context";
import 'bootstrap/dist/css/bootstrap.min.css';
import ViewFuneralsComponent from '../view-funerals/view-funerals.component.jsx';

// components
import { APIAuthContext } from "../../../contexts/api-auth.context";
import TitleSectionMin from "../../../components/title-section-min/title-section";
import EmailSender from "../../../components/email-sender/email-sender.component";
import Modal from 'react-modal';
import DeleteButton from "../../../components/delete-button/delete-button.component";

function removeFromArray(array, value){
    for (var i = array.length - 1; i >= 0; i--) {
     if (array[i] === value) {
      array.splice(i, 1);
     }
    }
  }


const EditFuneralComponent = ({userData, funeralID, setChildComponent}) => {
    const { currentUser } = useContext(UserContext);
    const { authCreds } = useContext(APIAuthContext);
    const [churchOptions, setChurchOptions] = useState([]);
    const [funeralDetails, setFuneralDetails] = useState(null);

    const [funeralFormStatus, setFuneralFormStatus] = useState(null);

    const [modalOpen, setModalOpen] = useState(null);

    const [allProcedures, setAllProcedures] = useState([]);
    const [selectedProcedures, setSelectedProcedures] = useState([]);

     // Modal stuff
  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: "20px"
    },
  };

    useEffect(() => {

        const getFuneral = async() => {
            if(currentUser !== null)
            {   
                let token = await currentUser.getIdToken();
                fetch(`https://api.parishnavigator.com/get-funeral/${funeralID}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: "include"
                })
                    .then((response) => response.json())
                    .then((response) => {
                        console.log(response);
                        if(response['status'] === "true")
                        {
                            setAllProcedures(response['allProcedures']);
                            setSelectedProcedures(response['completedProcedures']);
                            setFuneralDetails(response);
                        }
                    })
            }
        }

        getFuneral();


    }, [currentUser])

    useEffect(() => {
        if(userData !== null){
            var temp = []
            if(userData['church1'] !== '')
            {
                temp.push({'churchnum': 'church1', 'churchname': userData['church1']});
            }
            if(userData['church2'] !== '')
            {
                temp.push({'churchnum': 'church2', 'churchname': userData['church2']});
            }
            if(userData['church3'] !== '')
            {
                temp.push({'churchnum': 'church3', 'churchname': userData['church3']});
            }
            setChurchOptions(temp);
            console.log("church options: ", churchOptions);
        }
    }, [userData])

    const handleFuneralChange = (event) => {
        const {name, value} = event.target;
        setFuneralDetails({...funeralDetails, [name]: value});
        console.log(funeralDetails);
    }

    const changeSelectedProcedures = (event) => {
        var temp = selectedProcedures;

        if(event.target.checked === true){
            temp.push(event.target.value);
        } else {
            removeFromArray(temp, event.target.value);
        }

        setSelectedProcedures([...temp]);
        console.log(selectedProcedures);
    }

    const commitFuneralChange = async(event) => {
        event.preventDefault();

        var postJson = funeralDetails;
        postJson['allProcedures'] = allProcedures;
        postJson['completedProcedures'] = selectedProcedures;

        console.log(postJson);

        let token = await currentUser.getIdToken();
        fetch("https://api.parishnavigator.com/set-funeral-details", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "funeral_id": funeralID,
                "funeral_details": postJson
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response['status'] === "true"){
                setFuneralFormStatus(true);
            } else {
                setFuneralFormStatus(false);
            }
        })
    }

    if(funeralDetails !== null && userData !== null)
    {
        return (
            <div>
                <Modal isOpen={modalOpen} style={modalStyle} onRequestClose={() => setModalOpen(false)} contentLabel='Send Email' >
                    <h2>Send Email to {funeralDetails.contactName}</h2>
                    <hr />
                    <EmailSender recipients={funeralDetails.email} parishName={userData.parishName} senderEmail={userData.email} apiRoute='send-email'/>
                </Modal>


                <TitleSectionMin title={"Funeral of " + funeralDetails.deceasedName} />
                <div className="p-4">
                <form className="formGrid w-100 flex-fill " onSubmit={commitFuneralChange}>
                    
                    <div class="d-flex flex-column mb-3">
                        <label>Name of Deceased*</label>
                        <input required type="text" className="form-control" name="deceasedName" value={funeralDetails.deceasedName} onChange={handleFuneralChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Contact Name</label>
                        <input type="text" className="form-control" name="contactName" value={funeralDetails.contactName} onChange={handleFuneralChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Contact Email</label>
                        <input type="email" className="form-control" name="contactEmail" value={funeralDetails.contactEmail} onChange={handleFuneralChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Contact Phone</label>
                        <input type="phone" className="form-control" name="contactPhone" value={funeralDetails.contactPhone} onChange={handleFuneralChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Date of Funeral*</label>
                        <input type="date" className="form-control" name="funeralDate" value={funeralDetails.funeralDate} onChange={handleFuneralChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Time of Funeral*</label>
                        <input type="time" className="form-control" name="funeralTime" value={funeralDetails.funeralTime} onChange={handleFuneralChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Church</label>
                        <select className="form-select" name="funeralChurch" value={funeralDetails.funeralChurch} onChange={handleFuneralChange}>
                            {churchOptions.map((church) => {
                                return(
                                <option value={church.churchnum}>{church.churchname}</option>
                                );
                            })}
                        </select>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Minister Name*</label>
                        <input type="text" className="form-control" name="ministerName" value={funeralDetails.ministerName} onChange={handleFuneralChange}/>
                    </div>

                    <div class="d-flex flex-column mb-3">
                        <label>Quick Notes</label>
                        <textarea name="quickNotes" className="form-control" value={funeralDetails.quickNotes} onChange={handleFuneralChange}/>
                    </div>


                    <div className="rolesContainer d-flex flex-wrap">
                        {allProcedures.map((procedure) => {
                            return (
                                <div className="me-4 mb-3">
                                    {/* <p>hey there</p> */}
                                    <input type="checkbox" class="form-check-input me-2" id={procedure} value={procedure} checked={(selectedProcedures.includes(procedure) ? 'checked': '')} onChange={changeSelectedProcedures}/>
                                    <label for={procedure}>{procedure}</label>
                                </div>    
                            )
                        })}
                    </div>

                    <div className="rolesContainer d-flex flex-wrap w-100">
                        {/* Button */}
                        <button type="button" className="btn btn-success" onClick={() => setModalOpen(true)}>
                        Send Email
                        </button>

                        <div className="ms-2">
                        <DeleteButton userData={userData} formName="funerals" formID={funeralID} type="button" onDelete={() => {setChildComponent(<ViewFuneralsComponent userData={userData} setChildComponent={setChildComponent} />)}}/>
                        </div>
                    </div>

                    <div>
                        {funeralFormStatus === true && (
                            <p className="align-self-start mt-4 text-success"><i>Successfully updated Funeral Details</i></p>
                        )}
                        {funeralFormStatus === false && (
                            <p className="align-self-start mt-4 text-danger"><i>There was an error updating Funeral Details</i></p>
                        )
                        }

                        <button type="submit" className="btn btn-primary mt-4">Save Changes</button>
                    </div>
            
                </form>
                </div>
        </div>
        );
    }

}

export default EditFuneralComponent;