import './title-section.css';

const TitleSectionMin = ({title, subtitle}) => {
    return (
        <div className="mt-3 w-100 border-bottom d-flex flex-column px-4 justify-content-center align-items-start">
            <h2 className='title'><b>{title}</b></h2> 
            <p className='subtitle'>{subtitle}</p>
        </div>
    );
}

export default TitleSectionMin;