import { useContext, useEffect, useState, useSyncExternalStore } from "react";
import { UserContext } from "../../../contexts/user.context";
import EmailSender from "../../../components/email-sender/email-sender.component";
import { APIAuthContext } from "../../../contexts/api-auth.context";
import TitleSectionMin from "../../../components/title-section-min/title-section";

function removeFromArray(array, value){
    for (var i = array.length - 1; i >= 0; i--) {
     if (array[i] === value) {
      array.splice(i, 1);
     }
    }
  }

const SendGroupEmailComponent = ({ userData }) => {

    const [groups, setGroups] = useState(null);
    
    const { currentUser } = useContext(UserContext);
    const { authCreds } = useContext(APIAuthContext);

    const [selectedGroups, setSelectedGroups] = useState([]);

    useEffect(() => {
        document.title = "Send Group Emails";
    })

    const updatedSelectedGroups = (event) => {
        console.log(event.target.value, event.target.checked);
        var temp = selectedGroups;

        if(event.target.checked === true)
        {
            temp.push(event.target.value);
        } else {
            removeFromArray(temp, event.target.value);
        }

        setSelectedGroups(temp);
        console.log("selected groups: ", selectedGroups);
    }

    useEffect(() => {

        const getGroups = async() => {
            if(currentUser !== null){
                console.log(currentUser.uid);

                let token = await currentUser.getIdToken();
                fetch(`https://api.parishnavigator.com/get-groups`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: "include"
                })
                    .then((groups_result) => groups_result.json())
                    .then((groups_result) => {
                        console.log("api response = ", groups_result);
                        setGroups(groups_result);
                    })
            }
        }

        getGroups();
    }, [currentUser])

    if(groups !== null && userData !== null)
    {
        return (
            <div>
                <TitleSectionMin title="Send Group Emails"/>
                <div className="p-4">
                    <div className="d-flex flex-wrap">
                        {groups.map((group, index) => {
                        return (
                            <div className="me-4 mb-3">
                                <input onChange={updatedSelectedGroups} type="checkbox" class="form-check-input me-2" id={group.groupid} value={group.groupid}/>
                                <label for={group.groupid}>{group.groupname}</label>
                            </div>
                            )
                        })}
                    </div>

                    <EmailSender recipients={selectedGroups} parishName={userData.parishName} senderEmail={userData.email} userId={userData.parishid} apiRoute="send-group-contact"/>
                </div>
            </div>
        )
    }

}

export default SendGroupEmailComponent;