import { useContext, useEffect, useState } from "react"
import { APIAuthContext } from "../../../contexts/api-auth.context";
import { UserContext } from "../../../contexts/user.context";
import TitleSectionMin from "../../../components/title-section-min/title-section";

const ViewSubUsersComponent = ({ userData }) => {
    const [subUsers, setSubUsers] = useState([]);
    const { authCreds } = useContext(APIAuthContext);
    const { currentUser } = useContext(UserContext);

    useEffect(() => {

        const getSubUsers = async() => {
            if((userData !== null) && (userData.account_type === "parish"))
            {
                let token = await currentUser.getIdToken();
                fetch(`https://api.parishnavigator.com/get-subusers`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: "include"
                })
                .then((subusers) => subusers.json())
                .then((subusers) => {
                    console.log("subusers: ", subusers);
                    if(subusers['status'] === "true")
                    {
                        setSubUsers(subusers['subusers'])
                    }
                })
            }
        }
        getSubUsers();
    }, [currentUser])

    const updateAccountType = async(userid, newtype, index) => {
        console.log(userid, newtype);

        if (newtype === "parish")
        {
            return;
        }

        let token = await currentUser.getIdToken();
        fetch(`https://api.parishnavigator.com/update-subuser`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "subuserid": userid,
                "newtype": newtype
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            if(response['status'] === "true")
            {
                var temp = subUsers;
                temp[index].account_type = newtype;
                setSubUsers([...temp]);
            }
        })

    }

    if(userData !== null)
    {
        return (
            <div>
                <TitleSectionMin title="View Parish Sub Accounts" subtitle="Any Changes are made in real time"/>
                <div className="p-4">
                    {subUsers.map((subuser, index) => {
                        return (
                            <div className="d-flex mt-2 rounded-3 justify-content-between align-items-center  w-100 bg-white p-3">
                                <div>{subuser.personalName}</div>
                                <select name="" id="" value={subuser.account_type} className="form-select" style={{maxWidth: "300px"}} onChange={(event) => updateAccountType(subuser.subuserid, event.target.value, index)}>
                                    <option value="View">View</option>
                                    <option value="Edit">Edit</option>
                                </select>
                            </div>

                        )
                    })
                    }
                </div>
            </div>
        )
    }
}

export default ViewSubUsersComponent;