import { CurrencyBitcoin } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import { APIAuthContext } from "../../contexts/api-auth.context";
import MemberAccordion from "../accordion/accordion.component";
import TitleSectionMin from "../title-section-min/title-section";
import ListItemComponent from "../list-item/list-item.component";
import EditFuneralComponent from "../../function-components/funerals/edit-funeral/edit-funeral.component";
import ToDoComponent from "../../function-components/to-do/to-do.component.jsx";
import EditBaptismComponent from "../../function-components/baptisms/edit-baptism/edit-baptism.component.jsx";
import DesignableAccordion from "../accordion/designable-accordion.component.jsx";
import Modal from 'react-modal';
import EmailSender from "../email-sender/email-sender.component.jsx";

import './home-dashboard.css';
import '../../function-components/funerals/view-funerals/view-funerals.css';
import { UserContext } from "../../contexts/user.context.jsx";
import base64 from "base-64";

interface ChurchOption {
    churchnum: string;
    churchname: string;
  }

const HomeDashboard = ({ userData, setChildComponent }) => {

    const [userMembers, setUserMembers] = useState<any>([]);
    const [funeralList, setFuneralList] = useState<any>([]);
    const [baptismsList, setBaptismsList] = useState<any>([]);
    const [churchOptions, setChurchOptions] = useState<any>([]);
    const [formsList, setFormsList] = useState<any>([]);
    const [refreshKey, setRefreshKey] = useState(0);
    const { authCreds } = useContext(APIAuthContext);
    const { currentUser } = useContext<any>(UserContext);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalInf, setModalInf] = useState<any>([]);

    function dateToWordForm(dateString) {
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
    
        const date = new Date(dateString);
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
    
        // Determine the suffix for the day
        let suffix = "th";
        if (day === 1 || day === 21 || day === 31) {
            suffix = "st";
        } else if (day === 2 || day === 22) {
            suffix = "nd";
        } else if (day === 3 || day === 23) {
            suffix = "rd";
        }
    
        const wordDate = `${day}${suffix} ${month} ${year}`;
        return wordDate;
    }

    

    // church data
    useEffect(() => {
        if(userData !== null){
            var temp: Array<{churchnum: String; churchname: string }> = [];
            if(userData['church1'] !== '')
            {
                temp.push({'churchnum': 'church1', 'churchname': userData['church1']});
            }
            if(userData['church2'] !== '')
            {
                temp.push({'churchnum': 'church2', 'churchname': userData['church2']});
            }
            if(userData['church3'] !== '')
            {
                temp.push({'churchnum': 'church3', 'churchname': userData['church3']});
            }
            setChurchOptions(temp);
            console.log("church options: ", churchOptions);
        }
    }, [userData])


    useEffect(() => {
        if (userData !== null) {
            const fetchData = async () => {
                let token = await currentUser.getIdToken();

                try {
    
                    // Fetch New Members
                    const members_result = await fetch(`https://api.parishnavigator.com/get-members`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                        credentials: "include"
                    });

                    const membersData = await members_result.json();
                    console.log("New Members API response = ", membersData);
                    setUserMembers(membersData.slice(0, 5));
    
                    // Fetch Funerals
                    const funerals_result = await fetch(`https://api.parishnavigator.com/get-funerals`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                        credentials: "include"
                    });
                    const funeralsData = await funerals_result.json();
                    console.log("Funerals API response = ", funeralsData);
                    setFuneralList(funeralsData['funerals'].splice(0, 5));
    
                    // Fetch Baptisms
                    const baptisms_result = await fetch(`https://api.parishnavigator.com/get-baptisms`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                        credentials: "include"
                    });
                    const baptismsData = await baptisms_result.json();
                    console.log("Baptisms API response = ", baptismsData);
                    setBaptismsList(baptismsData['baptisms'].splice(0, 5));
    
                    // Fetch Contact Submissions
                    const forms_result = await fetch(`https://api.parishnavigator.com/get-contact-submissions`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                        credentials: "include"
                    });
                    const formsData = await forms_result.json();
                    console.log("Forms API response = ", formsData);
                    setFormsList(formsData['forms'].splice(0, 5));
    
                } catch (error) {
                    console.log("Error here is" + error);
                    alert("There was an error. Please try again later");
                }
            };
    
            fetchData();
        }
    }, [userData, refreshKey]);

    const deleteForm = async (formid) => {
        var token = await currentUser.getIdToken();

        fetch(`https://api.parishnavigator.com/delete-doc`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "collection_string": "contact_submissions",
                    "doc_id": formid
                }),
                credentials: "include"
            }).then(response => response.json())
            .then((response) => {
                if(response['status'] === "true")
                {
                    setRefreshKey(refreshKey+1);
                }
                else {
                    alert("There was an error. Please try again later");
                }
            })
    }

    const setModal = (form) => {
        setModalOpen(true);
        setModalInf(form);
    }
    // Modal stuff
  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: "20px"
    },
  };

    return(
        <div>
            <Modal isOpen={modalOpen} style={modalStyle} onRequestClose={() => setModalOpen(false)} contentLabel='Send Email' >
                <h2>Send Email to {modalInf.fullName}</h2>
                <hr />
                <EmailSender recipients={modalInf.email} parishName={userData.parishName} senderEmail={userData.email} userId={userData.parishid} apiRoute='contact-person'/>
            </Modal>
            <div>
                {('personalName' in userData) && (<TitleSectionMin title={"Hey, " + userData.personalName} subtitle="" />)}
                {!('personalName' in userData) && (<TitleSectionMin title={"Hey, " + userData.parishName} subtitle="" />)}
            </div>
            
            {/* Newest Members */}
            <div className="mt-2 w-100 bg-body p-3 rounded-3">
                <h4><strong>Newest Parish Members</strong></h4>
                {userMembers.map((member, index) => {
                    return (
                        <MemberAccordion memberData={member} userData={userData} setChildComponent={setChildComponent}/>
                    )
                })}
            </div>

            {/* To Do List and Upcoming Funerals */}
            <div className="mt-2 w-100 d-block d-xl-flex overflow-hidden fixed-height">

                <div className="bg-body p-3 rounded-3 me-3 overflow-auto sixty-five">
                    <h4><strong>Latest Funerals</strong></h4>
                    {funeralList.map((funeral, index) => {
                            return (
                                <ListItemComponent setChildComponent={setChildComponent} newState={<EditFuneralComponent userData={userData} funeralID={funeral.funeralid} setChildComponent={setChildComponent} />} key={'funeral'+index}>
                                    <div className="w-100 d-flex justify-content-start">
                                        <div className="d-flex flex-column thirty-five-funeral" >
                                            <div style={{fontSize: "12px"}}>Name of Deceased</div>
                                            <div><b>{funeral.deceasedName}</b></div>
                                        </div>

                                        <div className="d-none d-lg-block">
                                            <div style={{fontSize: "12px"}}>Date/Time of Funeral</div>
                                            <div><b>{dateToWordForm(funeral.funeralDate)} at {funeral.funeralTime}</b></div>
                                        </div>

                                        <div className="ms-auto d-flex justify-content-center align-items-center">
                                            <div>Go to Funeral </div>
                                        </div>

                                    </div>
                                </ListItemComponent>
                            )
                        })}
                </div>

                <div className="bg-body rounded-3 overflow-auto mt-2 mt-xl-0 thirty-five toDoFixed">
                    <ToDoComponent userData={userData} />
                </div>
            </div>

            {/* Baptism Applications + Contact us Forms */}
            <div className="mt-2 w-100 d-block d-xl-flex overflow-hidden fixed-height">
                <div className="bg-body p-3 rounded-3 overflow-auto fifty me-xl-3">
                    <h4><strong>Latest Baptism Applications</strong></h4>
                    
                    {baptismsList.map((baptism, index) => {
                        return (
                            <ListItemComponent setChildComponent={setChildComponent} newState={<EditBaptismComponent userData={userData} baptismID={baptism.baptismid} setChildComponent={setChildComponent}/>} key={'baptism'+index}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <div style={{ fontWeight: "100", fontSize: "12px"}}>Child's Full Name</div>
                                        <b>{baptism.childFullName}</b>
                                    </div>

                                    <div className="goButton py-2 px-3 d-flex align-items-center" onClick={() => {setChildComponent(<EditBaptismComponent userData={userData} baptismID={baptism.baptismid} setChildComponent={setChildComponent}/>)}}>
                                        Go to Baptism <i className="bx bx-chevron-right"></i>
                                    </div>
                                </div>
                            </ListItemComponent>
                        )
                    })}

                </div>

                <div className="bg-body p-3 mt-2 mt-xl-0 rounded-3 overflow-auto fifty">
                    <h4><strong>Latest Contact us Forms</strong></h4>
                {formsList.map((form, index) => {
                        var headingComponent = (
                            <div className="d-flex w-100 justify-content-between align-items-center">
                                <div><b>{form.subject}</b></div>
                                <div className="d-flex align-items-center">
                                    <div className="deleteButton me-2" onClick={() => {deleteForm(form.formid)}}><i className="bx bx-trash"></i></div>
                                    {form.fullName}</div>
                                </div>
                        );
                        return (
                            <DesignableAccordion headingComponent={headingComponent} key={'contact'+index}>
                                {atob(form.message).split('\n').map((line, index) => (
                                <div className="" key={index}>
                                    {line}
                                    <br />
                                </div>
                                ))}

                                {form.generatedResponse && (<p>Generated Response: {atob(form.generatedResponse).split('\n').map((line, index) => (
                                <div className="" key={index}>
                                    {line}
                                    <br />
                                </div>
                                ))}</p>)}

                                <p>Email: {form.email}</p>
                                <p>Phone: {form.phone}</p>
                                
                                <div className="d-flex">
                                    <div className="">                                        
                                            {/* Button */}
                                            <button type="button" className="btn btn-primary" onClick={() => setModal(form)}>
                                            Send Email
                                            </button>
                                        
                                    </div>
                                </div>

                            </DesignableAccordion>
                        )
                    })}
                </div>
            </div>

        </div>
    )
}

export default HomeDashboard;