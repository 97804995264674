import { createAuthUserWithEmailAndPassword, createSubUserDocumentFromAuth, createUserDocumentFromAuth, fetchUserData, signOutUser } from "../../../utils/firebase/firebase.util";

import { useContext, useEffect, useState } from "react";

import { UserContext } from "../../../contexts/user.context";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../routes/sign-up/sign-up.css';
import { useNavigate } from "react-router-dom";

const defaultFormFields = {
    personalName: '',
    email: '',
    password: '',
    account_type: ''
}



const CreateSubUserComponent = ({ userData }) => {
    const { currentUser } = useContext(UserContext);
    let navigate = useNavigate();
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { personalName, email, password, account_type } = formFields;

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormFields({...formFields, [name]: value})
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if(account_type === "parish")
        {
            return;
        }

        try {
            const { user } = await createAuthUserWithEmailAndPassword(email, password);
            await createSubUserDocumentFromAuth(personalName, account_type, user, currentUser.uid);
            navigate('/re-auth-new-sub-user');
        } catch (error) {
            console.log(error);
        }
    }

    if(userData !== null && (userData.account_type === "parish"))
    {
        return (

            <div className="">
                <div className="p-4">
                    <h2 className="text-start">Create a Sub Account for {userData.parishName}</h2>
                    <p className="text-start">Please complete the form below to create your account.</p>

                    <form className="d-flex flex-column align-items-start w-100" onSubmit={handleSubmit}>
                        <label>Name</label>
                        <input required className="w-100 form-control mb-2" type="text" name="personalName" onChange={handleChange} placeholder="John Smith"/>

                        <label>Email Address</label>
                        <input required className="w-100 form-control mb-2" type="email" name="email" onChange={handleChange} placeholder="e.g. edward@email.com"/>

                        <label>Password</label>
                        <input required className="w-100 form-control" type="password" name="password" onChange={handleChange} placeholder="********"/>

                        <label>Access Level</label>
                        <select required className="form-select" name="account_type" onChange={handleChange} >
                            <option value="View">View</option>
                            <option value="Edit">Edit</option>
                        </select>                            

                        <div className="mt-3">
                            <input required type="checkbox" className="form-check-input" id="tacagree"/>
                            <label htmlFor="tacagree" className="ms-2">I agree to the Terms and Conditions</label>
                        </div>

                        <button type="submit" className="btn shadow btn-primary mt-4 w-100">Sign Up</button>

                    </form>
                </div>
            </div>
        );
    }
}

export default CreateSubUserComponent;