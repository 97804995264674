

// THIS IS USED TO SIGN IN AFTER A PARISH HAS CREATED A NEW SUB ACCOUNT ONLY


import { useState } from "react";

import { useContext, useEffect } from "react";

import { UserContext } from "../../../contexts/user.context";

import { signInAuthUserWithEmailAndPassword, signOutUser } from "../../../utils/firebase/firebase.util";
import { useNavigate } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../sign-in.css';
import { LoadBundleTask } from "firebase/firestore";

const defaultFormFields = {
    parishName: '',
    email: '',
    password: '',
    church1: '',
    church2: '',
    church3: ''
}

const SignInAfterNewSubUser = () => {
    const { currentUser } = useContext(UserContext);
    let navigate = useNavigate();
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { email, password } = formFields;

    useEffect(() => {
        document.title = "Sign In";
        signOutUser();
    })

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormFields({...formFields, [name]: value})
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            await signInAuthUserWithEmailAndPassword(email, password);
            navigate('/');
        } catch (error) {
            console.log(error);
        }
    }
    return (

        <div>

            <div className="signUpWrapper w-100 d-flex d-md-flex p-4 justify-content-start ">
                <div className="signUpImage"></div>

                <div className="signUpContent d-flex flex-column justify-content-center align-items-center">
                    <div>
                        <h2 className="text-start">Sign In to Parish Account</h2>
                        <p className="text-start">You are required to Sign in to your Parish Account when a new sub-user is created. This is for Security Reasons.</p>

                        <form className="d-flex flex-column align-items-start w-100" onSubmit={handleSubmit}>
                            <label>Email Address</label>
                            <input required className="w-100 form-control mb-2" type="email" name="email" onChange={handleChange} placeholder="e.g. edward@email.com"/>

                            <label>Password</label>
                            <input required className="w-100 form-control" type="password" name="password" onChange={handleChange} placeholder="********"/>

                            <button type="submit" className="btn shadow btn-primary mt-4 w-100">Sign In</button>

                        </form>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default SignInAfterNewSubUser;