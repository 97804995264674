import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import 'bootstrap/dist/css/bootstrap.min.css';
import './accordion.css';
import EmailSender from '../email-sender/email-sender.component';
import Modal from 'react-modal';
import EditMember from '../../function-components/members/edit-member/edit-member.component.tsx';

const MemberAccordion = ({memberData, userData, setChildComponent}) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  // Modal stuff
  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: "20px"
    },
  };

  return (
    <div className='my-2 w-100'>
      <Modal isOpen={modalOpen} style={modalStyle} onRequestClose={() => setModalOpen(false)} contentLabel='Send Email' >
        <h2>Send Email to {memberData.forename}</h2>
        <hr />
        <EmailSender recipients={memberData.memberid} parishName={userData.parishName} senderEmail={userData.email} apiRoute='contact-person'/>
      </Modal>
      <Accordion className='accordionBorder'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <div className='w-100 d-flex justify-content-start'>
                <div className='d-flex flex-column' style={{width: "35%", maxWidth: "35%"}}>
                  <div><b>{memberData.forename} {memberData.surname}</b></div>
                  <div style={{ fontWeight: "100", fontSize: "12px"}}>{memberData.email}</div>
                </div>

                <div>
                  <div style={{ fontWeight: "100", fontSize: "12px"}}>Church</div>
                  <div><b>{memberData.churchName}</b></div>
                </div>

                <div className='d-flex justify-content-center align-items-center ms-auto'>                                      
                            <button type='button' className='accordionIcon d-flex justify-content-center align-items-center me-4' onClick={() => setChildComponent(<EditMember memberInfo={memberData} userData={userData} />)}>
                              <i className='bx bx-edit-alt'/>
                            </button>

                            {/* Button */}
                            <button type="button" className="accordionIcon d-flex justify-content-center align-items-center" onClick={() => setModalOpen(true)}>
                              <i className='bx bx-envelope'/>
                            </button>

                            {/* Modal */}
                            <div className="modal fade" id={`memberModal${memberData.memberid}`} tabIndex="-1" aria-labelledby={`memberModalLabel${memberData.memberid}`} aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-header">
                                      <h1 className="modal-title fs-5" id={`memberModalLabel${memberData.memberid}`}>Send Email to {memberData.forename}</h1>
                                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                  </div>
                                  <div className="modal-body">
                                      <EmailSender recipients={memberData.memberid} parishName={userData.parishName} senderEmail={userData.email} apiRoute='contact-person'/>
                                  </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </AccordionSummary>
        <AccordionDetails>

          <div>
            <p className='mb-2'>Forename: {memberData.forename}</p>
            <p className='mb-2'>Surname: {memberData.surname}</p>
            <p className='mb-2'>Church: {memberData.churchName}</p>
            <p className='mb-2'>Occupation: {memberData.occupation}</p>
            <p className='mb-2'>Date of Birth: {memberData.dob}</p>
            <p className='mb-2'>Email: {memberData.email}</p>
            {memberData.parentemail && <p className='mb-2'>Parent's Email: {memberData.parentemail}</p>}
            <p className='mb-2'>Phone: {memberData.phone}</p>
            <p className='mb-2'>Address: {memberData.address}</p>
            <p className='mb-2'>Roles: {memberData.roles_string}</p>

          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
export default MemberAccordion;