import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../contexts/user.context';

// components
import MemberAccordion from '../../../components/accordion/accordion.component';
import { APIAuthContext } from '../../../contexts/api-auth.context';
import TitleSectionMin from '../../../components/title-section-min/title-section';
import TitleSectionCustom from '../../../components/title-section-min/title-section-customisable.component.tsx';
import SearchBar from '../../../components/search-bar/search-bar.component.jsx';

function removeFromArray(array, value){
    for (var i = array.length - 1; i >= 0; i--) {
     if (array[i] === value) {
      array.splice(i, 1);
     }
    }
  }

const EditGroupComponent = ({ userData, groupID }) => {
    const { currentUser } = useContext(UserContext);
    const { authCreds } = useContext(APIAuthContext);

    const [groupName, setGroupName] = useState('Group');
    const [groupMembers, setgroupMembers] = useState([]);
    const [restMembers, setRestMembers] = useState([]);
    const [filteredRestMembers, setFilteredRestMembers] = useState([]);

    const [deleteUsers, setDeleteUsers] = useState([]);
    const [addUsers, setAddUsers] = useState([]);

    const [refreshKey, setRefreshKey] = useState(0);

    const [editGroupName, setEditGroupName] = useState(false);

    useEffect(() => {
        setFilteredRestMembers(restMembers);
    }, [restMembers])

    const updateDeleteUsers = (event) => {
        var temp = deleteUsers;

        if(event.target.checked === true)
        {
            temp.push(event.target.value);
        } else {
            removeFromArray(temp, event.target.value);
        }

        setDeleteUsers([...temp]);

        console.log("deleting users: ", deleteUsers);

    }

    const updateAddUsers = (event) => {
        var temp = addUsers;

        if(event.target.checked === true)
        {
            temp.push(event.target.value);
        } else {
            removeFromArray(temp, event.target.value);
        }

        setAddUsers([...temp]);

        console.log("adding users: ", addUsers);

    }

    // handle Group removal
    const handleGroupRemoval = async(event) => {
        event.preventDefault();

        let token = await currentUser.getIdToken();
        fetch('https://api.parishnavigator.com/remove-group-member', {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "deleteMembers": deleteUsers,
                "groupid": groupID
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            // window.location.reload();
            setRefreshKey(refreshKey+1);
        })
    }

    // handle group addition
    const handleGroupAddition = async(event) => {
        event.preventDefault();

        let token = await currentUser.getIdToken();
        fetch('https://api.parishnavigator.com/add-group-member', {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "addMembers": addUsers,
                "groupid": groupID
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            // window.location.reload();
            setRefreshKey(refreshKey+1);
        })

    }

    useEffect(() => {

        const getGroupF = async() => {
            if(currentUser !== null){
                console.log(currentUser.uid);
                
                let token = await currentUser.getIdToken();
                fetch(`https://api.parishnavigator.com/get-group-members-filtered/${groupID}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: "include"
                })
                    .then((group_members) => group_members.json())
                    .then((group_members) => {
                        console.log("api response = ", group_members);
                        setGroupName(group_members['group_name'])
                        setgroupMembers(group_members['group_members']);
                        setRestMembers(group_members['rest']);
                    });
            }
        }

        getGroupF();

        setAddUsers([]);
        setDeleteUsers([]);

    }, [currentUser, refreshKey])

    const updateGroupName = async() => {
        let token = await currentUser.getIdToken();
        fetch('https://api.parishnavigator.com/edit-group-name', {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "groupid": groupID,
                'new_name': groupName
            }),
            credentials: "include"
        }).then((response) => response.json())
        .then((response) => {
            if (response['status'] === "false")
            {
                alert("There was an error");
            } else {
                setEditGroupName(false);
            }
        })
    }

    return (
        <div>

            {!editGroupName && (
                <TitleSectionCustom title={
                    <div className='d-flex align-items-center'>
                        <h2 className='title'><b>{groupName}</b></h2>
                        <button type="button" className="btn" onClick={() => setEditGroupName(!editGroupName)}><i className="bx bxs-pencil inlineIcon" /></button>
                    </div>
                }
                subtitle="Add or remove members from specific Groups within the Parish" 
                />
            )}

            {editGroupName && (
                <TitleSectionCustom title={
                    <div className="d-flex align-items-center">
                        <input type="text" className='form-control' value={groupName} onChange={e => setGroupName(e.target.value)}/>
                        <button type="button" className="btn"><i className='bx bxs-check-circle inlineIcon' style={{color: "green"}} onClick={updateGroupName} /></button>
                    </div>
                }

                />
            )}

            <div className="p-4">
                <form onSubmit={handleGroupRemoval} className='mb-5'>
                    <button type="submit" className='btn btn-danger w-100 mb-2'>Remove selection from Group</button>
                    {groupMembers.map((member, index) => {
                        return (
                            <div key={index} className='w-100 d-flex align-items-center'>
                                <input type="checkbox" value={member.memberid} id={member.memberid} className='form-check-input me-3' onChange={updateDeleteUsers} checked={deleteUsers.includes(member.memberid) ? 'checked': ''} style={{minWidth: "20px"}}/>
                                <MemberAccordion memberData={member} userData={userData}/>
                            </div>
                        )
                    })}
                </form>

                <form onSubmit={handleGroupAddition}>
                    <button type="submit" className='btn btn-success w-100 mb-2'>Add selection to Group</button>
                    
                    <SearchBar userMembers={restMembers} setFilteredUserMembers={setFilteredRestMembers} searchParameter="Full Name" />
                    {filteredRestMembers.map((member, index) => {
                        return (
                            <div key={index} className="w-100 d-flex align-items-center">
                                <input type="checkbox" value={member.memberid} id={member.memberid} className='form-check-input me-3' onChange={updateAddUsers} checked={(addUsers.includes(member.memberid) ? 'checked': '')} />
                                <MemberAccordion memberData={member} userData={userData}/>
                            </div>
                        )
                    })}
                </form>
            </div>
        </div>
    )
}

export default EditGroupComponent;