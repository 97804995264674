
import { useEffect, useState, useContext } from "react";
import ParishNavSidebar from "../../components/ParishNav-Sidebar/sidebar.component"
import AddMembersComponent from "../../function-components/members/add-members/add-members.component";
import { fetchUserData } from "../../utils/firebase/firebase.util";
import { UserContext } from "../../contexts/user.context";
import HomeDashboard from "../../components/home-dashboard/home-dashboard.component.tsx";
import FormCreator from "../../function-components/custom-forms/form-creator/form-creator.component.tsx";
import ViewForms from "../../function-components/custom-forms/view-forms/view-forms.component.tsx";
import VerifyEmailPage from "../../components/verify-email-page/verify-email-page.component.tsx";
import { getAuth, sendEmailVerification } from "firebase/auth";

const Combined = () => {
    const [userData, setUserData] = useState(null);
    const { currentUser } = useContext(UserContext);
    const [userAuthenticated, setUserAuthenticated] = useState(false);
    const [childComponent, setChildComponent] = useState(null);

    useEffect(() => {
        if(currentUser !== null){
            console.log(currentUser.uid);
            console.log("sidebar userdata");
            fetchUserData(currentUser.uid, setUserData);
        }
    }, [currentUser])

    useEffect(() => {
    const fetchUserToken = async () => {
        if (userData !== null) {
            try {
                let t = await currentUser.getIdToken();
                console.log("User Token: " + t);
            } catch (error) {
                console.error('Error fetching user token:', error);
            }
        }
    };

    fetchUserToken(); // Call the async function
}, [userData, currentUser]); // Make sure to include dependencies for useEffect

    useEffect(() => {
        if(userData !== null)
        {
            // if(!currentUser.emailVerified) {
            //     // const auth = getAuth();
            //     // sendEmailVerification(auth.currentUser)
            //     // .then(() => {
            //     //     console.log("sent email for verification");
            //     // })
            // } else {
            //     setChildComponent(<HomeDashboard userData={userData} setChildComponent={setChildComponent}/>);
            // }

            setChildComponent(<HomeDashboard userData={userData} setChildComponent={setChildComponent}/>);
        }
    }, [userData])
    

    if(userData !== null)
    {
        return (
            <ParishNavSidebar userData={userData} childComponent={childComponent} setChildComponent={setChildComponent} setUserData={setUserData}/>
        )
        // if (userAuthenticated)
        // {
        //     return (
        //         <ParishNavSidebar userData={userData} childComponent={childComponent} setChildComponent={setChildComponent} setUserData={setUserData}/>
        //     )
        // }
        // if (!currentUser.emailVerified)
        // {
        //     return (
        //         <VerifyEmailPage/>
        //     )
        // }
        // else {
        //     return (
        //         <EmailAuthentication userData={userData} childComponent={childComponent} setChildComponent={setChildComponent} setUserData={setUserData} setUserAuthenticated={setUserAuthenticated}/>
        //     )
        // }
    }
}

export default Combined;