import './App.css';

import { Routes, Route } from 'react-router-dom';
import { PrivateRouter } from './components/private-router/private-router.component';

import SignIn from './routes/sign-in/sign-in.component';
import SignUp from './routes/sign-up/sign-up.component';

import OnlineParishRegistration from './routes/members/online-parish-registration/online-parish-registration.component';

// baptisms
import OnlineBaptismApplication from './routes/baptisms/online-baptism-application/online-baptism-application.component';

import PurchaseSubscription from './routes/purchase-subscription/purchase-subscription.component';
// import SendRoleEmail from './routes/roles/send-role-emails/send-role-email.component';

import PrivacyPolicy from './routes/privacy-policy/privacy-policy.component';
import TermsAndConditions from './routes/terms-and-conditions/terms-and-conditions.component';
import SignInAfterNewSubUser from './routes/sign-in/re-auth-new-subuser/re-auth-new-subuser.component';
import Combined from './routes/sidebar/combined.component';
import OnlineContactForm from './routes/contact-us/online-contact-form/online-contact-form.component';
import OnlineCustomForm from './routes/custom-forms/online-custom-forms.component.tsx';


function App() {
  return (

    <Routes>
      <Route path='/' >
        <Route exact path='' element={
          <PrivateRouter>
          <Combined /> 
        </PrivateRouter>
        } />

        <Route path='sign-up' element={<SignUp />} />
        <Route path='sign-in' element={<SignIn />} />

        {/* Online Forms */}
        <Route path='online-parish-registration' element={<OnlineParishRegistration />} />

        <Route path='online-baptism-application' element={
            <OnlineBaptismApplication />
        } />

        <Route path='online-custom-form' element={
          <OnlineCustomForm />
        } />

        <Route path='contact-us' element={<OnlineContactForm />} />

        <Route path='purchase-software' element={
          <PrivateRouter>
            <PurchaseSubscription />
          </PrivateRouter>
        } />

        

        {/* Re authentication after new sub user */}
        <Route path='re-auth-new-sub-user' element={<SignInAfterNewSubUser />} />


        <Route path='privacy-policy' element={<PrivacyPolicy />} />
        <Route path='terms-and-conditions' element={<TermsAndConditions />} />
        
      </Route>
    </Routes>
  );
}

export default App;
