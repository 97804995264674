import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../contexts/user.context';
import { Route, Redirect, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { app } from '../../utils/firebase/firebase.util';
import { Navigate } from 'react-router-dom';


export const PrivateRouter = ({children}) => {
    const auth = getAuth(app);
    const [loggedState, setLoggedState] = useState(0);

    useEffect(() => {
        auth.onAuthStateChanged(() => {
            console.log("current user = ", auth.currentUser);
            if(auth.currentUser === null)
            {
                setLoggedState(1);
            } else {
                setLoggedState(2);
            }
        })
    }, [])

    if (loggedState === 0)
    {
        <h1>loading from router</h1>
    }
    if (loggedState === 1)
    {
        return <Navigate to='/sign-in'/>
    }
    if (loggedState === 2)
    {
        return children;
    }

    // if (user === undefined)
    // {
    //     return <Navigate to='/sign-in' />
    // }
    // else {
    //     console.log("childs");
    //     return children;
    // }

}