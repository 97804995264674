import { useContext, useState } from "react";
import { UserContext } from "../../contexts/user.context";
import 'bootstrap/dist/css/bootstrap.min.css';

import { app } from "../../utils/firebase/firebase.util";
import { getPremiumStatus } from "../../utils/firebase/getPremiumStats.ts";
import { useEffect } from "react";
import { getCheckoutUrl } from "../../utils/firebase/stripePayment.ts";
import { Height } from "@mui/icons-material";

import ParishNavLogo from '../../assets/navbar-icons/ParishNavigator-Logo.png';
import { BeatLoader } from "react-spinners";

const PurchaseSubscription = () => {

    const [showLoading, setShowLoading] = useState(false);
    const [userData, setUserData] = useState(null);
    const { currentUser } = useContext(UserContext);
    const [isPremium, setIsPremium] = useState(null);

    useEffect(() => {
        document.title = "Purchase ParishNavigator";
    })
    
    useEffect(() => {
        const checkPremium = async () => {
            const newPremiumStatus = currentUser ? await getPremiumStatus(app) : false;
            setIsPremium(newPremiumStatus);
        }
        checkPremium();
    }, [app, currentUser])

    useEffect(() => {
        if(isPremium){
            window.location.href = 'https://app.parishnavigator.com/account-dashboard';
        }
    })

    const upgradePremium = async() => {
        setShowLoading(true);
        // const priceId = "price_1NvMdBHVhAXQAM1QW6MC8NCj";
        const priceId = "price_1Nvi6mF8RN4d19lCESmVEwUU";
        const checkoutUrl = await getCheckoutUrl(app, priceId).then((pricurl) => {
            console.log("checkoutUrl: " + pricurl);
            window.location.href = pricurl; 
        });
    }

    const statusPanel = isPremium ? <div>Premium</div> : <div>Not Premium</div>;

    if(isPremium === false)
    {

        return (
            <div className="d-flex flex-column justify-content-center align-items-center" style={{width: '100%', height: '100vh'}}>
                <div className='d-flex justify-content-center align-items-center'>
                    <img src={ParishNavLogo} alt="" style={{height: '25%'}} className='mb-3'/>
                    <h1 className='ms-2'><b>ParishNavigator</b></h1>
                </div>
                <button className="btn btn-primary" onClick={upgradePremium}>Purchase ParishNavigator</button>

                {showLoading && 
                    <BeatLoader color="#36d7b7" className="mt-4"/>
                }

            </div>
        )
    }
}

export default PurchaseSubscription;