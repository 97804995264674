

import { useContext, useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './online-contact-form.css';

// components
import FunctionWrapper from "../../../components/function-area-wrapper/function-wrapper.component";
import TitleSection from "../../../components/title-section/title-section";
import { APIAuthContext } from "../../../contexts/api-auth.context";
import { serverTimestamp } from "firebase/firestore";
import base64 from 'base-64';


const defaultField = {
    fullName: '',
    subject: '',
    email: '',
    phone: '',
    message: ''
}

const OnlineContactForm = () => {
    const [queryParameters] = useSearchParams();
    const { authCreds } = useContext(APIAuthContext);
    const [churchOptions, setChurchOptions] = useState([]);
    const [parishId, setParishId] = useState(null);
    const [parishName, setParishName] = useState('');

    // form fields
    const [formFields, setFormFields] = useState(defaultField);
    const [invalidPage, setInvalidPage] = useState(false);

    // form status
    const [formStatus, setFormStatus] = useState(null);

    useEffect(() => {
        document.title = "Contact us";
    })
    

    const handleFormChange = (event) => {
        const {name, value} = event.target;
        setFormFields({...formFields, [name]: value});
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        var headers = new Headers();
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;
        const encodedCredentials = base64.encode(`${username}:${password}`);
        headers.set('Authorization', 'Basic ' + encodedCredentials)
        headers.set('content-type', 'application/json');
        
        fetch('https://api.parishnavigator.com/create-contact-submission', {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                "user_id": parishId,
                "formData": formFields
             })
        }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response['status'] === "true")
            {
                setFormStatus(true);
            }
            if(response['status'] === "false")
            {
                setFormStatus(false);
            }
        })
    }

    useEffect(() => {
        const parish = queryParameters.get("parish")
        setParishId(parish);
        var headers = new Headers();
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;
        const encodedCredentials = base64.encode(`${username}:${password}`);
        headers.set('Authorization', 'Basic ' + encodedCredentials)
        fetch(`https://api.parishnavigator.com/get-churches-parish/${parish}`, {headers: headers})
            .then((response) => response.json())
            .then((response) => {
                console.log("churches = ", response);
                if(response['status'] === "false")
                {
                    setInvalidPage(true);
                } else {
                    setParishName(response['parishName']);
                    const userData = response;
                    var temp = []
                    if(userData['church1'] !== '')
                    {
                        temp.push({'churchnum': 'church1', 'churchname': userData['church1']});
                    }
                    if(userData['church2'] !== '')
                    {
                        temp.push({'churchnum': 'church2', 'churchname': userData['church2']});
                    }
                    if(userData['church3'] !== '')
                    {
                        temp.push({'churchnum': 'church3', 'churchname': userData['church3']});
                    }
                    setChurchOptions(temp);
                    console.log("church options: ", churchOptions);
                }
            })

    }, [])
    
    if(queryParameters.get("parish") === null || invalidPage === true)
    {
        return (
            <div>INVALID PAGE</div>
        )
    }
    else {
        return (
                <FunctionWrapper>
                    <TitleSection title={"Contact the Parish of " + parishName} subtitle="We are here to help you on your faith journey"/>

                    <div className="p-4" >
                        <div class="">
                                    <form className="d-flex flex-column w-100 flex-fill " onSubmit={handleFormSubmit}>
                                        
                                        <div class="d-flex flex-column mb-3">
                                            <label>Your Name</label>
                                            <input required type="text" className="form-control" name="fullName" onChange={handleFormChange} placeholder="Full Name"/>
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Subject</label>
                                            <input required type="text" className="form-control" name="subject" onChange={handleFormChange} placeholder="A short description"/>
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Email</label>
                                            <input required type="email" className="form-control" name="email" onChange={handleFormChange} placeholder="john@email.com"/>
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Phone</label>
                                            <input type="phone" className="form-control" name="phone" onChange={handleFormChange} />
                                        </div>

                                        <div class="d-flex flex-column mb-3">
                                            <label>Message</label>
                                            <textarea required class="form-control" name="message" style={{height: "100px"}} onChange={handleFormChange} placeholder="What would you like to tell us"/>
                                        </div>


                                        <div>
                                        {formStatus === true && (
                                            <p className="align-self-start text-success"><i>The form has been successfully submitted</i></p>
                                        )}
                                        {formStatus === false && (
                                            <p className="align-self-start text-danger"><i>There was an error submitting your Contact Form</i></p>
                                        )
                                        }
                                            <button type="submit" className="btn btn-primary mt-1">Submit</button>
                                        </div>
                                
                                    </form>
                                    
                                    
                                </div>

                    </div>

                    
                </FunctionWrapper>
        );
    }
}

export default OnlineContactForm;