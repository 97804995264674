import { useContext, useEffect, useState, useSyncExternalStore } from "react";
import { UserContext } from "../../../contexts/user.context";
import EmailSender from "../../../components/email-sender/email-sender.component";
import { APIAuthContext } from "../../../contexts/api-auth.context";
import TitleSectionMin from "../../../components/title-section-min/title-section";
import base64 from 'base-64';

function removeFromArray(array, value){
    for (var i = array.length - 1; i >= 0; i--) {
     if (array[i] === value) {
      array.splice(i, 1);
     }
    }
  }

const SendRoleEmailComponent = ({ userData }) => {

    const [roles, setRoles] = useState(null);
    
    const { currentUser } = useContext(UserContext);
    const { authCreds } = useContext(APIAuthContext);

    const [selectedRoles, setSelectedRoles] = useState([]);

    useEffect(() => {
        document.title = "Send Role Emails";
    })

    const updateSelectedRoles = (event) => {
        console.log(event.target.value, event.target.checked);
        var temp = selectedRoles;

        if(event.target.checked === true)
        {
            temp.push(event.target.value);
        } else {
            removeFromArray(temp, event.target.value);
        }

        setSelectedRoles(temp);
        console.log("selected roles: ", selectedRoles);
    }

    useEffect(() => {
        if(currentUser !== null){
            console.log(currentUser.uid);

            var headers = new Headers();
            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;
            const encodedCredentials = base64.encode(`${username}:${password}`);
            headers.set('Authorization', 'Basic ' + encodedCredentials)
            fetch(`https://api.parishnavigator.com/get-roles/${userData.parishid}`, {headers: headers})
                .then((roles_result) => roles_result.json())
                .then((roles_result) => {
                    console.log("api response = ", roles_result);
                    setRoles(roles_result);
                })
        }
    }, [currentUser])

    if(roles !== null && userData !== null)
    {
        return (
            <div>
                <TitleSectionMin title="Send Email to Role Holders"/>
                <div className="p-4">
                    <div className="d-flex flex-wrap">
                        {roles.map((role, index) => {
                        return (
                            <div className="me-4 mb-3">
                                <input onChange={updateSelectedRoles} type="checkbox" class="form-check-input me-2" id={role.roleid} value={role.roleid}/>
                                <label for={role.roleid}>{role.rolename}</label>
                            </div>
                            )
                        })}
                    </div>

                    <EmailSender recipients={selectedRoles} parishName={userData.parishName} senderEmail={userData.email} userId={userData.parishid} apiRoute="send-role-contact"/>
                </div>
            </div>
        )
    }

}

export default SendRoleEmailComponent;